import React, {useContext} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {ThemeContext} from '../../../providers/theme';
import {FABV} from '../../../widgets/FABV';
import {useViewport, ViewportProvider} from 'react-viewport-provider';

const hex2rgb = require('hex2rgb');

const getRGB = (color: string) => {
  const bg = hex2rgb(color, {rgbStringDefault: 'transparent'}).rgb;
  return `rgba(${bg[0]}, ${bg[1]}, ${bg[2]}, 0.8)`;
};

interface FABVProps {
  onFabClick?(): void;
}

const FABInternal = ({onFabClick}: FABVProps) => {
  const theme = useContext(ThemeContext);
  const { height } = useViewport();

  const styles = StyleSheet.create({
    fabContainerStyle: {
      // flex: 1,
    },
    extraSpace: {
      backgroundColor: 'transparent',
      // width: 80,
      zIndex: -1,
      alignSelf: 'center',
    },
  });

  return (
    <>
      <FABV
        fabButtonColor={getRGB('#fff')}
        iconName={'info'}
        iconType="entypo"
        iconSize={30}
        iconColor={theme?.palette?.secondary}
        fabContainerStyle={StyleSheet.flatten([styles.fabContainerStyle])}
        onFabClick={onFabClick}
      />
      <TouchableOpacity style={StyleSheet.flatten([styles.extraSpace])} />
    </>
  );
};

export const FAB = (props: FABVProps) => {
  return(
    <ViewportProvider>
      <FABInternal {...props} />
    </ViewportProvider>
  );
}


