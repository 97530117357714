import React, { useContext } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { PanelV } from 'react-panel-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { LineChartV } from 'react-linechart-chartjs-2';
import { PieChartV } from 'react-piechart-chartjs-2';
// import { PiechartV } from 'react-piechart-nivo';
import { BarchartV } from 'react-barchart-nivo';
import { DashboardCardsV } from 'react-dashboard-cards-web';
import config from './config/default-blueprint.json';
import { IconV } from 'react-icon-rs';
import { TableV, TableHeaderDataProps } from 'react-table-rs';
import { ThemeContext } from '../../../../../../providers/theme';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import { StreamV } from 'react-streamchart-nivo';
import { Panel } from 'rsuite';

const BP = config.adminDashboard;

const generateFakeData = async (schema, rows = 1) => {
  let res = [];
  try {
    if (schema) {
      for (let i = 0; i < rows; ++i) {
        let _sample = { ...schema[i] };

        res.push(_sample);
      }
    }
  } catch (err) { }
  return res;
};

const AdminDashboardInternal = props => {
  const { width, height } = useViewport();
  const theme = useContext(ThemeContext);
  const columns: TableHeaderDataProps = BP?.userTable?.tableHeader;
  const schema = BP?.userTable?.tableData;
  const cardData = BP?.cardData;
  const [data, setData] = React.useState<any>([]);

  const { drawerWidth } = props;

  React.useEffect(() => {
    generateFakeData(schema, schema.length).then(res => {
      res && Array.isArray(res) && setData([...res]);
    });
  }, []);

  const styles = {
    textStyle: {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme?.palette.ternary,
    },
    cardTitleStyle: {
      fontSize: 18,
      color: theme?.palette?.primary,
    },
    // cardStyle: {
    //   display: 'flex',
    //   flexGrow: 1,
    //   flexDirection: 'column',
    //   justifyContent: 'space-around',
    //   alignItems: 'center',
    // },
    titleStyle: {
      fontSize: 18,
      color: theme?.palette.ternary,
    },
    tableStyle: {
      margin: '20px 0px',
      alignItems: 'center',
    },
    containerStyle: {
      // maxHeight: height - (height * 0.06)
    },
    panelstyle: {
      backgroundColor: '#fff',
      marginTop: 15,
      alignItems: 'center',
    },
    statsStyle: {
      border: '5px solid #fff',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: 150,
      height: 150,
      backgroundImage: 'linear-gradient(#a90cfe, #13b5f9)'
    },
    cardStyle: {
      padding: 8
    }
  };

  return (
    <View style={StyleSheet.flatten([styles.containerStyle])}>
      <FBGridV justify={'space-around'}>
        <FBItemV colspan={23}>
          <PanelV shaded style={{ backgroundColor: '#fff', marginTop: 10 }}>
            <DashboardCardsV items={cardData}
              cardStyle={StyleSheet.flatten([styles.cardStyle])}
            />
          </PanelV>
        </FBItemV>
      </FBGridV>

      <FBGridV justify={'space-around'}>
        <FBItemV colspan={11}>
          <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
            <Text style={StyleSheet.flatten([styles.cardTitleStyle])}>{BP?.activeCredentials?.title}</Text>
            <LineChartV
              data={BP?.activeCredentials}
              options={{ responsive: true }}
            />
          </PanelV>
        </FBItemV>
        <FBItemV colspan={11}>
          <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
            <Text style={StyleSheet.flatten([styles.cardTitleStyle])}>{BP?.revokedCredentials?.title}</Text>
            <LineChartV
              data={BP?.revokedCredentials}
              options={{ responsive: true }}
            />
          </PanelV>
        </FBItemV>
        {/* <FBItemV colspan={11}>
          <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
            <Text style={StyleSheet.flatten([styles.cardTitleStyle])}>{BP?.issuerPie?.title}</Text>
            <PieChartV
              data={BP?.issuerPie}
              options={{ responsive: true }}
              height={200}
            />
          </PanelV>
        </FBItemV>
        <FBItemV colspan={11}>
          <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
            <Text style={StyleSheet.flatten([styles.cardTitleStyle])}>{BP?.verifierPie?.title}</Text>
            <PieChartV
              data={BP?.verifierPie}
              options={{ responsive: true }}
              height={200}
            />
          </PanelV>
        </FBItemV> */}
        {/* <FBItemV colspan={7}>
          <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
            <BarchartV
              data={BP?.issuer.data}
              keys={BP?.issuer.keys}
              indexBy="dataType"
              margin={{ top: 10, bottom: 10 }}
              pixelRatio={2}
              padding={0.15}
              innerPadding={0}
              minValue="auto"
              maxValue="auto"
              groupMode="grouped"
              layout="vertical"
              reverse={false}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              colorBy="id"
              borderWidth={0}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
              }}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'dataType',
                legendPosition: 'middle',
                legendOffset: 36,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'dataType',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              enableGridX={true}
              enableGridY={false}
              enableLabel={true}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              isInteractive={true}
              width={250}
              height={300}
              containerStyle={{
                width: '100%',
                height: '40vh',
                marginBottom: 30,
              }}
              appearance={'svg'}
            />
          </PanelV>
        </FBItemV>
        <FBItemV colspan={7}>
          <PanelV shaded style={StyleSheet.flatten(styles.panelstyle)}>
            <BarchartV
              data={BP?.verifier.data}
              keys={BP?.verifier.keys}
              indexBy="dataType"
              margin={{ top: 50, bottom: 10 }}
              pixelRatio={2}
              padding={0.15}
              innerPadding={0}
              minValue="auto"
              maxValue="auto"
              groupMode="grouped"
              layout="vertical"
              reverse={false}
              colors={{ scheme: 'nivo' }}
              defs={[
                {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: '#38bcb2',
                  size: 4,
                  padding: 1,
                  stagger: true,
                },
                {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#eed312',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10,
                },
              ]}
              colorBy="id"
              borderWidth={0}
              borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
              }}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'dataType',
                legendPosition: 'middle',
                legendOffset: 36,
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Verifiers',
                legendPosition: 'middle',
                legendOffset: 40,
              }}
              enableGridX={true}
              enableGridY={false}
              enableLabel={true}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              isInteractive={true}
              width={250}
              height={300}
              containerStyle={{
                width: '100%',
                height: '40vh',
                marginBottom: 30,
              }}
              appearance={'svg'}
            />
          </PanelV>
        </FBItemV> */}
        <FBItemV colspan={23} style={StyleSheet.flatten([styles.tableStyle])}>
          <TableV
            onRowClick={data => console.log('row clicked', data)}
            checkedAppearance={true}
            oddRowColor={theme?.palette.tableOddRowColor}
            evenRowColor={theme?.palette.tableEvenRowColor}
            table={{
              height: 500,
              headerHeight: 40,
              rowHeight: 50,
              wordWrap: true,
              data: [...data],
            }}
            tableContainerStyle={{ margin: '0 auto' }}
            columns={columns}
          />
        </FBItemV>
      </FBGridV>
    </View >
  );
};

export const AdminDashboard = props => {
  return (
    <ViewportProvider>
      <AdminDashboardInternal {...props} />
    </ViewportProvider>
  );
};
