import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {BodyContentLG} from './BodyContent.lg';
import {BodyContentSM} from './BodyContent.sm';

export interface IProps {
  bodyContent?: any;
  parallaxBg?: string;
  mobileParallaxBg?: string;
}

export const BodyContent = (props: IProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <BodyContentSM {...props} />;
  }

  // if (bp === 'md') {
  //   return <LandingTemplateMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <BodyContentLG {...props} />;
};
