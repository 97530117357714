import React from 'react';
import {useHistory} from 'react-router-dom';
import {FloatingProductInfo} from './components';

export const FabProductInfo = () => {
    const history = useHistory();

    const handleBackPress = () => {
        window.history.go(-1);
        return false;
    }

    return (
        <FloatingProductInfo 
            handleBackPress={handleBackPress}
        />
    );
}