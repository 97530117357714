import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { ProvenanceTimelineSM } from './ProvenanceTimeline.sm';
import { ProvenanceTimelineMD } from './ProvenanceTimeline.md';
import {ProvenanceTimelineLG} from './ProvenanceTimeline.lg';

export interface ProvenanceTimelineProps {
    timeLineData?: any[];
    onTimelineElementClick?(item: any, index: number): void;
}

export const ProvenanceTimeline = (props: ProvenanceTimelineProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <ProvenanceTimelineSM {...props} />;
    }

    if (bp === 'md') {
      return <ProvenanceTimelineMD {...props} />;
    }

    if (bp === 'lg')
    return <ProvenanceTimelineLG {...props} />;
};
