//@ts-nocheck
import {isAfter} from 'date-fns';
import {MaskServiceV} from 'mask-service-vfs';
import React, {ReactNode, useState} from 'react';
import {IconV} from 'react-icon-rs';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import 'rsuite/dist/styles/rsuite-default.css';
import DatePicker from 'rsuite/lib/DatePicker';
import HelpBlock from 'rsuite/lib/HelpBlock';

function deleteKeys(obj: any, keys: string[]) {
  if (obj && keys && Array.isArray(keys)) {
    keys.forEach((key: string) => {
      delete obj[key];
    });
  }
}

function renameKeys(obj: any, newKeys: any, delete_keys?: string[]) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return {[newKey]: obj[key]};
  });
  const ret = Object.assign({}, ...keyValues);
  delete_keys && deleteKeys(ret, delete_keys);
  return ret;
}

type Locale = {
  sunday: 'Su';
  monday: 'Mo';
  tuesday: 'Tu';
  wednesday: 'We';
  thursday: 'Th';
  friday: 'Fr';
  saturday: 'Sa';
  ok: 'OK';
  today: 'Today';
  yesterday: 'Yesterday';
  hours: 'Hours';
  minutes: 'Minutes';
  seconds: 'Seconds';
};

type Range = {
  label: ReactNode;
  closeOverlay?: boolean;
  value: Date | ((date: Date) => Date);
};

export interface WidgetProps {
  appearance?: 'default' | 'subtle';
  block?: boolean;
  calendarDefaultDate?: Date;
  cleanable?: boolean;
  container?: any;
  defaultOpen?: boolean;
  defaultValue?: Date;
  disabled?: boolean;
  disabledDate?(date: Date): boolean;
  disabledHours?(hour: number, date: Date): boolean;
  disabledMinutes?(minute: number, date: Date): boolean;
  disabledSeconds?(second: number, date: Date): boolean;
  format?: string;
  hideHours?(hour: number, date: Date): boolean;
  hideMinutes?(minute: number, date: Date): boolean;
  hideSeconds?(second: number, date: Date): boolean;
  inline?: boolean;
  isoWeek?: boolean;
  limitEndYear?: number;
  locale?: Locale;
  onChange?(date: Date): void;
  onChangeCalendarDate?(date: Date, event?: React.SyntheticEvent): void;
  onClean?(event: React.SyntheticEvent): void;
  onClose?(): void;
  onEnter?(): void;
  onEntered?(): void;
  onEntering?(): void;
  onExit?(): void;
  onExited?(): void;
  onExiting?(): void;
  onNextMonth?(date: Date): void;
  onOk?(date: Date, event: React.SyntheticEvent): void;
  onOpen?(): void;
  onPrevMonth?(date: Date): void;
  onSelect?(date: Date): void;
  onToggleMonthDropdown?(open: boolean): void;
  onToggleTimeDropdown?(open: boolean): void;
  oneTap?: boolean;
  open?: boolean;
  placeholder?: string;
  placement?:
  | 'bottomStart'
  | 'bottomEnd'
  | 'topStart'
  | 'topEnd'
  | 'leftStart'
  | 'leftEnd'
  | 'rightStart'
  | 'rightEnd'
  | 'auto'
  | 'autoVerticalStart'
  | 'autoVerticalEnd'
  | 'autoHorizontalStart'
  | 'autoHorizontalEnd';
  preventOverflow?: boolean;
  ranges?: Range[];
  showMeridian?: boolean;
  showWeekNumbers?: boolean;
  toggleComponentClass?: any;
  value?: Date;
  title?: string;
  titleInline?: boolean;
  titleStyle?: TextStyle;
  titleContainerStyle?: object;
  containerStyle?: ViewStyle;
  maskParams?: {
    type:
    | 'money'
    | 'credit-card'
    | 'only-numbers'
    | 'mobile-phone'
    | 'custom'
    | 'datetime';
    options: any;
  };
  helpblock?: {
    tooltip?: boolean;
    message: string;
    style?: any;
  };
  errorMessage?: string;
  errorMessageStyle?: any;
  datePickerStyle?: any;
  mandatory?: boolean;
}

const mapper = {datePickerStyle: 'style'};
const delete_keys = [
  'containerStyle',
  'onChange',
  'title',
  'titleStyle',
  'titleContainerStyle',
  'maskParams',
  'helpblock',
  'errorMessage',
  'errorMessageStyle',
  'defaultValue',
  'titleInline',
  'mandatory',
];

const styles = StyleSheet.create({
  linerStyle: {
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  textInlineStyle: {marginRight: 10, marginTop: 5},
  textNonInlineStyle: {marginBottom: 10},
  errorMessageStyle: {
    color: 'red',
    marginTop: 5,
    marginLeft: 5,
    fontSize: 11,
  },
  iconContainerStyle: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
  },
});

export const DatePickerV = (props: WidgetProps) => {
  const maskServiceValidator = (value: any) => {
    let _value: any = value;
    let _rawValue: any = value;
    let _cc = '';
    if (props.maskParams && value && value !== '') {
      const _mask = new MaskServiceV(
        props.maskParams.type,
        value,
        props.maskParams.options,
      );
      _value = _mask.toMask();
      _rawValue = _mask.toRawValue();
      _cc = _mask.getCC();
    }
    return {
      maskValue: _value,
      rawValue: _rawValue,
      cc: _cc,
    };
  };
  const {maskValue, rawValue, cc} = maskServiceValidator(
    props.defaultValue ?? '',
  );
  const [value, setValue] = useState<string | number>(maskValue ?? '');
  const _props = {...props};
  const _widgetProps = renameKeys(_props, mapper);
  deleteKeys(_widgetProps, delete_keys);

  const handleOnChange = (value: string, event: any) => {
    const {maskValue, rawValue, cc} = maskServiceValidator(value);
    props.onChange && props.onChange(rawValue, cc);
    setValue(maskValue);
  };

  const _renderTitle = () => {
    return (
      <View
        style={StyleSheet.flatten([
          props.titleContainerStyle,
          {flexWrap: 'wrap'},
        ])}
      >
        {props.mandatory && (
          <View style={{flexDirection: 'row'}}>
            <Text
              style={StyleSheet.flatten([
                props.titleStyle,
                props.inline
                  ? styles.textInlineStyle
                  : styles.textNonInlineStyle,
              ])}
            >
              {props.title}
            </Text>
            <IconV
              icons={[
                {
                  name: 'asterisk',
                  style: {
                    color: 'red',
                    fontSize: 8,
                  },
                },
              ]}
              containerStyle={{marginTop: -8}}
            />
          </View>
        )}
        {!props.mandatory && (
          <Text
            style={StyleSheet.flatten([
              props.titleStyle,
              props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
            ])}
          >
            {props.title}
          </Text>
        )}
      </View>
    );
  };

  return (
    <View
      style={StyleSheet.flatten([
        props.containerStyle,
        props.titleInline && {flexDirection: 'row'},
      ])}
    >
      {props.title && _renderTitle()}
      <View
        style={StyleSheet.flatten([
          {
            flexDirection:
              props.helpblock?.tooltip && !props.errorMessage
                ? 'row'
                : 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          },
        ])}
      >
        <DatePicker
          {..._widgetProps}
          style={StyleSheet.flatten([props.datePickerStyle])}
          onChange={handleOnChange}
          disabledDate={date => isAfter(date, new Date())}
        />
        {props.helpblock && !props.errorMessage && (
          <HelpBlock
            tooltip={props.helpblock.tooltip}
            style={StyleSheet.flatten([props.helpblock.style])}
          >
            {props.helpblock.message}
          </HelpBlock>
        )}
        {props.errorMessage && (
          <HelpBlock
            tooltip={false}
            style={StyleSheet.flatten([
              styles.errorMessageStyle,
              props.errorMessageStyle,
            ])}
          >
            {props.errorMessage}
          </HelpBlock>
        )}
      </View>
    </View>
  );
};
