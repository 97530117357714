import React, { useContext } from 'react';
import { ThemeContext } from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import { View, Text, StyleSheet, ImageBackground } from 'react-native';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';
import { ButtonV } from 'react-button-rs';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import { EventBus } from 'event-bus-vfs';
import { FORM_VALUES, defaultValuesDS, FormV } from 'react-form-rs';
import { IProps } from './index';
import { MOBILE_BACKGROUND_IMAGE } from '../../../services/loadAssets';
import Alert from 'rsuite/lib/Alert';
import { Panel } from 'rsuite';

let formContext = {};
const BP = blueprint.LoginPage;

export const LoginPageSM = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();
  const history = useHistory();
  const [date, setDate] = React.useState<Date>(new Date());
  const [validateForm, setValidateForm] = React.useState<any>(null);
  React.useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
      formContext = { ...value };
    });
    return function () {
      app.off();
    };
  }, []);

  const styles = {
    panelStyle: {
      backgroundColor: '#fff',
      opacity:'0.7',
      width: width * 0.9,
      alignItems: 'center',
      justifyContent: 'center',
      margin: '40% auto',
    },
    logContainerStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    titleStyle: {
      paddingLeft: 5,
      fontSize: 24,
      fontWeight: 700
    },
    descStyle:{
        display:'block', 
        padding:5,
        letterSpacing:2,
        lineHeight:20,
        fontSize:14,
        textAlign:'center'
    }
  }

  return (
    <ImageBackground
      source={MOBILE_BACKGROUND_IMAGE}
    //   style={{resizeMode:'contain'}}
    >
      {/* <div style={{ width: '100%', height: '100%', backgroundImage:`url("https://i.ibb.co/6ZCvN5R/image.png")`, backgroundRepeat: 'no-repeat', backgroundPosition:'left bottom', backgroundSize:'cover'}}> */}
      <Panel shaded style={StyleSheet.flatten([styles.panelStyle])}>
        <View style={StyleSheet.flatten([styles.logContainerStyle])}>
          <AvatarV
            source={{ uri: BP?.leftAvatar }}
            size={AvatarSize?.Large}
          />
          <Text style={StyleSheet.flatten([styles.titleStyle])}>{BP.title}</Text>
          <Text style={StyleSheet.flatten([styles.descStyle])}>{BP?.mobilewarningMessage}</Text>
        </View>
      </Panel>
    </ImageBackground>
  );
};
