import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {ProvenanceMapLG} from './ProvenanceMap.lg';
import {ProvenanceMapMD} from './ProvenanceMap.md';
import {ProvenanceMapSM} from './ProvenanceMap.sm';


export interface ProvenanceMapProps {
  handleMarkerClick?(item: any): void;
  defaultCenter?: object;
  markers?: any[];
  mapDetails?: object;
  googleMapAPIKey?:string
}

export const ProvenanceMap = (props: ProvenanceMapProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <ProvenanceMapSM {...props} />;
  }

  // if (bp === 'md') {
  //   return <ProvenanceMapMD {...props} />;
  // }

  //bp === 'lg' || bp === 'xl'
  return <ProvenanceMapLG {...props} />;
};
