import React, { useEffect } from 'react';
import { PageNotFound } from './components';

import { useHistory, useLocation, Prompt } from 'react-router-dom';


export const NotFound = () => {
    const history = useHistory();

    const _renderBody = () => {
        // if (loading) {
        //     return <div />
        // }
        // if (error) {
        //     return <div />
        // }
        return (
            <PageNotFound />
        )
    }
    return (
        <>
            {_renderBody()}
        </>
    )
}