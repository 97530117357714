import * as React from "react"
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Food({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={888}
      height={485.489}
      viewBox="0 0 888 485.489"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{"breakfast"}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path
        d="M119.412 253.045H90.993a3.768 3.768 0 00-3.767 3.768v12.474a3.768 3.768 0 003.767 3.767h4.71v18.69h19v-18.69h4.71a3.768 3.768 0 003.767-3.767v-12.474a3.768 3.768 0 00-3.768-3.768z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        d="M147.877 367.206a146.105 146.105 0 01-25.418-86.625 3.081 3.081 0 00-2.406-3.075v-5.39H90.04v5.316h-.413a3.08 3.08 0 00-3.067 3.307q3.546 49.442-23.954 88.677a8.487 8.487 0 00-1.536 5.144l3.282 101.518a8.788 8.788 0 008.713 8.482h67.887a8.791 8.791 0 008.718-8.632l1.265-98.565a17.789 17.789 0 00-3.057-10.157z"
        fill="#3f3d56"
      />
      <path d="M113.488 257.11a8.129 8.129 0 01-16.258 0" opacity={0.2} />
      <path
        d="M137.13 400.927h-8.22a21.576 21.576 0 00-42.65 0h-8.22a6.067 6.067 0 00-5.997 6.99l8.93 58.04h51.274l10.846-57.845a6.067 6.067 0 00-5.963-7.185z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        fill="#e6e6e6"
        d="M827.952 307.634l-1.972 78.814-2.442 97.733h-83.124l-2.862-114.506L736 307.634h91.952z"
      />
      <path fill={props?.tertiary || "#5987af" } d="M775.723 265.704h11.77v155.95h-11.77z" />
      <path
        d="M822.067 367.02l-2.226 112.02h-75.767l-2.608-131.244a51.634 51.634 0 0140.827 5.615c12.659 7.488 26.88 11.518 39.774 13.609z"
        fill="#3f3d56"
      />
      <path
        fill="#e6e6e6"
        d="M752.031 379.63h10.8v10.8h-10.8zM759.387 357.562h10.8v10.8h-10.8zM784.703 366.406l8.675 6.434-6.434 8.675-8.674-6.434zM804.217 385.542l8.674 6.433-6.433 8.675-8.675-6.434zM771.113 396.576l8.675 6.434-6.434 8.674-8.674-6.433z"
      />
      <circle cx={805.148} cy={351.035} r={2.207} fill="#3f3d56" />
      <circle cx={760.275} cy={336.323} r={2.207} fill="#3f3d56" />
      <circle cx={797.792} cy={320.875} r={2.207} fill="#3f3d56" />
      <circle cx={444.508} cy={241.754} r={241.754} fill={props?.tertiary || "#5987af" } />
      <path
        d="M444.508 418.211a176.456 176.456 0 11124.774-51.683 175.304 175.304 0 01-124.774 51.683zm0-342.031c-91.298 0-165.574 74.276-165.574 165.574 0 91.298 74.276 165.574 165.574 165.574 91.298 0 165.574-74.276 165.574-165.574 0-91.298-74.276-165.574-165.574-165.574z"
        opacity={0.2}
      />
      <circle cx={444.508} cy={241.754} r={171.016} fill="#3f3d56" />
      <path
        d="M317.327 173.372a21.704 21.704 0 1138.682-19.698l48.316 94.878a21.704 21.704 0 11-38.681 19.698z"
        fill="#ff6584"
      />
      <path
        d="M322.717 183.954l-2.23-4.379c11.129-8.647 24.197-15.007 38.68-19.698l2.23 4.38c-15.675 3.264-29.01 9.304-38.68 19.697zM333.123 204.39l-2.23-4.38c11.13-8.647 24.198-15.006 38.681-19.697l2.23 4.379c-15.675 3.264-29.011 9.305-38.68 19.698zM343.53 224.825l-2.23-4.379c11.129-8.647 24.198-15.007 38.68-19.698l2.23 4.379c-15.675 3.264-29.01 9.305-38.68 19.698zM353.936 245.26l-2.23-4.378c11.13-8.648 24.198-15.007 38.681-19.699l2.23 4.38c-15.675 3.264-29.011 9.304-38.68 19.698zM364.343 265.696l-2.23-4.38c11.13-8.646 24.198-15.006 38.681-19.697l2.23 4.379c-15.675 3.264-29.011 9.305-38.681 19.698z"
        opacity={0.2}
      />
      <path
        d="M281.08 208.89a21.704 21.704 0 1138.68-19.697l48.317 94.878a21.704 21.704 0 01-38.682 19.698z"
        fill="#ff6584"
      />
      <path
        d="M286.468 219.473l-2.23-4.379c11.13-8.647 24.198-15.007 38.681-19.698l2.23 4.38c-15.675 3.264-29.011 9.304-38.68 19.697zM296.875 239.909l-2.23-4.38c11.129-8.647 24.198-15.006 38.681-19.697l2.23 4.379c-15.675 3.264-29.012 9.305-38.681 19.698zM307.281 260.344l-2.23-4.379c11.13-8.647 24.198-15.007 38.682-19.698l2.23 4.379c-15.676 3.264-29.012 9.305-38.682 19.698zM317.688 280.78l-2.23-4.38c11.13-8.647 24.198-15.007 38.681-19.698l2.23 4.38c-15.675 3.264-29.011 9.305-38.681 19.697zM328.095 301.215l-2.23-4.38c11.129-8.646 24.197-15.006 38.68-19.697l2.23 4.379c-15.675 3.264-29.01 9.305-38.68 19.698z"
        opacity={0.2}
      />
      <path
        d="M558.778 247.195h.152a25.888 25.888 0 0024.24-16.236 84.503 84.503 0 005.923-31.694c-.272-45.91-37.623-83.575-83.53-84.21a84.744 84.744 0 00-35.277 162.32 25.667 25.667 0 0028.506-5.285 84.458 84.458 0 0159.986-24.895z"
        fill="#e6e6e6"
      />
      <circle cx={515.246} cy={193.559} r={27.984} fill="#f9a825" />
      <circle cx={454.426} cy={187.115} r={3.297} fill="#3f3d56" />
      <circle cx={345.598} cy={190.225} r={3.297} fill="#3f3d56" />
      <circle cx={471.996} cy={152.949} r={3.297} fill="#3f3d56" />
      <circle cx={471.902} cy={247.973} r={3.297} fill="#3f3d56" />
      <circle cx={537.199} cy={146.141} r={3.297} fill="#3f3d56" />
      <circle cx={324.207} cy={236.313} r={3.297} fill="#3f3d56" />
      <circle cx={348.305} cy={283.731} r={3.297} fill="#3f3d56" />
      <circle cx={499.7} cy={289.949} r={12.438} fill="#ff6584" />
      <path
        d="M509.028 290.727a9.328 9.328 0 01-18.657 0M490.371 289.172a9.328 9.328 0 0118.657 0"
        opacity={0.2}
      />
      <ellipse cx={495.035} cy={285.674} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={499.7} cy={284.119} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={499.7} cy={294.225} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={504.364} cy={288.006} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={503.586} cy={296.557} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={494.258} cy={292.67} rx={0.777} ry={1.166} fill="#ff6584" />
      <circle cx={524.575} cy={256.524} r={12.438} fill="#ff6584" />
      <path
        d="M533.903 257.3a9.328 9.328 0 01-18.657 0M515.246 255.746a9.328 9.328 0 0118.657 0"
        opacity={0.2}
      />
      <ellipse cx={519.91} cy={252.248} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={524.575} cy={250.693} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={524.575} cy={260.799} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={529.239} cy={254.58} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={528.461} cy={263.131} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={519.133} cy={259.244} rx={0.777} ry={1.166} fill="#ff6584" />
      <circle cx={549.45} cy={273.625} r={12.438} fill={props?.tertiary || "#5987af" } />
      <path
        d="M558.778 274.403a9.328 9.328 0 01-18.657 0M540.121 272.848a9.328 9.328 0 0118.657 0"
        opacity={0.2}
      />
      <ellipse cx={544.785} cy={269.35} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={549.45} cy={267.795} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={549.45} cy={277.901} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={554.114} cy={271.682} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={553.336} cy={280.233} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={544.008} cy={276.346} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <circle cx={487.262} cy={275.18} r={12.438} fill={props?.tertiary || "#5987af" } />
      <path
        d="M496.59 275.957a9.328 9.328 0 01-18.656 0M477.934 274.403a9.328 9.328 0 0118.656 0"
        opacity={0.2}
      />
      <ellipse cx={482.598} cy={270.904} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={487.262} cy={269.35} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={487.262} cy={279.455} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={491.926} cy={273.236} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={491.149} cy={281.787} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={481.821} cy={277.901} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <circle cx={515.246} cy={280.621} r={12.438} fill={props?.tertiary || "#5987af" } />
      <path
        d="M524.575 281.399a9.328 9.328 0 11-18.657 0M505.918 279.844a9.328 9.328 0 1118.657 0"
        opacity={0.2}
      />
      <ellipse cx={510.582} cy={276.346} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={515.246} cy={274.791} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={515.246} cy={284.897} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={519.91} cy={278.678} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={519.133} cy={287.229} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <ellipse cx={509.805} cy={283.342} rx={0.777} ry={1.166} fill={props?.tertiary || "#5987af" } />
      <circle cx={534.68} cy={293.836} r={12.438} fill="#ff6584" />
      <path
        d="M544.008 294.613a9.328 9.328 0 11-18.656 0M525.352 293.059a9.328 9.328 0 1118.656 0"
        opacity={0.2}
      />
      <ellipse cx={530.016} cy={289.561} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={534.68} cy={288.006} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={534.68} cy={298.111} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={539.344} cy={291.893} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={538.567} cy={300.443} rx={0.777} ry={1.166} fill="#ff6584" />
      <ellipse cx={529.239} cy={296.557} rx={0.777} ry={1.166} fill="#ff6584" />
      <circle cx={374.734} cy={246.418} r={3.297} fill="#3f3d56" />
      <path
        fill="#f9a825"
        d="M464.345 366.08l-79.175-21.957 3.033-11.266 79.175 21.958-3.033 11.265z"
      />
      <path
        d="M452.738 362.315a218.268 218.268 0 01-82.078 0v-11.678a250.991 250.991 0 0082.078 0zM461.176 327.28a218.268 218.268 0 01-82.078 0v-11.678a250.992 250.992 0 0082.078 0z"
        fill="#f9a825"
      />
      <path
        d="M489.558 341.294a218.268 218.268 0 01-82.078 0v-11.678a250.992 250.992 0 0082.078 0z"
        fill="#f9a825"
      />
      <path
        d="M496.462 354.53a218.268 218.268 0 01-82.078 0V342.85a250.992 250.992 0 0082.078 0zM520.531 371.824l-80.977 13.602-1.878-11.522 80.976-13.602 1.879 11.522z"
        fill="#f9a825"
      />
      <path
        d="M508.435 373.321a219.97 219.97 0 01-74.58 34.785l-4.805-10.612a252.954 252.954 0 0074.58-34.784zM501.689 337.91a219.97 219.97 0 01-74.58 34.785l-4.805-10.611a252.954 252.954 0 0074.58-34.785z"
        fill="#f9a825"
      />
      <path
        d="M533.243 338.616a219.97 219.97 0 01-74.58 34.785l-4.804-10.612a252.955 252.955 0 0074.58-34.784z"
        fill="#f9a825"
      />
      <path
        d="M544.962 347.717a219.97 219.97 0 01-74.58 34.784l-4.805-10.611a252.954 252.954 0 0074.58-34.785z"
        fill="#f9a825"
      />
      <path
        fill="#00bf71"
        d="M442.953 324.93h3.887v3.887h-3.887zM454.614 392.559h3.887v3.887h-3.887zM392.426 324.93h3.887v3.887h-3.887zM482.598 373.125h3.887v3.887h-3.887zM484.93 350.582h3.887v3.887h-3.887zM420.198 336.885l4.503 3.055-1.746 2.573-4.502-3.055zM466.061 345.436l4.503 3.055-1.746 2.573-4.502-3.055zM410.092 360.206l4.502 3.055-1.745 2.572-4.503-3.054zM446.156 358.532l-3.054 4.502-2.573-1.745 3.055-4.503zM467.144 363.973l-3.055 4.503-2.573-1.746 3.055-4.502zM523.119 345.316l-3.055 4.502-2.573-1.745 3.055-4.503z"
      />
      <path fill="#3f3d56" d="M0 483.248h888v2.241H0z" />
    </svg>
  )
}
