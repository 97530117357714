import React from 'react';
import Svg, {Path} from 'react-native-svg';

interface SvgIconVProps {
  width?: number;
  height?: number;
  path: any[];
  color?: string;
}

export const SvgIconV = (props: SvgIconVProps): JSX.Element => {
  const viewBox = '0 0 1024 1024';
  return (
    <Svg width={props.width} height={props.height} viewBox={viewBox} {...props}>
      {props.path &&
        props.path.map((element: any, index: number) => (
          <Path key={`svg-${index}`} d={element.d} fill={props.color} />
        ))}
    </Svg>
  );
};

SvgIconV.defaultProps = {
  width: 30,
  height: 30,
  color: 'black',
} as Partial<SvgIconVProps>;
