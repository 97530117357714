import React from 'react';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { View, StyleSheet, Text } from 'react-native';

import { IconV } from 'react-icon-rs';
import Icon from 'rsuite/lib/Icon';

import config from './config/default-blueprint.json';

const BP = config?.pageNotFound;

export const PageNotFoundLG = () => {
    const styles = {
        bodyStyle: {
            height: '100vh',
            width: '100%',
            backgroundImage: 'linear-gradient(#2a4564, #141e30)',
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center'
        },
        spinnerStyle: {
            color: '#fff',
            position: 'absolute',
            top: '46%',
            left: '48%'
        },
        imgStyle: {
            margin: '0 auto',
            top: '36%',
            left: '40%'
        },
        titleStyle: {
            textAlign: 'center',
            fontSize: 32,
            fontWeight: 700,
            color: '#fff',
        },
        desciptionStyle: {
            width: '90%',
            alignItems: 'center',
            alignSelf: 'center',
            display: 'block',
            textAlign: 'center',
            lineHeight: 26,
            fontSize: 18,
            color: '#fff',
            padding: 10
        }

    }
    return (
        <FBGridV justify={'center'}>
            <FBItemV colspan={16} style={StyleSheet.flatten([styles.bodyStyle])}>
                <View style={{ margin: '12%', padding: 15, border: '1px solid #fff', borderRadius: 25 }}>
                    <img src={BP?.logo} width={120} height={'auto'} style={styles.imgStyle} />
                    <Text style={styles.titleStyle}>
                        {BP?.description1}
                    </Text>
                    <Text style={styles.desciptionStyle}>
                        {BP?.description2}
                    </Text>
                    <Text style={styles.desciptionStyle}>
                        {BP?.description3} <br /><a href="https://www.vlinder.io">www.vlinder.io</a><br /> to know more
                    </Text>
                </View>
            </FBItemV>
        </FBGridV >
    )
}

