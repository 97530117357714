import React from 'react';
import {View, ViewStyle, TextStyle, StyleSheet} from 'react-native';

import FAB from 'react-native-fab';
import {Icon} from 'react-native-elements';

interface FABVProps {
  fabContainerStyle?: ViewStyle;
  fabButtonColor?: string;
  iconTextColor?: string;
  onFabClick(): void;
  visible: boolean;
  iconName: string;
  iconColor: string;
  snackOffset?: number;
  iconType: string;
  iconSize: number;
}

export const FABV = (props: FABVProps): JSX.Element => {
  const {
    fabContainerStyle,
    fabButtonColor,
    iconTextColor,
    onFabClick,
    visible,
    iconName,
    iconColor,
    snackOffset,
    iconType,
    iconSize,
  } = props;
  return (
    <View style={StyleSheet.flatten([styles.fabContainer, fabContainerStyle])}>
      <FAB
        buttonColor={fabButtonColor}
        iconTextColor={iconTextColor}
        onClickAction={onFabClick}
        visible={visible}
        iconTextComponent={
          <Icon
            name={iconName}
            type={iconType}
            color={iconColor}
            size={iconSize}
          />
        }
        snackOffset={snackOffset}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  fabContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
  },
});

FABV.defaultProps = {
  visible: true,
  snackOffset: 2,
  iconName: 'plus',
  iconType: 'foundation',
  iconColor: 'white',
  iconSize: 40,
  fabButtonColor: 'red',
  onFabClick: () => {},
} as Partial<FABVProps>;
