import React from 'react';
import {View, Text } from 'react-native';
import config from './config/default-blueprint.json';
import {Icon} from 'rsuite';

const BP = config.verificationFailure;

export const VerificationFailure = () => {
    return(
        <View style={{justifyContent:'center', alignItems:'center', marginTop:'15%'}}>
            <Icon
            icon={'exclamation-circle'}
            size={"5x"}
            style={{color:'orange'}}
            />
            <Text style={{padding:10, fontSize:18 }}>
                {BP?.errorText}
            </Text>
        </View>
    )
}