import React from 'react';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {StyleSheet, Text, View} from 'react-native';
import {AvatarV} from 'react-native-avatar-vfw';
import {IProps} from './index';

export const BenefitsSM = (props: IProps) => {
  const styles = {
    benefitsContainerStyle: {
      paddingLeft: '5%',
      paddingRight: '5%',
      justifyContent: 'space-around',
      alignItems: 'center',
      paddingTop: 100,
      paddingBottom: 80,
      backgroundColor: '#F0F0F0',
    },
    benefitsTitleStyle: {
      display: 'block',
      textAlign: 'center',
      fontSize: 24,
      marginBottom: 16,
    },
    benefitsDescStyle: {
      display: 'block',
      textAlign: 'center',
      fontSize: 16,
      marginBottom: 36,
    },
    benefitsCardTitleStyle: {
      fontSize: 18,
      textAlign: 'center',
      fontWeight: 600,
      textTransform: 'uppercase',
      marginBottom: 20,
    },
  };
  let _benefits =
    Array.isArray(props?.benefits?.benefitsData) &&
    props?.benefits?.benefitsData.map(item => {
      return (
        <FBItemV colspan={22}>
          <View>
            <AvatarV
              source={{uri: item?.icon}}
              avatarStyle={StyleSheet.flatten([{resizeMode: 'contain', width: 80, height: 80}, item?.iconStyle])}
              containerStyle={{
                width: 80,
                height: 80,
                justifyContent: 'center',
                alignSelf: 'center',
                marginBottom: 22,
              }}
            />
            <Text style={StyleSheet.flatten([styles.benefitsCardTitleStyle])}>
              {item?.title}
            </Text>
            <Text style={{fontSize: 16, textAlign: 'center', marginBottom: 22}}>
              {item?.desciption}
            </Text>
          </View>
        </FBItemV>
      );
    });
  return (
    <>
      <FBItemV colspan={24}>
        <View style={StyleSheet.flatten([styles.benefitsContainerStyle])}>
          <Text style={StyleSheet.flatten([styles.benefitsTitleStyle])}>
            {props?.benefits?.benefitsTitle}
          </Text>
          <Text style={StyleSheet.flatten([styles.benefitsDescStyle])}>
            {props?.benefits?.benefitsDesciption}
          </Text>
          <FBGridV justify={'space-around'}>{_benefits}</FBGridV>
        </View>
      </FBItemV>
    </>
  );
};
