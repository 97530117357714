import React, { useEffect } from 'react';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { useHistory } from 'react-router-dom';
import { EnrollForm } from './components';
import { Alert } from 'rsuite';
import { useProductView } from '../ProductView/provider';

const PromoEnrollViewInternal = () => {
    const history = useHistory();
    const { batchPromoDetails } = useProductView();

    const _handleSubmit = () => {
        if (batchPromoDetails?.isPromoWebsiteUrlEnabled){
            window.open(batchPromoDetails?.promoWebsiteUrl, '_blank');
        }
    }

    return (
        <EnrollForm onEnrollSubmit={_handleSubmit} />
    );
}

export const PromoEnrollView = () => {
    return (
        <ViewportProvider>
            <PromoEnrollViewInternal />
        </ViewportProvider>
    );
}