import * as React from "react"
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Factory({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={888}
      height={460.526}
      viewBox="0 0 888 460.526"
      aria-labelledby={titleId}
      {...props}
    >
      {title === undefined ? (
        <title id={titleId}>{"factory"}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <path
        d="M213.701 452.706l-21.874-34.714 21.884 38.496.012 3.955q2.34-.027 4.635-.18l-1.785-50.811.024-.393-.04-.075-.17-4.802 22.871-38.046-22.895 34.45-.068 1.018-1.348-38.39 19.662-39.365-19.816 32.618-.922-79.573-.004-.264-.003.26-.449 62.738-18.788-25.102 18.805 30.506.087 34.362-17.433-33.187 17.443 38.239.048 19.107-25.323-46.209 25.343 52.874zM401.413 452.706l-21.875-34.714 21.885 38.496.012 3.955q2.34-.027 4.634-.18l-1.784-50.811.023-.393-.04-.075-.17-4.802 22.872-38.046-22.895 34.45-.069 1.018-1.348-38.39 19.663-39.365-19.816 32.618-.922-79.573-.004-.264-.003.26-.45 62.738-18.788-25.102 18.805 30.506.088 34.362-17.433-33.187 17.442 38.239.049 19.107-25.323-46.209 25.342 52.874zM25.811 454.939l-15.795-25.067 15.803 27.797.008 2.857q1.69-.02 3.347-.13l-1.289-36.691.017-.284-.029-.054-.122-3.467 16.515-27.473-16.533 24.877-.049.734-.973-27.722 14.198-28.424-14.31 23.553-.665-57.46-.003-.19-.002.188-.324 45.303-13.567-18.127 13.579 22.029L25.68 402l-12.588-23.964 12.595 27.612.035 13.797L7.436 386.08l18.3 38.18zM857.945 452.706l-21.874-34.714 21.884 38.496.012 3.955q2.34-.027 4.634-.18l-1.784-50.811.024-.393-.04-.075-.17-4.802 22.871-38.046-22.895 34.45-.069 1.018-1.347-38.39 19.662-39.365-19.816 32.618-.922-79.573-.004-.264-.003.26-.449 62.738-18.788-25.102 18.805 30.506.087 34.362-17.433-33.187 17.442 38.239.049 19.107-25.323-46.209 25.343 52.874z"
        fill="#e2e2e2"
      />
      <path
        fill={props?.tertiary || "#5987af" }
        d="M382.714 400.295h-118v-5h118zM376.714 395.295h-118v-5h118zM383.714 390.295h-118v-5h118zM395.714 385.295h-118v-5h118z"
      />
      <path fill="#3f3d56" d="M181.41 387.289h-4.097V358.2h4.097z" />
      <path
        fill="#3f3d56"
        d="M178.845 361.627l-1.54-3.796 151.608-61.536 1.54 3.796zM137.163 430.717h-35.644v-13.93h35.644z"
      />
      <path fill="#3f3d56" d="M280.556 444.646h-175.76v-15.568h175.76z" />
      <path
        fill="#e2e2e2"
        d="M133.68 436.862h42.199v-53.056h-31.547l-10.652 26.759v26.297z"
      />
      <path
        fill="#fff"
        d="M169.324 405.11h-18.027v-18.027h18.027zM137.777 405.315h11.472v-18.026h-4.917l-6.555 18.026z"
      />
      <path
        fill="#3f3d56"
        d="M168.504 423.137h-4.097v-7.784h4.097zM145.766 406.544h-.205a3.892 3.892 0 01-3.892-3.892 3.892 3.892 0 013.892-3.892h.205v7.784zM298.174 436.862h-.205a3.892 3.892 0 01-3.892-3.892 3.892 3.892 0 013.892-3.892h.205v7.784z"
      />
      <path
        d="M117.292 429.019a20.27 20.27 0 00-15.755 7.843H93.53l7.784-21.684 36.258-7.15-1.024 28.834h-3.5a20.27 20.27 0 00-15.756-7.843z"
        fill="#e2e2e2"
      />
      <circle cx={116.883} cy={441.983} r={17.822} fill="#3f3d56" />
      <circle cx={116.883} cy={441.983} r={9.38} fill="#e2e2e2" />
      <circle cx={277.894} cy={444.032} r={15.568} fill="#3f3d56" />
      <circle cx={277.894} cy={444.032} r={8.194} fill="#e2e2e2" />
      <path fill="#3f3d56" d="M183.254 438.5h-8.604v-62.068h8.604z" />
      <path
        fill="#3f3d56"
        d="M298.378 430.922H174.65v-3.687h123.73zM147.405 407.773h-4.097v-1.639h4.097zM324.394 400.265a.41.41 0 01-.104-.319l5.65-53.432a.41.41 0 01.406-.367h.002a.41.41 0 01.407.364l6.111 53.32a.41.41 0 01-.403.457l-11.762.11h-.003a.41.41 0 01-.304-.133zm5.97-49.967l-5.21 49.277 10.846-.102zM101.39 415.494l.191.073a3.892 3.892 0 012.264 5.017 3.892 3.892 0 01-5.018 2.264l-.191-.073 2.754-7.28z"
      />
      <path fill="#3f3d56" d="M332.588 356.152h-4.097v-59.816h4.097z" />
      <circle cx={179.361} cy={359.02} r={5.736} fill="#3f3d56" />
      <path
        fill={props?.tertiary || "#5987af" }
        d="M305.714 427.295h-118v-5h118zM312.714 422.295h-118v-5h118z"
      />
      <path
        fill={props?.tertiary || "#5987af" }
        d="M307.714 418.295h-118v-5h118zM314.714 413.295h-118v-5h118z"
      />
      <path
        d="M382.337 122.242a15.596 15.596 0 0110.237 3.814 15.606 15.606 0 016.887-.302 15.66 15.66 0 0128.555 8.887h-60.997a15.665 15.665 0 0115.318-12.399zM290.572 171.59a14.744 14.744 0 019.677 3.606 14.755 14.755 0 016.511-.286 14.805 14.805 0 0126.996 8.402H276.09a14.81 14.81 0 0114.482-11.722zM198.824 90.198a15.34 15.34 0 0110.07 3.752 15.351 15.351 0 016.774-.298 15.404 15.404 0 0128.088 8.742h-60a15.409 15.409 0 0115.068-12.196zM594.999 245.186V370.09h48.574v-126.64l-11.276.402v-25.557h-8.179l-1.347-56.604c3.3-2.15 5.623-7.258 5.623-13.222a21.669 21.669 0 00-.594-5.084 10.4 10.4 0 001.12-12.667 10.407 10.407 0 1013.487-15.852 10.41 10.41 0 10-14.013-9.767c0 .153.005.304.011.454a10.415 10.415 0 00-13.338 8.383 10.409 10.409 0 10-7.485 19.127c-.002.076-.006.15-.006.227a50.6 50.6 0 003.676 8.434 21.026 21.026 0 00-1.074 6.745c0 5.964 2.323 11.072 5.623 13.222l-1.348 56.604h-8.178v26.487zm30.332-119.269h.027c.15 0 .299-.004.447-.01q.141.46.322.903a10.453 10.453 0 00-.796-.893z"
        fill="#e2e2e2"
      />
      <path fill="#3f3d56" d="M715.712 459H568.254V288.411h147.458z" />
      <path
        fill="#e2e2e2"
        d="M661.86 459h-39.755v-34.696h39.756zM519.11 63.955c-.002.126-.009.25-.009.377a17.293 17.293 0 005.26 12.438 17.33 17.33 0 00.867 1.62 35.03 35.03 0 00-1.79 11.242c0 13.173 6.796 23.853 15.18 23.853s15.18-10.68 15.18-23.853a36.105 36.105 0 00-.99-8.474 17.334 17.334 0 001.867-21.113 17.346 17.346 0 1022.478-26.419 17.35 17.35 0 10-23.356-16.278c0 .254.009.506.02.757a17.36 17.36 0 00-22.23 13.971 17.348 17.348 0 10-12.476 31.879zm29.627-11.91c.25 0 .499-.01.747-.02q.233.77.536 1.506a17.387 17.387 0 00-1.328-1.488z"
      />
      <path
        d="M529.941 232.03h17.35l-.62-26.02V206l-2.27-95.41a11.095 11.095 0 01-11.57 0l-2.27 95.41v.01z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        fill={props?.tertiary || "#5987af" }
        d="M498.141 250.82V459h80.951V247.93l-18.791.67v-42.59h-43.37v44.14l-18.79.67z"
      />
      <path
        d="M725.841 63.955c-.003.126-.01.25-.01.377a17.293 17.293 0 005.26 12.438 17.33 17.33 0 00.868 1.62 35.03 35.03 0 00-1.79 11.242c0 13.173 6.796 23.853 15.18 23.853s15.179-10.68 15.179-23.853a36.106 36.106 0 00-.99-8.474 17.334 17.334 0 001.867-21.113 17.346 17.346 0 1022.48-26.419 17.35 17.35 0 10-23.357-16.278c0 .254.008.506.019.757a17.36 17.36 0 00-22.23 13.971 17.348 17.348 0 10-12.476 31.879zm29.627-11.91c.25 0 .499-.01.746-.02q.234.77.536 1.506a17.385 17.385 0 00-1.327-1.488z"
        fill="#e2e2e2"
      />
      <path
        d="M736.674 232.03h17.348l-2.891-121.436a17.1 17.1 0 01-11.565 0z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        fill={props?.tertiary || "#5987af" }
        d="M704.871 250.82V459h80.96V247.93l-6.65.24-12.15.43v-42.59h-43.37v44.14l-18.79.67z"
      />
      <path
        fill="#fff"
        d="M683.983 329h-20v-20h20zM651.983 329h-20v-20h20zM619.983 329h-20v-20h20zM683.983 361h-20v-20h20zM651.983 361h-20v-20h20zM619.983 361h-20v-20h20zM683.983 393h-20v-20h20zM651.983 393h-20v-20h20zM619.983 393h-20v-20h20zM548.617 321h-20v-20h20zM548.617 353h-20v-20h20zM548.617 385h-20v-20h20zM548.617 417h-20v-20h20zM755.182 321h-20v-20h20zM755.182 353h-20v-20h20zM755.182 385h-20v-20h20zM755.182 417h-20v-20h20z"
      />
      <path fill="#3f3d56" d="M0 458.295h888v2H0z" />
    </svg>
  )
}
