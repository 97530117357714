//@ts-nocheck
import React from 'react';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {StyleSheet, View} from 'react-native';
import {AvatarV} from 'react-native-avatar-vfw';
import {ButtonV} from 'react-native-button-vfw';
import {TextV} from 'react-native-text-vfw';
import {useViewport} from 'react-viewport-provider';
import Divider from 'rsuite/lib/Divider';
import Nav from 'rsuite/lib/Nav';
import {LandingPageProps} from '../LandingPageV';

const ids = require('short-id');

const AVATAR_WIDTH = 40;
const AVATAR_HEIGHT = 40;
const PROFILE_ICON_COLOR = '#333333';
const TITLE_FONT_COLOR = '#565D61';
const TEXT_COLOR = '#fff';

export type IconPropsInternal = {
  style?: any;
  name: string | any;
  stack?: '1x' | '2x';
  componentClass?: any;
  fixedWidth?: boolean;
  flip?: 'horizontal' | 'vertical';
  inverse?: boolean;
  pulse?: boolean;
  rotate?: boolean;
  size?: 'lg' | '2x' | '3x' | '4x' | '5x';
  spin?: boolean;
  svgStyle?: any;
  iconStyle?: any;
};
export type ProfileDetail = {
  icon?: IconPropsInternal;
  title?: string;
  subtitle1?: string;
  subtitle2?: string;
  tooltip?: string;
  type?: 'string' | 'url';
};

export const HeaderV = (props: LandingPageProps) => {
  const {width, height, bp} = useViewport();

  const styles = {
    avatarContainerStyle: {
      width: AVATAR_WIDTH,
      height: AVATAR_HEIGHT,
    },
    titleContainerStyle: {
      height: 35,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 20,
      paddingLeft:bp!== 'sm' && bp!== 'xs' ? 0 : '5%',
      paddingRight:bp!== 'sm' && bp!== 'xs' ? 0 : '5%'
    },
    titleStyle: {
      color: 'rgba(86,93,97,0.8)',
      fontWeight: 600,
      marginLeft: 10,
    },
    subtitleStyle: {
      color: TITLE_FONT_COLOR,
      marginLeft: 5,
    },
    avatarTitleContainerStyle: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      // marginLeft: bp === 'xs' || bp === 'sm' ? 10 : 15,
    },
    loginButtonStyle: {
      width: 65,
      borderRadius: 5,
      alignSelf: 'flex-end',
      borderColor: TEXT_COLOR,
    },
    signupButtonStyle: {
      width: 80,
      borderRadius: 5,
      marginLeft: 15,
      alignSelf: 'center',
    },
    signupButtonContainer: {
      width: 65,

      borderRadius: 5,
      //  alignSelf: bp !== 'xs' && bp !== 'sm' && 'center',
    },
    loginButtonContainerStyle: {
      alignItems: 'flex-end',
    },
    signupButtonContainerStyle: {
      alignItems: 'flex-end',
    },
    loginButtonTitleStyle: {
      color: TEXT_COLOR,
      fontSize: 15,
    },
    signupButtonTitleStyle: {
      color: '#FFFFFF',
      fontSize: 15,
    },
    profileContainer: {
      width: '80%',
    },
    profileItemContainer: {
      justifyContent: 'flex-start',
      flexDirection: 'row',
      alignItems: 'center',
      marginHorizontal: 30,
      marginBottom: 25,
    },
    profileIconStyle: {
      color: PROFILE_ICON_COLOR,
      alignSelf: 'center',
      width: 10,
      // height: 10,
    },
    profileTitleGroupContainer: {
      marginLeft: 30,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    buttonContainer: {
      // flexDirection: 'row',
      width: '100%',
    },
    containerStyle: {},
  };

  const renderBody = () => {
    const _body = [];
    if (props.title) {
      _body.push(renderHeader());
      // _body.push(<Divider key={ids.generate()} />);
      //    if(props.renderBody) {
      //        _body.push(props.renderBody())
      //    }
    }
    if (props.loginButtonTitle) {
      _body.push(<Divider key={ids.generate()} />);
    }

    return _body;
  };

  const renderMenu = () => {
    if (bp !== 'xs' && bp !== 'sm') {
      return (
        <View style={{alignItems: 'flex-end'}}>
          <Nav
            appearance={'default'}
            pullRight
          >
            {Array.isArray(props?.headerMenu) &&
              props?.headerMenu.map(item => {
                return (
                  <Nav.Item
                    href={item?.href ?? `/#${item?.eventKey}`}
                    style={{fontSize: 16, fontWeight: 'bold'}}
                    target={item?.href ? '_blank': null}
                  >
                    {item?.label}
                  </Nav.Item>
                );
              })}
          </Nav>
        </View>
      );
    } else {
      return null;
    }
  };

  const renderHeader = () => {
    if (props.title) {
      return (
        <FBGridV
          justify={'space-between'}
          style={StyleSheet.flatten([styles.titleContainerStyle])}
        >
          <FBItemV colspan={bp === 'xs' || bp === 'sm' || bp === 'md' ? 6 : 4}>
            <View
              style={StyleSheet.flatten([styles.avatarTitleContainerStyle])}
            >
              {props.leftAvatar && renderAvatar(props.leftAvatar)}
              {props.title && <View>{renderTitle(props.title)}</View>}
            </View>
          </FBItemV>
          <FBItemV colspan={12}>
            {renderMenu()}
          </FBItemV>
          <FBItemV colspan={bp === 'xs' || bp === 'sm' || bp === 'md' ? 6 : 2}>
            {props.loginButtonTitle && renderButton()}
          </FBItemV>
        </FBGridV>
      );
    }
    return null;
  };

  const renderAvatar = (avatar: any) => {
    if (avatar) {
      return (
        <AvatarV
          containerStyle={StyleSheet.flatten([styles.avatarContainerStyle])}
          size={AVATAR_WIDTH}
          rounded
          source={{uri: avatar}}
        />
      );
    }
    return null;
  };

  const renderButton = () => {
    if (props.loginButtonTitle || props.signupButtonTitle) {
      return (
        <View style={StyleSheet.flatten([styles.buttonContainer])}>
          {props?.loginButtonTitle ? (
            <ButtonV
              title={props.loginButtonTitle}
              buttonStyle={StyleSheet.flatten([
                styles.loginButtonStyle,
                props.loginButtonStyle,
                props.loginButtonColor && {
                  backgroundColor: props.loginButtonColor,
                },
              ])}
              // type={ButtonType.Outline}
              titleStyle={StyleSheet.flatten([
                styles.loginButtonTitleStyle,
                props.loginButtonTitleStyle,
              ])}
              containerStyle={StyleSheet.flatten([
                styles.loginButtonContainerStyle,
              ])}
              onPress={() => {
                props.onLoginButtonPress &&
                  props.onLoginButtonPress(props.loginButtonId);
              }}
            />
          ) : null}
        </View>
      );
    }
    return null;
  };

  const renderTitle = (title: string) => {
    const _title = [];
    if (title) {
      _title.push(
        <TextV
          key={ids.generate()}
          type={'title2'}
          style={StyleSheet.flatten([
            styles.titleStyle,
            props.titleStyle,
            props.titleColor && {color: props.titleColor},
          ])}
        >
          {String(title)}
        </TextV>,
      );
    }
    if (props.subtitle) {
      _title.push(
        <TextV
          key={ids.generate()}
          type={'callout'}
          style={StyleSheet.flatten([
            styles.subtitleStyle,
            props.subtitleStyle,
            props.subtitleColor && {color: props.subtitleColor},
          ])}
        >
          {String(props.subtitle).toLowerCase()}
        </TextV>,
      );
    }
    return _title;
  };
  return (
    <View
      style={StyleSheet.flatten([
        props?.headerContainerStyle,
        styles.containerStyle,
        {width: 1 * width},
      ])}
    >
      {renderBody()}
    </View>
  );
};
