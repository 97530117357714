import React from 'react';
import { TimeLineV } from '../../../../widgets/TimeLineV';
import { View, StyleSheet, ActivityIndicator, Image, ImageBackground } from 'react-native';
import { IconV as IconRS } from 'react-icon-rs';
import { IconV } from 'react-native-icon-vfw';
import { TextV } from 'react-native-text-vfw';
import { ImageV } from 'react-native-image-vfw';
import {useViewport} from 'react-viewport-provider';
import { AvatarV, AvatarSize } from 'react-native-avatar-vfw';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { titleCase } from 'title-case';
import {useHistory} from 'react-router-dom';
import { ThemeContext } from '../../../../providers/theme';
import { PROV_CARD_BG_WALLPAPER } from '../../../../services/loadAssets';
import { useProductView } from '../../provider';
import { TriangleV } from '../../../../widgets/TriangleV';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProvenanceTimeline;
const AVATAR_SIZE = 35;

interface ProvenanceTimelineProps {
    // timeLineData?: any[];
    onTimelineElementClick?(item: any, index: number): void;
};

export const ProvenanceTimelineLG = ({
    onTimelineElementClick
}: ProvenanceTimelineProps) => {
    const theme = React.useContext(ThemeContext);
    const history = useHistory();
    const [show, setShow] = React.useState<number>(0);
    const {width, height} = useViewport();
    const { timeLineData } = useProductView();
    const styles = {
        containerStyle: {
            marginTop: 20,
        },
        timelineContainerStyle: {
            width: '90%',
            paddingBottom: 10,
        },
        titleStyle: {
            fontSize: 20,
            // fontWeight: 'bold',
            // color: theme?.palette?.primary,
            letterSpacing: 1,
            padding: 10,
            marginLeft: 10,
            marginRight: 10
        },
        imageContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            alignSelf: 'center',
            backgroundColor: '#fff',
            shadowOpacity: 0.4,
            shadowRadius: 5,
            shadowColor: '#373a3b',
            elevation: 1,
            shadowOffset: { height: 0, width: 0 },
            marginVertical: 5,
            marginLeft: 5,
            width: 90,
            height: 90,
        },
        imageStyle: {
            width: 89.5,
            height: 89.5,
            borderRadius: 10,
        },
        locationIconContainer: {
            alignSelf: 'center',
            paddingHorizontal: 5,
        },
        dateIconContainer: {
            alignSelf: 'center',
            paddingHorizontal: 6,
        },

        organization: {
            fontSize: 16,
            letterSpacing: 1,
            color: '#373a3b',
            paddingHorizontal: 2,
            paddingTop: 5,
        },
        circleStyle: {
            height: 24,
            width: 24,
            borderRadius: 24 / 2,
        },
        location: {
            fontSize: 18,
            color: '#373a3b',
            paddingVertical: 10,
        },
        date: {
            fontSize: 18,
            color: '#373a3b',
        },
        bgimageStyle: {
            flex: 1,
            resizeMode: 'cover',
            justifyContent: 'center',
            backgroundColor: '#ffffff',
            borderRadius: 10,
            width: 420
        },
        divider: {
            height: 0.5,
            width: '90%',
            backgroundColor: theme?.palette?.gray,
            marginVertical: 10,
        },
        organizationLogoContainer: {
            marginLeft: 5
        },
        dashStyle: {
            width: '40%',
            marginVertical: 10,
        },
        triangleStyle: {
            transform: [{ rotate: '-90deg' }],
        },
        triangleContainer: { position: 'absolute', top: 0, left: 15, borderStyle: 'solid', borderColor: '#000' },
        itemContainerStyle: {
            borderRadius: 10,
            marginTop: 5,
            marginBottom: 5,
            boxShadow:
                '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        },
        rightTriangleStyle: {
            transform: [{ rotate: '90deg' }],
        },
        rightTriangleContainer: { position: 'absolute', top: 5, right: -10, borderStyle: 'solid', borderColor: '#000' },
    };

    const renderDot = (key: string, item: any) => {
        return (
            <View style={{}}>
                <AvatarV
                    source={item.icon}
                    avatarStyle={{
                        width: 32,
                        height: 32,
                        borderRadius: 16,
                        borderColor: '#ddd',
                        borderWidth: 2,
                    }}
                    containerStyle={{
                        position: 'absolute',
                        top: 0,
                        left: -2,
                        width: 40,
                        height: 40,
                        paddingTop: 9,
                        marginLeft: -13,
                        borderRadius: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                />
            </View>
        );
    };

    const _renderTitle = (title: string) => {
        if (title && typeof title === 'string') {
            return (
                <TextV
                    type={'title3'}
                    children={titleCase(title)}
                    ellipsizeMode={'tail'}
                    numberOfLines={1}
                    style={StyleSheet.flatten([styles.titleStyle])}
                />
            );
        }
    };

    const _renderImage = (imageUrl: any) => {
        if (imageUrl) {
            return (
                <View style={StyleSheet.flatten([styles.imageContainer])}>
                    <Image
                        source={{ uri: imageUrl }}
                        resizeMode="cover"
                        style={styles.imageStyle}
                    />
                </View>
            );
        }
    };

    const _renderLocation = (location: string) => {
        if (location && typeof location === 'string') {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <IconV
                        name={'location-on'}
                        type={'material'}
                        size={16}
                        color={theme?.palette?.secondary}
                        containerStyle={styles.locationIconContainer}
                    />
                    <TextV
                        type={'footnote'}
                        children={titleCase(location)}
                        ellipsizeMode={'tail'}
                        numberOfLines={2}
                        style={StyleSheet.flatten([styles.location])}
                    />
                </View>
            );
        }
    };

    const _renderDate = (date: string) => {
        if (date && typeof date === 'string') {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <IconV
                        name={'calendar'}
                        type={'octicon'}
                        size={15}
                        color={theme?.palette?.secondary}
                        containerStyle={styles.dateIconContainer}
                    />
                    <TextV
                        type={'footnote'}
                        children={titleCase(date)}
                        ellipsizeMode={'tail'}
                        numberOfLines={2}
                        style={StyleSheet.flatten([styles.date])}
                    />
                </View>
            );
        }
    };

    const _renderOrganiztionLogo = (
        organization: string,
        organizationLogo: string,
    ) => {
        if (organizationLogo) {
            return (
                <AvatarV
                    source={organizationLogo}
                    rounded
                    size={AVATAR_SIZE}
                    //@ts-ignore
                    containerStyle={StyleSheet.flatten([
                        styles.organizationLogoContainer,
                        organization && { backgroundColor: '#d3d3d3' },
                    ])}
                    title={
                        organization && typeof organization === 'string'
                            ? organization[0]
                            : undefined
                    }
                />
            );
        }
    };

    const _renderOrganization = (
        organization: string,
        organizationLogo: string,
    ) => {
        if (organization && typeof organization === 'string') {
            return (
                <FBGridV justify="center" style={{flex: 1, paddingBottom: 5, marginBottom: 10}}>
                    {/* <FBItemV colspan={24}> */}
                    <View style={StyleSheet.flatten([styles.divider])} />
                    {/* <Dash
                            style={StyleSheet.flatten([styles.dashStyle])}
                            dashColor={theme?.palette?.gray}
                            dashThickness={1}
                            dashLength={5}
                        /> * */}
                    {/* </FBItemV> */}
                    <FBItemV colspan={4}>
                        {_renderOrganiztionLogo(organization, organizationLogo)}
                    </FBItemV>
                    <FBItemV colspan={20}>
                        <TextV
                            type={'title3'}
                            children={titleCase(`${organization}`)}
                            ellipsizeMode={'tail'}
                            numberOfLines={2}
                            style={StyleSheet.flatten([styles.organization])}
                        />
                    </FBItemV>
                </FBGridV>
            );
        }
    };

    // const onTimelineElementClick = (item: any) => {
    //     history.push('/info', item);
    // }
    const onTimelineCardClick = (item: any, index: number) => {
        setShow(index);
        onTimelineElementClick && onTimelineElementClick(item, index);
    }

    const renderItem = (item: any, index: number) => {
        return (
            <>
                <View style={StyleSheet.flatten([styles.triangleContainer])}>
                    <TriangleV
                        size={30}
                        triangleStyle={StyleSheet.flatten([styles.triangleStyle])}
                        color={'#f0f0f0'}
                    />
                </View>
                {index === show &&
                    <View style={StyleSheet.flatten([styles.rightTriangleContainer])}>
                        <TriangleV
                            size={25}
                            triangleStyle={StyleSheet.flatten([styles.rightTriangleStyle])}
                            color={'#757575'}
                        />
                    </View>
                }
            <ImageBackground
                source={PROV_CARD_BG_WALLPAPER}
                style={StyleSheet.flatten([styles.bgimageStyle])}
            >
            <View
                style={
                    ([StyleSheet.flatten(styles.itemContainerStyle)])
                }
                //@ts-nocheck
                        onClick={onTimelineCardClick.bind(null, item, index)}
            >
                    <FBGridV justify="center">
                        <FBItemV colspan={24}>
                            {_renderTitle(item?.title)}
                        </FBItemV>
                        <View style={StyleSheet.flatten([styles.divider])} />
                        <FBItemV colspan={8}>
                            {_renderImage(item?.imageUrl)}
                        </FBItemV>
                        <FBItemV colspan={16}>
                            {_renderLocation(item?.location)}
                            {_renderDate(item?.date)}
                        </FBItemV>
                        <View style={{ flexDirection: 'row', width: '100%' }}>
                            {_renderOrganization(
                                item.organization,
                                item.organizationLogo,
                            )}
                        </View>
                    </FBGridV>
                {/* <FBGridV justify="center">
                    {item.imageUrl && (
                        <FBItemV colspan={8}>
                            <Image
                                source={{ uri: item.imageUrl }}
                                resizeMode="cover"
                                style={styles.imageStyle}
                            />
                        </FBItemV>
                    )}
                    <FBItemV colspan={14}>
                        <View style={{ paddingLeft: 5 }}>
                            {item.title && (
                                <TextV
                                    type={'title3'}
                                    children={
                                        typeof item.title === 'string' && titleCase(item.title)
                                    }
                                    style={StyleSheet.flatten([styles.titleStyle])}
                                    ellipsizeMode={'tail'}
                                    numberOfLines={1}
                                />
                            )}
                            {item.location && (
                                <View style={StyleSheet.flatten([styles.twoColStyle])}>
                                    <IconV
                                        name={'location-on'}
                                        type={'material'}
                                        size={16}
                                        color={'#F05776'}
                                        containerStyle={{ alignSelf: 'center', padding: 5 }}
                                    />
                                    <TextV
                                        type={'footnote'}
                                        children={
                                            typeof item.location === 'string' &&
                                            titleCase(item.location)
                                        }
                                        style={StyleSheet.flatten([styles.subtextStyle])}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={1}
                                    />
                                </View>
                            )}
                            {item.timelineDate && (
                                <View style={StyleSheet.flatten([styles.twoColStyle])}>
                                    <IconV
                                        name={'calendar'}
                                        type={'octicon'}
                                        size={15}
                                        color={'#F05776'}
                                        containerStyle={{
                                            alignSelf: 'center',
                                            paddingHorizontal: 5,
                                        }}
                                    />
                                    <TextV
                                        type={'footnote'}
                                        children={
                                            typeof item.timelineDate === ('string' || Date) &&
                                            titleCase(item.timelineDate)
                                        }
                                        style={StyleSheet.flatten([styles.subtextStyle])}
                                        ellipsizeMode={'tail'}
                                        numberOfLines={1}
                                    />
                                </View>
                            )}
                        </View>
                    </FBItemV>
                    {item.organization && (
                        <FBItemV>
                            <TextV
                                type={'title3'}
                                children={
                                    typeof item.organization === 'string' &&
                                    `By : ${titleCase(item.organization)}`
                                }
                                style={StyleSheet.flatten([styles.organizationStyle])}
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                            />
                        </FBItemV>
                    )}
                </FBGridV> */}
            </View>
            </ImageBackground>
            </>
        );
    };

    return (
        <View
            style={StyleSheet.flatten([styles.containerStyle])}
        >
            <TimeLineV
                items={timeLineData && timeLineData}
                renderItem={renderItem}
                renderDot={renderDot}
                containerStyle={StyleSheet.flatten([styles.timelineContainerStyle])}
                width={'90%'}
                height= {height}
                align={'left'}
            />
        </View>
    );
}