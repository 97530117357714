import React from 'react';
import {View, Text } from 'react-native';
import config from './config/default-blueprint.json';
import {Icon} from 'rsuite';

const BP = config.verificationSuccess;

export const VerificationSuccess = () => {
    return(
        <View style={{justifyContent:'center', alignItems:'center', marginTop:'15%'}}>
            <Icon
            icon={'ok-circle'}
            size={"5x"}
            style={{color:'green'}}
            />
            <Text style={{padding:10, fontSize:18 }}>
                {BP?.successText}
            </Text>
        </View>
    )
}