import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { ButtonV, ButtonType } from 'react-native-button-vfw';
import {useHistory} from 'react-router-dom';
import { IconV } from 'react-native-icon-vfw';
import Rating from '@material-ui/lab/Rating';
import { ThemeContext } from '../../../../providers/theme';
import { FORM_VALUES, defaultValuesDS, SENDER_ID, FormV} from 'react-form-rs';
import {FeedbackProductCard} from '../index';
import {useProductView} from '../../provider';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.RatingInfo;

interface RatingInfo {
    formData?(formData: any): void;
    onFeedbackSubmit?(formData: any): void;
};

let formData = {};

export const RatingInfoSM = (props: RatingInfo) => {
    const parentRef = React.useRef(() => { });
    const [validateForm, setValidateForm] = React.useState<boolean>(false);
    const [rate, setRate] = React.useState(4);
    const history = useHistory();
    const theme = React.useContext(ThemeContext);
    const {feedbackData} = useProductView();
    const title = feedbackData?.prodTitle;
    const subTitle = feedbackData?.prodSubTitle
    const subTitle1 = feedbackData?.prodPrice
    const imageUri = feedbackData?.prodImage

    const styles = {
        formContainer: {
            width: '100%',
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            // overflow: 'scroll',
        },
        buttonTitleStyle: {
            color: '#ffffff',
        },
        buttonContainerStyle: {
            width: 120,
            alignSelf: 'center',
            marginTop: 20,
            marginBottom: 60,
        },
        submitButtonStyle: {
            backgroundColor: theme?.palette.secondary,
            borderRadius: 10,
        },
    }

    const _handleOnValidateResult = (
        formContext?: any,
        status?: 'success' | 'error',
        validateResult: any,
    ) => {
        formData = {};
        formData.name = defaultValuesDS.get('fullName')?.defaultValue;
        formData.email = defaultValuesDS.get('emailAddress')?.defaultValue ?? null;
        formData.contactNo =
            defaultValuesDS.get('contactNumber')?.defaultValue ?? null;
        formData.address = defaultValuesDS.get('feedback')?.defaultValue ?? null;

        if (status === 'success') {
            props.formData(formData);
        }
    }

    const onSubmitPress = () => {
        setValidateForm(true);
        // history.push('/', formData);
        props.onFeedbackSubmit && formData && props.onFeedbackSubmit(formData)
    }

    const handleBackPress = () => {
        window.history.go(-1);
    }

    return (
        <>
            <TouchableOpacity hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
            <IconV
                name={'closecircle'}
                type={'antdesign'}
                onPress={handleBackPress}
                size={26}
                color={'#000'}
                containerStyle={{
                    alignSelf: 'flex-end',
                    marginTop: 20,
                    marginRight: 20,
                    marginBottom: 10,
                }}
            />
            </TouchableOpacity>
            <FeedbackProductCard 
                cardBgColor= {theme?.palette?.secondary}
                imageUri={imageUri}
                title= {title}
                subTitle= {subTitle}
                subTitle1={subTitle1}
            />
            <View style={{width: '100%', justifyContent: 'center', alignItems: 'center'}}>
            <Rating
                name="simple-controlled"
                value={rate}
                onChange={(event: object, value: number) => {
                    setRate(value)
                }}
            />
            </View>
            <FormV
                components={BP?.formFields}
                formContext={{}}
                containerStyle={styles.formContainer}
                validateForm={validateForm}
                onValidateResult={_handleOnValidateResult}
                defaultValues={defaultValuesDS.get()}
                ref={parentRef}
            />
            <View style={{ width: '100%' }}>
                <ButtonV
                    // {...props}
                    title={'Submit'}
                    titleStyle={StyleSheet.flatten([styles.buttonTitleStyle])}
                    type={ButtonType.Solid}
                    buttonStyle={StyleSheet.flatten([styles.submitButtonStyle])}
                    containerStyle={StyleSheet.flatten([styles.buttonContainerStyle])}
                    onPress={onSubmitPress}
                />
            </View>
        </>
    );
}