import React from 'react';
import { View, StyleSheet, Image, ImageSourcePropType, TouchableOpacity } from 'react-native';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { TextV } from 'react-native-text-vfw';
import { titleCase } from 'title-case';
import { IconV } from 'react-native-icon-vfw';
import { useViewport } from 'react-viewport-provider';
import { ImageBackgroundV } from 'react-image-background';
import { AvatarSize, AvatarV } from 'react-native-avatar-vfw';
import { useHistory, useLocation } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { Card } from '@material-ui/core';
import { ThemeContext } from '../../../../providers/theme';
import { SvgIconV } from '../../../../widgets/SvgIconV';
import { Carousel } from '../index';
import { TriangleV } from '../../../../widgets/TriangleV';
import { GoogleMapV } from '../../../../widgets/GoogleMapV';
import config from '../../../../config/default.config.json';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProductInfoScreen;

const RIGHT_AVAVTAR_SIZE = 50;

interface ProductInfoScreenProps {
    isListing?: boolean;
    isFetchingFeatures?: object;
    listFeatures?: any[];
    wallpaperURL?: ImageSourcePropType;
    onClickData?: any;
    googleMapAPIKey?:string;
};

export const ProductInfoScreenMD = ({
    wallpaperURL,
    onClickData,
    googleMapAPIKey
}: ProductInfoScreenProps) => {
    const { height, width } = useViewport();
    const theme = React.useContext(ThemeContext);
    const history = useHistory();
    const location = useLocation();
    const post = onClickData ? onClickData : location.state;
    const styles = {
        rowTitle: {
            flexDirection: 'row',
            paddingHorizontal: 15,
            marginTop: 0,
            minHeight: 50,
            paddingBottom: 30
        },
        rightAvatarStyle: {
            position: 'absolute',
            right: 10,
            top: 0,
            marginRight: 15,
        },
        postTitle: {
            color: '#373a3b',
            fontWeight: 500,
            fontSize: 20,
            textAlign: 'left',
            backgroundColor: 'transparent',
            zIndex: 9999,
        },
        subTitle: {
            color: theme?.palette?.secondary,
            marginRight: 16,
            marginBottom: 14,
            marginLeft: 15,
            fontSize: 18,
            marginTop: 10,
            textAlign: 'left',
            backgroundColor: 'transparent',
            zIndex: 9999,
        },
        subTitle2: {
            color: theme?.palette?.secondary,
            marginRight: 16,
            marginBottom: 14,
            marginLeft: 15,
            fontSize: 18,
            textAlign: 'left',
            backgroundColor: 'transparent',
            zIndex: 9999,
        },
        content: {
            textAlign: 'justify',
            fontSize: 16,
            letterSpacing: 0.95,
            lineHeight: '200%',
            paddingLeft: 5,
            paddingRight: 5
        },
        boxContent: {
            backgroundColor: '#F5F5F5',
            padding: 6,
        },
        boxInfo: {
            paddingTop: 15,
            marginBottom: 10,
            width: '100%'
        },
        tableInfo: {
            paddingTop: 10,
            backgroundColor: '#F5F5F5',
        },
        row: {
            paddingLeft: 5,
            alignItems: 'center',
            justifyContent: 'flex-start',
            zIndex: 9999,
            marginHorizontal: 5,
            flexDirection: 'row',
        },
        label: {
            color: '#000',
            width: '30%',
            paddingLeft: 10,
            fontSize: 14,
            lineHeight: 30,
            alignSelf: 'center',
        },
        tableLabel: {
            color: '#000',
            width: 200,
            paddingLeft: 12,
            paddingTop: 4,
            fontSize: 12,
            lineHeight: 30,
        },
        text: {
            color: theme?.palette?.secondary,
            fontSize: 14,
            width: '60%',
            lineHeight: 30,
            alignSelf: 'center',
        },
        rightText: {
            color: '#000',
            fontSize: 14,
            paddingTop: 5,
            // width: width / 2,
            lineHeight: 30,
            alignSelf: 'flex-start',
            marginRight: 25,
        },
        rightContainer: {
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'row',
        },
        headerTop: {
            position: 'absolute',
            left: 0,
            top: 0,
        },
        infoContainerStyle: {
            paddingTop: 20
        },
        containerStyle: {
            // marginTop: post?.index * 210 + post?.index * 20,
            minHeight: height,
        },
        provenanceInfoCardStyle: {
            width: '100%',
            borderRadius: 10,
            marginTop: 20,
            paddingLeft: 10,
            paddingRight: 10,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#ffffff',
            // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
            padding: -20
        },
        triangleStyle: {
            transform: [{ rotate: '-90deg' }],
        },
        triangleContainer: { position: 'absolute', top: 20, left: -23, borderStyle: 'solid', borderColor: '#000' },
    };

    const _renderInfo = () => {
        const postTitle = post && post?.title
        const subTitle = post && post?.subTitle
        const subTitle2 = post && post?.subTitle2
        const content = post && post?.content
        const source = post && post?.organizationLogo
        return (
            <>
                <View style={StyleSheet.flatten([styles.infoContainerStyle])}>
                    <View style={StyleSheet.flatten([styles.rowTitle])}>
                        {postTitle && (
                            <TextV
                                type={'title3'}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                                style={StyleSheet.flatten([styles.postTitle])}
                            >
                                {postTitle}
                            </TextV>
                        )}
                        {source && (
                            <AvatarV
                                rounded
                                title={postTitle && postTitle[0]}
                                source={source}
                                size={RIGHT_AVAVTAR_SIZE}
                                containerStyle={StyleSheet.flatten([styles.rightAvatarStyle])}
                            />
                        )}
                    </View>

                    {subTitle && (
                        <TextV
                            type={'headline'}
                            numberOfLines={1}
                            ellipsizeMode={'tail'}
                            style={StyleSheet.flatten([styles.subTitle])}
                        >
                            {subTitle}
                        </TextV>
                    )
                    }
                </View>
            </>
        );
    }

    const _renderContent = () => {
        const postTitle = post && post?.title
        const subTitle = post && post?.subTitle
        const subTitle2 = post && post?.subTitle2
        const content = post && post?.content
        const source = post && post?.organizationLogo
        return (
            <>
                <View style={StyleSheet.flatten([styles.infoContainerStyle])}>
                    {
                        subTitle2 && (
                            <TextV
                                type={'headline'}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                                style={StyleSheet.flatten([styles.subTitle2])}
                            >
                                {subTitle2}
                            </TextV>
                        )
                    }
                    <View style={StyleSheet.flatten([styles.boxContent])}>
                        <TextV
                            type={'footnote'}
                            style={StyleSheet.flatten([styles.content])}
                        >
                            {content}
                        </TextV>
                    </View>
                </View>
            </>
        );
    }

    const _openBox = (element: any) => {
        if (element) {
            switch (element.index) {
                case 0 /* location */:
                    const { latitude, longitude } = element.location;
                    const url = `http://maps.apple.com/?ll=${latitude},${longitude}`;
                    const urlGG = `https://google.com/maps/place/${latitude},${longitude}`;
                    // WebBrowser.openBrowserAsync(Platform.OS == 'ios' ? url : urlGG);
                    window.open(urlGG);
                    break;
                case 1 /* Phone */:
                    // const phone = element.value;
                    // const phoneOpen =
                    //   Platform.OS == 'ios' ? `telprompt:${phone}` : `tel:${phone}`;
                    // Linking.canOpenURL(phoneOpen)
                    //   .then(supported => {
                    //     if (supported) {
                    //       return Linking.openURL(phoneOpen).catch(err => {}
                    //       );
                    //     }
                    //   })
                    //   .catch(err => {});
                    return null;
                    break;
                case 2 /* Twitter */:
                    const twitter = element.value;
                    // Linking.openURL(`https://twitter.com/${twitter}`);
                    window.open(`https://twitter.com/${twitter}`);
                    break;
                // case 3 /* Facebook */:
                //   break;
                default:
                    const link = element.url || element.value;
                    if (link) {
                        let website;
                        if (link.includes('http://') || link.includes('https://')) {
                            website = link;
                        } else {
                            return;
                        }
                        // WebBrowser.openBrowserAsync(website).catch(err => {});
                        window.open(website)
                    }
                    return;
            }
        }
    }

    const _listItem = (element: any, index: number) => {
        return (
            <>
                <TouchableOpacity
                    key={`box-info-${index}`}
                    onPress={() => {
                        _openBox(element);
                    }}
                >
                    <View style={StyleSheet.flatten([styles.row])}>
                        {element.path && (
                            <SvgIconV
                                color={theme?.palette?.secondary}
                                path={element?.path}
                                width={element?.width}
                                height={element?.height}
                            />
                        )}
                        {element?.label && (
                            <TextV
                                type={'headline'}
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                                style={StyleSheet.flatten([styles.label])}
                            >
                                {/* {Languages[element.label] || element.label} */}
                                {element.label}
                            </TextV>
                        )}
                        {element?.value && (
                            <TextV
                                type={'headline'}
                                numberOfLines={1}
                                children={titleCase(`${element.value}`)}
                                ellipsizeMode={'tail'}
                                style={StyleSheet.flatten([styles.text])}
                            />
                        )}
                    </View>
                </TouchableOpacity>
            </>
        );
    }


    const _renderListingContent = () => {
        const postTitle = post && post?.title
        const subTitle = post && post?.subTitle
        const subTitle2 = post && post?.subTitle2
        const boxInfo = post && post?.boxInfo
        // const tableInfo = post ? post.tableInfo ? post.tableInfo : '' : '';
        const content = post && post?.content
        const source = post && post?.organizationLogo
        const length = boxInfo && boxInfo.length;
        let leftContent = [];
        let rightContent = [];
        {
            boxInfo &&
            boxInfo.map((element, index) => {
                if (index <= (length - 1) / 2) {
                    leftContent.push(_listItem(element, index))
                } else {
                    rightContent.push(_listItem(element, index))
                }
            });
            return (
                <View style={StyleSheet.flatten([styles.boxInfo])}>
                    <FBGridV>
                        <FBItemV colspan={12}>
                            {leftContent}
                        </FBItemV>
                        <FBItemV colspan={12}>
                            {rightContent}
                        </FBItemV>
                    </FBGridV>
                </View>
            );
        }
    }

    const _renderMap = () => {
        const _markers = post ? (post.latitude && post.longitude) ? [{ lat: post.latitude, lng: post.longitude }] : [] : [{ lat: BP.post.latitude, lng: BP.post.longitude }]
        const zoomCenter = {
            center: _markers,
            zoom: 6
        }
        return (
            <div style={{ height: '40vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: googleMapAPIKey,
                    }}
                    defaultCenter={{ lat: post.latitude, lng: post.longitude }}
                    defaultZoom={4}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={
                        ({ map, maps }) => {
                            if (
                                Array.isArray(_markers) &&
                                _markers.length > 0
                            ) {
                                _markers.forEach((location, index) => {
                                    let marker = new maps.Marker({
                                        position: location,
                                        map,
                                        titile: post.title,
                                    });
                                });
                            }
                        }
                        // renderMarker(map, maps, element.markers)
                    }
                ></GoogleMapReact>
            </div>
        );
    }

    const handleBackPress = () => {
        window.history.go(-1);
    }

    return (
        <>

            <View style={StyleSheet.flatten([styles.containerStyle])}>
                {/* <View style={StyleSheet.flatten([styles.triangleContainer])}>
                    <TriangleV
                        size={25}
                        triangleStyle={StyleSheet.flatten([styles.triangleStyle])}
                        color={'#757575'}
                    />
                </View> */}
                <Card style={StyleSheet.flatten([styles.provenanceInfoCardStyle])}>
                    <FBGridV>
                        <FBItemV colspan={24}>
                            {_renderInfo()}
                        </FBItemV>
                        <FBItemV colspan={24}>
                            <Carousel images={post && post?.galleryImages} />
                        </FBItemV>
                        <FBItemV colspan={24}>
                            {_renderContent()}
                        </FBItemV>
                        <FBItemV colspan={24}>
                            {_renderListingContent()}
                        </FBItemV>
                        <FBItemV colspan={24}>
                            {_renderMap()}
                        </FBItemV>
                    </FBGridV>
                </Card>
            </View>
        </>
    );

};