import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { BatchProducts } from './components/Products';

export interface IProps {
    onCardClick?(eventKey?:string): void;
    products?:any[];
}

export const ProductView = (props: IProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
    //     return <DashboardSM />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    //bp === 'lg' || bp === 'xl' || bp === 'md
    return <BatchProducts {...props} />;
};
