//@ts-nocheck
import GoogleMapReact from 'google-map-react';
import React from 'react';
import {View} from 'react-native';

const K_MARGIN_TOP = 30;
const K_MARGIN_RIGHT = 30;
const K_MARGIN_BOTTOM = 30;
const K_MARGIN_LEFT = 30;

const K_HOVER_DISTANCE = 40;

export function customDistanceToMouse(pt, mousePos, markerProps) {
  const K_SCALE_NORMAL = 0.65;

  const K_MARKER_HEIGHT = 60;
  // marker is more tall at top, so calc distance to some point at marker top
  const K_MARKER_WEIGHT_PT = K_MARKER_HEIGHT * 0.7;
  // distance to markers depends on scale so hover on big markers is more probable
  const scale = markerProps.scale;
  const x = pt.x;
  const y = pt.y - K_MARKER_WEIGHT_PT * scale;

  const scaleNormalized = Math.min(scale / K_SCALE_NORMAL, 1);
  const K_MIN_DIST_MIN_KOEF = 0.6;

  const distKoef = 1 + scaleNormalized * (K_MIN_DIST_MIN_KOEF - 1);
  return (
    distKoef *
    Math.sqrt(
      (x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y),
    )
  );
}

export interface GoogleMapVProps {
  bootstrapURLKeys?: {
    key?: string;
  };
  defaultCenter?: {
    lat?: number;
    lng?: number;
  };
  defaultZoom?: number;
  markers?: object[];
  width: number;
  height: number;
  propsStyle?: object;
  zoom?: number;
  center?: {
    lat?: number;
    lng?: number;
  };
  onBoundsChange?(
    center: number[],
    zoom: number,
    bounds: number[],
    marginBounds: number[],
  ): void;
  onChildClick?(key: number, childProps: any): void;
  onChildMouseEnter?(key: number, childProps: any): void;
  onChildMouseLeave?(key: number, childProps: any): void;
  yesIWantToUseGoogleMapApiInternals?: boolean;
  renderMarker?(marker: object, index: number): any;
}

export const GoogleMapV = ({
  bootstrapURLKeys,
  defaultCenter,
  defaultZoom,
  markers,
  width,
  height,
  propsStyle,
  center,
  zoom,
  onBoundsChange,
  onChildClick,
  onChildMouseEnter,
  onChildMouseLeave,
  yesIWantToUseGoogleMapApiInternals,
  renderMarker,
}: GoogleMapVProps) => {
  return (
    <View style={{ width: width, height: height, borderRadius: 10, }}>
      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={center}
        zoom={zoom}
        //distanceToMouse={customDistanceToMouse}
        onBoundsChange={onBoundsChange}
        margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
        hoverDistance={K_HOVER_DISTANCE}
        onChildClick={onChildClick}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
      >
        {markers &&
          Array.isArray(markers) &&
          markers.map((marker, index) => {
            if (renderMarker) {
              return (
                <div lat={marker?.lat} lng={marker?.lng} key={index}>
                  {renderMarker(marker, index)}
                </div>
              );
            }
          })}
      </GoogleMapReact>
    </View>
  );
};

// export const GoogleMapV = (props: GoogleMapVProps) => {
//   return <GoogleMapInternal {...{...props}} />;
// };
