import * as React from "react"
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function Shopping({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={888}
      height={741.048}
      viewBox="0 0 888 741.048"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M365.896 174.38h-4.032V63.927A63.927 63.927 0 00297.937 0H63.927A63.927 63.927 0 000 63.927v605.954a63.927 63.927 0 0063.927 63.927h234.01a63.927 63.927 0 0063.927-63.927V253.003h4.032z"
        fill="#e6e6e6"
      />
      <path
        d="M300.516 16.632H269.97a22.681 22.681 0 01-21 31.247H114.91a22.681 22.681 0 01-21-31.247H65.38a47.74 47.74 0 00-47.74 47.74v605.064a47.74 47.74 0 0047.74 47.74h235.136a47.74 47.74 0 0047.74-47.74V64.372a47.74 47.74 0 00-47.74-47.74z"
        fill="#fff"
      />
      <path
        d="M113.672 139.635H99.633a1.861 1.861 0 00-1.861 1.861v6.162a1.861 1.861 0 001.861 1.862h2.327v9.233h9.386v-9.233h2.326a1.861 1.861 0 001.862-1.862v-6.162a1.861 1.861 0 00-1.862-1.861z"
        fill="#e6e6e6"
      />
      <path
        d="M127.734 196.032a72.178 72.178 0 01-12.557-42.794 1.522 1.522 0 00-1.188-1.519v-2.663H99.162v2.626h-.204a1.521 1.521 0 00-1.52 1.523q0 .056.004.111 1.752 24.425-11.833 43.808a4.193 4.193 0 00-.759 2.54l1.621 50.152a4.341 4.341 0 004.305 4.19h33.537a4.343 4.343 0 004.307-4.264l.625-48.693a8.788 8.788 0 00-1.51-5.017z"
        fill="#e6e6e6"
      />
      <path
        d="M122.425 212.69H93.234a2.997 2.997 0 00-2.962 3.453l4.41 28.673h25.33l5.359-28.576a2.997 2.997 0 00-2.946-3.55z"
        fill="#fff"
      />
      <path
        d="M269.957 289.87h-14.04a1.861 1.861 0 00-1.86 1.86v6.163a1.861 1.861 0 001.86 1.861h2.327v9.233h9.386v-9.233h2.327a1.861 1.861 0 001.861-1.861v-6.162a1.861 1.861 0 00-1.861-1.862z"
        fill="#e6e6e6"
      />
      <path
        d="M284.019 346.266a72.178 72.178 0 01-12.557-42.793 1.522 1.522 0 00-1.188-1.52v-2.662h-14.828v2.626h-.204a1.521 1.521 0 00-1.52 1.523q0 .055.005.11 1.752 24.426-11.834 43.808a4.193 4.193 0 00-.759 2.541l1.622 50.152a4.341 4.341 0 004.304 4.19h33.537a4.343 4.343 0 004.307-4.264l.625-48.693a8.788 8.788 0 00-1.51-5.018z"
        fill="#e6e6e6"
      />
      <path
        d="M278.71 362.925h-29.192a2.997 2.997 0 00-2.962 3.453l4.411 28.673h25.33l5.358-28.577a2.997 2.997 0 00-2.945-3.55z"
        fill="#fff"
      />
      <path
        d="M93.935 502.257h-6.66a.883.883 0 00-.882.883v2.923a.883.883 0 00.883.883h1.103v4.38h4.452v-4.38h1.104a.883.883 0 00.883-.883v-2.923a.883.883 0 00-.883-.883z"
        fill="#e6e6e6"
      />
      <path
        d="M100.605 529.008a34.237 34.237 0 01-5.956-20.298.722.722 0 00-.564-.721v-1.263h-7.033v1.245h-.097a.722.722 0 00-.72.723l.001.053a31.246 31.246 0 01-5.613 20.78 1.989 1.989 0 00-.36 1.205l.77 23.789a2.06 2.06 0 002.041 1.987h15.908a2.06 2.06 0 002.043-2.022l.297-23.097a4.168 4.168 0 00-.717-2.38z"
        fill="#e6e6e6"
      />
      <path fill="#fff" d="M83.137 537.753h15.179v7.59H83.137z" />
      <path
        d="M124.935 502.257h-6.66a.883.883 0 00-.882.883v2.923a.883.883 0 00.883.883h1.103v4.38h4.452v-4.38h1.104a.883.883 0 00.883-.883v-2.923a.883.883 0 00-.883-.883z"
        fill="#e6e6e6"
      />
      <path
        d="M131.605 529.008a34.237 34.237 0 01-5.956-20.298.722.722 0 00-.564-.721v-1.263h-7.033v1.245h-.097a.722.722 0 00-.72.723l.001.053a31.246 31.246 0 01-5.613 20.78 1.989 1.989 0 00-.36 1.205l.77 23.789a2.06 2.06 0 002.041 1.987h15.908a2.06 2.06 0 002.043-2.022l.297-23.097a4.168 4.168 0 00-.717-2.38z"
        fill="#e6e6e6"
      />
      <path fill="#fff" d="M114.137 537.753h15.179v7.59h-15.179z" />
      <path
        d="M155.935 502.257h-6.66a.883.883 0 00-.882.883v2.923a.883.883 0 00.883.883h1.103v4.38h4.452v-4.38h1.104a.883.883 0 00.883-.883v-2.923a.883.883 0 00-.883-.883z"
        fill="#e6e6e6"
      />
      <path
        d="M162.605 529.008a34.237 34.237 0 01-5.956-20.298.722.722 0 00-.564-.721v-1.263h-7.033v1.245h-.097a.722.722 0 00-.72.723l.001.053a31.246 31.246 0 01-5.613 20.78 1.989 1.989 0 00-.36 1.205l.77 23.789a2.06 2.06 0 002.041 1.987h15.908a2.06 2.06 0 002.043-2.022l.297-23.097a4.168 4.168 0 00-.717-2.38z"
        fill="#e6e6e6"
      />
      <path fill="#fff" d="M145.137 537.753h15.179v7.59h-15.179z" />
      <path
        d="M245.567 444.137h-14.039a1.861 1.861 0 00-1.861 1.861v6.163a1.861 1.861 0 001.861 1.86h2.327v9.234h9.386v-9.233h2.326a1.861 1.861 0 001.862-1.861v-6.163a1.861 1.861 0 00-1.862-1.861z"
        fill="#e6e6e6"
      />
      <path
        d="M259.63 500.534a72.178 72.178 0 01-12.558-42.794 1.522 1.522 0 00-1.188-1.519v-2.662h-14.827v2.625h-.205a1.521 1.521 0 00-1.519 1.524q0 .055.004.11 1.752 24.425-11.833 43.808a4.193 4.193 0 00-.76 2.541l1.622 50.151a4.341 4.341 0 004.305 4.19h33.537a4.343 4.343 0 004.307-4.264l.625-48.692a8.788 8.788 0 00-1.51-5.018z"
        fill="#e6e6e6"
      />
      <path
        d="M254.32 517.193H225.13a2.997 2.997 0 00-2.963 3.452l4.412 28.674h25.33l5.358-28.577a2.997 2.997 0 00-2.946-3.55z"
        fill="#fff"
      />
      <path
        d="M253.232 260.462h-83.688c5.85-31.633 4.505-69.831 0-110.911h83.688c-4.385 41.08-5.696 79.278 0 110.911z"
        fill="#e6e6e6"
      />
      <circle cx={211.892} cy={201.982} r={20.166} fill="#fff" />
      <path
        d="M176.602 407.672H92.914c5.851-31.633 4.505-69.832 0-110.911h83.688c-4.385 41.08-5.695 79.278 0 110.911z"
        fill="#e6e6e6"
      />
      <circle cx={135.262} cy={349.192} r={20.166} fill="#fff" />
      <path
        d="M300.621 267.52h-83.688c5.851-31.633 4.505-69.831 0-110.911h83.688c-4.385 41.08-5.695 79.278 0 110.911z"
        fill="#e6e6e6"
      />
      <circle cx={259.281} cy={209.04} r={20.166} fill="#fff" />
      <path
        d="M300.621 262.479h-83.688c5.851-31.633 4.505-69.832 0-110.911h83.688c-4.385 41.08-5.695 79.278 0 110.911z"
        fill="#e6e6e6"
      />
      <circle cx={259.281} cy={203.998} r={20.166} fill="#fff" />
      <path
        d="M300.621 267.52h-83.688c5.851-31.633 4.505-69.831 0-110.911h83.688c-4.385 41.08-5.695 79.278 0 110.911z"
        fill="#e6e6e6"
      />
      <circle cx={259.281} cy={209.04} r={20.166} fill="#fff" />
      <path
        fill="#e6e6e6"
        d="M45.525 254.413h274.254v30.249H45.525zM45.525 404.647h274.254v30.249H45.525zM45.525 554.882h274.254v30.249H45.525z"
      />
      <path
        d="M796.855 677.599l-68.325-48.326c23.043-22.447 44.002-54.41 64.046-90.55l68.324 48.325c-27.301 31.006-50.429 61.436-64.045 90.55z"
        fill={props?.tertiary || "#5987af" }
      />
      <circle cx={796.873} cy={605.982} r={20.166} fill="#fff" />
      <path
        d="M467.262 457.078h-7.5a.994.994 0 00-.994.995v3.292a.994.994 0 00.994.994h1.243v4.932h5.015v-4.932h1.242a.994.994 0 00.995-.994v-3.292a.994.994 0 00-.995-.995z"
        fill="#e6e6e6"
      />
      <path
        d="M474.774 487.206a38.558 38.558 0 01-6.708-22.86.813.813 0 00-.634-.812v-1.423h-7.921v1.403h-.11a.813.813 0 00-.811.814q0 .03.002.059a35.19 35.19 0 01-6.322 23.403 2.24 2.24 0 00-.405 1.357l.866 26.792a2.32 2.32 0 002.3 2.238h17.916a2.32 2.32 0 002.3-2.278l.334-26.012a4.695 4.695 0 00-.807-2.68z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        d="M465.699 458.15a2.145 2.145 0 01-4.29 0"
        opacity={0.2}
        style={{
          isolation: "isolate",
        }}
      />
      <path fill="#fff" d="M456.161 492.368h15.124v10.083h-15.124z" />
      <path
        d="M704.602 561.742h-14.039a1.861 1.861 0 00-1.861 1.861v6.162a1.861 1.861 0 001.861 1.862h2.327v9.233h9.386v-9.233h2.326a1.861 1.861 0 001.862-1.862v-6.162a1.861 1.861 0 00-1.862-1.861z"
        fill="#e6e6e6"
      />
      <path
        d="M718.664 618.139a72.178 72.178 0 01-12.557-42.794 1.522 1.522 0 00-1.188-1.519v-2.663h-14.827v2.626h-.204a1.521 1.521 0 00-1.52 1.524q0 .055.004.11 1.752 24.425-11.833 43.808a4.193 4.193 0 00-.759 2.54l1.621 50.152a4.341 4.341 0 004.305 4.19h33.537a4.343 4.343 0 004.307-4.264l.625-48.693a8.788 8.788 0 00-1.51-5.017z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        d="M701.676 563.75a4.016 4.016 0 01-8.032 0"
        opacity={0.2}
        style={{
          isolation: "isolate",
        }}
      />
      <path
        d="M713.355 634.797h-29.191a2.997 2.997 0 00-2.963 3.453l4.412 28.673h25.33l5.358-28.576a2.997 2.997 0 00-2.946-3.55z"
        fill="#fff"
      />
      <path
        d="M664.546 677.52h-83.688c5.851-31.633 4.505-69.832 0-110.912h83.688c-4.385 41.08-5.695 79.279 0 110.912z"
        fill={props?.tertiary || "#5987af" }
      />
      <circle cx={623.206} cy={619.039} r={20.166} fill="#fff" />
      <path
        fill="#ffb8b8"
        d="M322.243 328.466l18.787-1.708 11.956-29.034-21.349-11.956-9.394 42.698zM494.66 408.174a10.593 10.593 0 00-9.201-14.517l-29.17-1.739-4.295 14.282 30.029 8.247a10.593 10.593 0 0012.637-6.273z"
      />
      <path
        d="M463.339 414.687l-.321-26.8-64.793-10.338L348.31 320.3a16.338 16.338 0 00-21.295-2.912 16.338 16.338 0 00-2.846 24.922l41.18 50.678z"
        fill="#575a89"
      />
      <path
        d="M307.726 716.167h17.079l42.363-191.534 43.887 59.169 25.62 128.095H452.9l-3.416-137.489-57.216-146.882h-56.362c-15.402 27.875-27.484 56.609-13.663 86.25z"
        fill="#2f2e41"
      />
      <path
        d="M426.204 727.8a4.457 4.457 0 003.476 5.719l41.793 7.416a6.876 6.876 0 007.801-4.713 6.834 6.834 0 00-3.342-8.126 63.902 63.902 0 01-23.048-20.038c-4.594 4.277-9.728 3.875-15.18.723l-5.782.723zM299.818 727.8a4.457 4.457 0 003.475 5.719l41.793 7.416a6.876 6.876 0 007.801-4.713 6.834 6.834 0 00-3.342-8.126 63.902 63.902 0 01-23.048-20.038c-4.594 4.277-9.728 3.875-15.18.723l-5.782.723z"
        fill="#2f2e41"
      />
      <circle cx={353.413} cy={284.487} r={23.911} fill="#ffb8b8" />
      <path
        d="M332.064 433.931l62.339-1.708-18.308-57.62c11.378-18.239-.073-36.46-16.278-54.676l-12.81-8.54-20.494-1.708a50.314 50.314 0 00-13.55 35.05c.406 27.827 5.334 58.12 19.1 89.202z"
        fill="#575a89"
      />
      <path
        d="M475.291 472.716a10.593 10.593 0 00-2.749-16.966l-26.133-13.075-9.567 11.441 24.363 19.395a10.593 10.593 0 0014.086-.795z"
        fill="#ffb8b8"
      />
      <path
        d="M443.933 466.382l10.248-24.765-55.502-34.994-23.37-72.268a16.338 16.338 0 00-18.432-11.054 16.338 16.338 0 00-12.421 21.793l17.923 62.791z"
        fill="#575a89"
      />
      <path
        d="M314.13 275.093c0 9.304 6.042 23.713 13.664 29.89 5.128 4.155 11.904 3.98 14.517-1.709 2.68-5.835 11.92-19.552 28.181-28.18 21.678-11.503-7.688-31.182-28.18-28.181-15.4 2.254-28.182 12.617-28.182 28.18z"
        fill="#2f2e41"
      />
      <circle cx={328.648} cy={245.205} r={17.079} fill="#2f2e41" />
      <path
        d="M348.289 239.227a17.069 17.069 0 00-15.798-17.015c.424-.031.848-.064 1.28-.064a17.08 17.08 0 010 34.158c-.432 0-.856-.033-1.28-.064a17.069 17.069 0 0015.798-17.015z"
        fill="#2f2e41"
      />
      <ellipse cx={353.84} cy={287.903} rx={3.843} ry={5.124} fill="#ffb8b8" />
      <path
        fill="#3f3d56"
        d="M837.751 684.845H571.879l-56.301-266.151h-48.049v-11.942h57.729l56.301 266.15h256.192v11.943z"
      />
      <circle cx={610.841} cy={707.877} r={25.591} fill="#3f3d56" />
      <circle cx={801.923} cy={707.877} r={25.591} fill="#3f3d56" />
      <path
        d="M832.903 679.22H574.225l-45.641-225.55h347.151a12.265 12.265 0 0112.116 14.166l-42.833 201.025a12.204 12.204 0 01-12.115 10.36zm-255.869-3.411h255.87a8.806 8.806 0 008.742-7.478l42.833-201.025a8.85 8.85 0 00-8.744-10.225h-343.01z"
        fill="#3f3d56"
      />
      <path
        fill="#3f3d56"
        d="M624.513 678.499l-29.89-222.846 3.366-.556 29.89 222.846-3.366.556zM790.712 676.788l-3.369-.546 29.017-221.14 3.369.546-29.017 221.14zM708.115 455.375h3.412v222.846h-3.412z"
      />
      <path
        fill="#3f3d56"
        d="M546.005 518.5l.008-3.412 330.295.8-.008 3.412zM562.83 622.478L853 620.494l.024 3.412-290.171 1.984z"
      />
      <ellipse
        cx={472.648}
        cy={411.017}
        rx={18.767}
        ry={10.237}
        fill="#3f3d56"
      />
    </svg>
  )
}
