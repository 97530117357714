import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import BP from './config/default-blueprint.json';
import {GET_PRODUCTS, GET_BATCHES, GET_COUNTERFEITS, GET_TRANSACTIONS, GET_TRANS_STATS, GET_FEEDBACKS, GET_PRODUCT_AND_BATCHES, GET_ENROLMENT_STATS, GET_COUNTERFEITS_STATS, GET_CITY_COUNTRY_REGION } from './queries';
import { GET_QR_CODE, UPDATE_BATCH } from './mutation';

export const provOrganizationProfileDS = new DataSet({
    fieldId: 'id',
  });
  
export const provOrganizationProfileTransformedDS = new DataSet({
    fieldId: 'id',
  });
  provOrganizationProfileTransformedDS.update(BP?.dashboard?.organizationProfile);

export const provTransactionsDS = new DataSet({
  fieldId: 'id'
});
  
export const provTransactionsTransformedDS = new DataSet({
  fieldId: 'id'
});

provTransactionsTransformedDS.update(BP?.dashboard?.transactionList);

export const provEnrolmentStatsDS = new DataSet({
  fieldId:'id'
});

export const provEnrolmentStatsTransformedDS = new DataSet({
  fieldId:'id'
});

provEnrolmentStatsTransformedDS.update(BP?.dashboard?.feedbackStats);

export const provProductDS = new DataSet({
  fieldId:'id'
});

export const provProductTransformedDS = new DataSet({
  fieldId:'id'
});

provProductTransformedDS.update(BP?.dashboard?.productList);

export const provFeedbackDS = new DataSet({
  fieldId:'id'
});

export const provFeedbackTransformedDS = new DataSet({
  fieldId:'id'
});

provFeedbackTransformedDS.update(BP?.dashboard?.feedBackScreen);

export const provBatchesDS = new DataSet({
  fieldId:'id'
});

export const provBatchesTransformedDS = new DataSet({
  fieldId:'id'
});

provBatchesTransformedDS.update(BP?.dashboard?.batchList);

export const provCounterFeitDS = new DataSet({
  fieldId: 'id'
});

export const provCounterFeitTransformedDS = new DataSet({
  fieldId: 'id'
});

provCounterFeitTransformedDS.update(BP?.dashboard?.counterFeitData);

export const provTransStatsDS = new DataSet({
  fieldId: 'id'
});

export const provTransStatsTransformedDS = new DataSet({
  fieldId: 'id'
});

provTransStatsTransformedDS.update(BP?.dashboard?.transactionStats);

export const provCounterfeitStatsDS = new DataSet({
  fieldId: 'id'
});

export const provCounterfeitStatsTransformedDS = new DataSet({
  fieldId: 'id'
});

provCounterfeitStatsTransformedDS.update(BP?.dashboard?.counterfeitStats);

export const provProductsBatchesDS = new DataSet({
  fieldId: 'id'
});

export const provCityCountryRegionDS = new DataSet({
  fieldId: 'id'
});

  export const useProvDashboard = () => {
    useEffect(() => {
      return function cleanup() {
        provOrganizationProfileDS.clear();
        provEnrolmentStatsDS.clear();
        provTransactionsDS.clear();
      };
    }, []);

    const _getOrgProfile = () => {
        const _data = provOrganizationProfileTransformedDS.get(
          BP?.dashboard?.organizationProfile?.id,
        );
        let orgProfData;
        if (_data) {
          orgProfData = _data;
        }
        return orgProfData;
      };

    const _getTransactions = () => {
      const _data = provTransactionsTransformedDS.get(BP?.dashboard?.transactionList?.id);
      let transactionList;
      if(_data) {
        transactionList= _data?.list;
      }
      return transactionList;
    };

    const _getTransactionStats = () => {
      const _data = provTransStatsTransformedDS.get(BP?.dashboard?.transactionStats?.id);
      let transactionStats;
      if(_data) {
        transactionStats= _data?.statistics;
      }
      return transactionStats;
    };

    const _getCounterfeitStats = () => {
      const _data = provCounterfeitStatsTransformedDS.get(BP?.dashboard?.counterfeitStats?.id);
      let counterfeitStats;
      if(_data) {
        counterfeitStats = _data?.statistics;
      }
      return counterfeitStats;
    }

    const _getEnrolmentStats = () => {
      const _data = provEnrolmentStatsTransformedDS.get(BP?.dashboard?.feedbackStats?.id);
      let enrolmentStats;
      if(_data){
        enrolmentStats = _data?.statistics;
      }
      return enrolmentStats;
    };

    const _getProducts = () => {
      const _data = provProductTransformedDS.get(BP?.dashboard?.productList?.id);
      let products;
      if(_data){
        products = _data?.products;
      }
      return products;
    }

    const _getBatches = () => {
      const _data = provBatchesTransformedDS.get(BP?.dashboard?.batchList?.id);
      let batches;
      if(_data){
        batches = _data?.batches;
      }
      return batches;
    }

    const _getFeedbacks = () => {
      const _data = provFeedbackTransformedDS.get(BP?.dashboard?.feedBackScreen?.id);
      let feedbacks;
      if(_data) {
        feedbacks = _data?.userFeedback;
      }
      return feedbacks;
    }

    const _getCities =() =>{
      let _tempData = provCityCountryRegionDS.get()?.find(item=> {if(item?.city) return true});

      let _cities = Array.isArray(_tempData?.city) && _tempData?.city.map(item=>{
        return({
            key:item,
            label: item,
            value:item,
        });
      });
      return _cities;
    }

    const _getCountries = () => {
      let _tempData = provCityCountryRegionDS.get()?.find(item=> {if(item?.country) return true});

      let _country = Array.isArray(_tempData?.country) && _tempData?.country.map(item=>{
        return({
            key:item,
            label: item,
            value:item,
        });
      });
      return _country;
    }

    const _getRegions = () => {
      let _tempData = provCityCountryRegionDS.get()?.find(item=> {if(item?.region) return true});

      let _region = Array.isArray(_tempData?.region) && _tempData?.region.map(item=>{
        return({
            key:item,
            label: item,
            value:item,
        });
      });
      return _region;
    }

    const _getCounterFeits = () => {
      const _data = provCounterFeitTransformedDS.get(BP?.dashboard?.counterFeitData?.id);
      let counterfeit;
      if(_data) {
        counterfeit = _data?.counterFeits;
      }
      return counterfeit;
    }

    const batchPipeTransformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provBatchesDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provBatchesTransformedDS);
    pipe.all().start();
  };

  const transStatsPipeTransformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provTransStatsDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provTransStatsTransformedDS);
    pipe.all().start();
  };

  const counterfeitStatsPipeTransformation = () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provCounterfeitStatsDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provCounterfeitStatsTransformedDS);
    pipe.all().start();
  }

  const enrolStatsPipeTransformation= () => {
    //@ts-ignore
    const pipe = createNewDataPipeFrom(provEnrolmentStatsDS)
      //@ts-ignore
      .map((item: any) => ({
        ...item,
      }))
      //@ts-ignore
      .to(provEnrolmentStatsTransformedDS);
    pipe.all().start();
  };

    const [getProducts,
      {loading:getProductsLoading, data:getProductData, error: getProductsError}
      ] = useLazyQuery(GET_PRODUCTS, {fetchPolicy: `network-only`});

    if(getProductData && getProductData?.getSubscribedProducts){
      let _prodList = getProductData?.getSubscribedProducts?.ProductList?.prodList;
      provProductDS.update(_prodList);
    }

    const [getBatches,
    {loading: getBatchesLoading, data:getBatchesData, error: getBatchesError}
    ] = useLazyQuery(GET_BATCHES, {fetchPolicy: 'network-only'});

    if(getBatchesData && getBatchesData?.getBatchesWeb){
      let _batches = getBatchesData?.getBatchesWeb;
      provBatchesDS.update(_batches);
      batchPipeTransformation();
    }

    const [updateBatch,
    {loading:updateBatchLoading, data:updateBatchData, error:updateBatchError}
    ] = useMutation(UPDATE_BATCH);

    const [getQRCode,
    {loading:getQRLoading, data:getQRData, error:getQRError}
    ] = useMutation(GET_QR_CODE);


    const [getTransactions,
    {loading:transactionsLoading, data: transactionsList, error:transactionsError}
  ] = useLazyQuery(GET_TRANSACTIONS, {fetchPolicy: 'network-only'});

  if(transactionsList && transactionsList?.getScanLogs){
    provTransactionsDS.clear();
    let _data = transactionsList?.getScanLogs;
    provTransactionsDS.update(_data);
  }


    const [getCounterfeits,
    {loading: counterfeitLoading, data: counterfietData, error: counterfeitError}
    ] = useLazyQuery(GET_COUNTERFEITS, {fetchPolicy: 'network-only'});

    if(counterfietData){
      provCounterFeitDS.clear();
      let _data = counterfietData?.getScanLogs;
      provCounterFeitDS.update(_data);
    }

    const [getCounterfeitStats,
    {loading: counterfeitStatsLoading, data: counterfeitStatsData, error: counterfeitStatsError}
    ] = useLazyQuery(GET_COUNTERFEITS_STATS, {fetchPolicy: 'network-only'});

    if(counterfeitStatsData && counterfeitStatsData?.getScanLogStats){
      let _stats = counterfeitStatsData?.getScanLogStats;
      provCounterfeitStatsDS.update(_stats);
      counterfeitStatsPipeTransformation();
    }

    const [getTransactionStats, 
    {loading: transStatsLoading, data:transStatsData, error: transStatsError}
    ] = useLazyQuery(GET_TRANS_STATS, {fetchPolicy: 'network-only'});

    if(transStatsData && transStatsData?.getScanLogStats){
      let _stats = transStatsData?.getScanLogStats;
      provTransStatsDS.update(_stats);
      transStatsPipeTransformation();
    }

    const [getFeedback, 
    {loading: feedbackLoading, data:feedbackData, error: feedbackError}
    ] = useLazyQuery(GET_FEEDBACKS, {fetchPolicy:'network-only'});
    
    if(feedbackData && feedbackData?.getFeedbacks){
      provFeedbackDS.clear();
      let _feedbacks = feedbackData?.getFeedbacks
      provFeedbackDS.update(_feedbacks);
    }

    const [getProductAndBatches,
    {loading: productAndBatchesLoading, data: productAndBatchesData, error: productAndBatchesError}
    ] = useLazyQuery(GET_PRODUCT_AND_BATCHES, {fetchPolicy: 'network-only'});

    if(productAndBatchesData){
      let _productsBatches = productAndBatchesData?.getMyProductsAndBatches
      provProductsBatchesDS.update(_productsBatches);
    }

    const [getEnrolmentStats, 
    {loading: enrolStatsLoading, data: enrolStatsData, error: enrolStatsError}
    ] = useLazyQuery(GET_ENROLMENT_STATS, {fetchPolicy:'network-only'});

    if(enrolStatsData && enrolStatsData?.getFeedbackStats){
      let _stats = enrolStatsData?.getFeedbackStats;
      provEnrolmentStatsDS.update(_stats);
      enrolStatsPipeTransformation();
    }

    const [getCityCountryRegion, 
    {loading: getregionsLoading, data: getRegionsData, error: getRegionsError}
    ] = useLazyQuery(GET_CITY_COUNTRY_REGION, {fetchPolicy: 'network-only'});

    if(getRegionsData) {
      let data = getRegionsData?.getScanLogsRegionsList;
      provCityCountryRegionDS.update(data);
    }

      const orgProfData = _getOrgProfile();
      // const transactions = _getTransactions();
      const transactions = provTransactionsDS.get();
      const transactionStats= _getTransactionStats();
      // const enrolments = _getEnrolments();
      const enrolments = provFeedbackDS.get();
      const enrolmentStats = _getEnrolmentStats();
      // const products = _getProducts();
      const products = provProductDS.get();
      const batches = _getBatches();
      // const feedbacks = _getFeedbacks();
      const feedbacks = provFeedbackDS.get();
      // const counterFiets = _getCounterFeits();
      const counterFiets = provCounterFeitDS.get();
      const counterfeitStats= _getCounterfeitStats();
      const productsAndBatches = provProductsBatchesDS.get();
      const scanCities = _getCities();
      const scanCountries = _getCountries();
      const scanRegions = _getRegions();

      return {
          orgProfData,
          transactions,
          enrolments,
          enrolmentStats,
          products,
          batches,
          feedbacks,
          getProducts,
          getBatches,
          updateBatch,
          updateBatchData,
          updateBatchLoading,
          updateBatchError,
          getTransactions,
          transactionsLoading,
          transactionsError,
          getCounterfeits,
          counterfeitLoading,
          counterfeitError,
          counterFiets,
          getCounterfeitStats,
          counterfeitStats,
          getTransactionStats,          
          transactionStats,
          transStatsLoading,
          transStatsError,
          getFeedback,
          getEnrolmentStats,
          feedbackLoading,
          getProductAndBatches,
          productAndBatchesLoading,
          productAndBatchesError,
          productsAndBatches,
          getQRCode,
          getQRData,
          getQRLoading,
          getQRError,
          getCityCountryRegion,
          scanCities,
          scanRegions,
          scanCountries,
      }
  }