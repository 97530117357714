import React, {useContext} from 'react';
import {
  Image,
  View,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';

import {TooltipV} from 'react-tooltip-mui';
import {ImageV} from 'react-native-image-vfw';
import { TextV } from 'react-native-text-vfw';
import {EventBus} from 'event-bus-vfs';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import {GoogleMapV} from '../../../../widgets/GoogleMapV';
import {useViewport} from 'react-viewport-provider';
import Modal from 'rsuite/lib/Modal';
import {ThemeContext} from '../../../../providers/theme';
// import {ProvenanceMapModalSM} from '../index';
import {useProductView} from '../../provider';
import config from '../../../../config/default.config.json';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProvenanceMap;

interface ProvenanceMapProps {
  defaultCenter?: object;
  markers?: any[];
  googleMapAPIKey?:string
};

export const ProvenanceMapSM = ({
  defaultCenter,
  markers,
  googleMapAPIKey
}: ProvenanceMapProps) => {
         // const [show, setShow] = React.useState<boolean>(false)
         const {mapDetails} = useProductView();
  const _getMarkers = () => {
    let modifiedMarker = [];
    markers && markers.map((item, index) => {
      modifiedMarker.push(
        {
          id: index,
          image: {
            uri: item?.imageURL?.uri,
            size: 40,
            backgroundColor: "blue"
          },
          title: item?.title,
          description: "",
          lat: item?.latitude,
          lng: item?.longitude
        }
      );
    });
    return modifiedMarker;
  }
         const _markers = markers && _getMarkers();
         const _defaultCenter = defaultCenter;
         const _defaultZoom = BP.defaultZoom;
         const _data = BP.data;
         const [size, setSize] = React.useState(
           Array(_markers?.length).fill(1),
         );
         const [zoomCenter, setZoomCenter] = React.useState({
           center: _defaultCenter,
           zoom: _defaultZoom,
         });
         const history = useHistory();
         const theme = useContext(ThemeContext);
         const {width, height} = useViewport();
         const styles = {
           contentContainer: {
             flexDirection: 'column',
             marginTop: 30,
             justifyContent: 'center',
             alignItems: 'center',
             alignSelf: 'center',
             width: 1 * width,
             height: 196.8,
             backgroundColor: theme?.palette.ternary,
             paddingBottom: 30
           },
           tooltipIcon: {
             backgroundColor: 'white',
             flexDirection: 'column',
             alignItems: 'center',
             justifyContent: 'center',
             shadowOpacity: 0.75,
             shadowRadius: 10,
             shadowColor: 'rgba(0,0,0,0.2)',
             shadowOffset: {height: 0, width: 0},
           },
           thumbnailStyle: {
             width: 0.8 * width,
             height: 0.3 * height,
           },
         };

         const onChildClick = (key: number, childProps: any) => {};
         const onChildMouseEnter = (key: number, childProps: any) => {
           let _size = size;
           _size[key] = 1.8;
           setSize(_size);
         };
         const onChildMouseLeave = (key: number, childProps: any) => {
           let _size = size;
           _size[key] = 1;
           setSize(_size);
         };
         const onBoundsChange = (
           center: number[],
           zoom: number,
           bounds: number[],
           marginBounds: number[],
         ) => {};

         // const handleModalHide = () => {
         //   setShow(false);
         // }

         const handleTouchEnd = () => {
           // setShow(true);
          //  handleMarkerClick(BP);
          const data = {
            markers: _markers,
            defaultCenter: _defaultCenter,
            defaultZoom: _defaultZoom
          }
          history.push('/map', data)
         };

         const renderItem = (tooltip, index) => {
           //Currently supports only react icons/images, not react-native icons/images
           return (
             <View
               style={StyleSheet.flatten([
                 styles.tooltipIcon,
                 {
                   backgroundColor: 'transparent',
                   width: size[tooltip?.id] * (tooltip?.image?.size + 8),
                   height: size[tooltip?.id] * (tooltip?.image?.size + 8),
                   borderRadius: tooltip?.image?.size
                     ? (size[tooltip?.id] * (tooltip?.image?.size + 8)) / 2
                     : 0,
                 },
               ])}
               
             >
               <Image
                 key={index}
                 source={{uri: tooltip?.image?.uri}}
                 style={{
                   resizeMode: 'contain',
                   backgroundColor: 'transparent',
                   width: size[tooltip?.id] * tooltip?.image?.size,
                   height: size[tooltip?.id] * tooltip?.image?.size,
                   borderRadius: tooltip?.image?.size
                     ? (size[tooltip?.id] * tooltip?.image?.size) / 2
                     : 0,
                 }}
               />
             </View>
           );
         };

         const renderMarker = (marker: object | any, index: number) => {
           return (
             <TooltipV
               key={index}
               title={marker?.title}
               tooltipStyle={{
                 maxWidth: 200,
                 fontSize: 20,
                 backgroundColor: theme?.palette.primary,
               }}
               arrowStyle={{color: theme?.palette.primary}}
               children={[marker]}
               renderItem={renderItem}
               placement={'top'}
               arrow
             />
           );
         };

         return (
           <View style={styles.contentContainer} onClick={handleTouchEnd}>
             {
               googleMapAPIKey ?
               <GoogleMapV
               width={0.8 * width}
               height={197}
               bootstrapURLKeys={{key: googleMapAPIKey}}
               defaultCenter={defaultCenter && defaultCenter}
               defaultZoom={BP.defaultZoom}
               center={zoomCenter?.center}
               zoom={zoomCenter?.zoom}
               markers={_markers && _markers}
               renderMarker={renderMarker}
               onChildClick={onChildClick}
               onChildMouseEnter={onChildMouseEnter}
               onChildMouseLeave={onChildMouseLeave}
               onBoundsChange={onBoundsChange}
             />:
             <TextV type={'title1'} style={{ fontSize: 16, marginLeft: 10 }}>
                  {"map not found!"}
            </TextV>
             }
             
           </View>
         );
       };
