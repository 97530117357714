import React, { useContext, useLayoutEffect } from 'react';
import { StyleSheet } from 'react-native';
import {useViewport} from 'react-viewport-provider';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../../../../../providers/theme';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialTemplateV } from 'react-credentail-template';

import {IProps} from '../../index';

const BP = config?.batchProducts;

export const BatchProducts = (props: IProps) => {
    const theme = useContext(ThemeContext);
    const { width, height } = useViewport();
    // const [data, setData] = React.useState<any>([]);
    const [userActionKey, setUserActionKey] = React.useState('');
    // const [drawer, setDrawer] = React.useState<any>({
    //   placement: 'right',
    //   title: '',
    //   size: 'sm',
    //   backdrop: false,
    //   show: false,
    // });

  
    const styles = {
      leftcontainerStyle: {
        display: 'flex',
      },
      rightContainerStyle: {
        float: 'right',
        padding: 10,
      },
      containerStyle: {
        backgroundColor: '#f7f7fa',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 'none',
        height: height - (height * 0.16),
        padding: 20,
      },
    };
  
    // const renderDrawerBody = () => {
    //   if (userActionKey === 'adduser') {
    //     return <div />;
    //   }
    //   return <div />;
    // };
  
    // const handleOnDrawerHide = () => {
    //   let _drawer = { ...drawer };
    //   _drawer.show = false;
    //   setDrawer({
    //     ..._drawer,
    //   });
    // };
  
    // const renderDrawerHeader = () => {
    //   return <div />;
    // };
  
    // const renderDrawerFooter = () => {
    //   return <div />;
    // };
  
    const onNavItemClick = (eventKey: string) => {
      setUserActionKey(eventKey);
      // if (eventKey === 'newIssuer') {
      //   let _drawer = { ...drawer };
      //   _drawer.title = humanizeString(eventKey);
      //   _drawer.placement = BP?.drawerPlacement;
      //   _drawer.show = true;
      //   setDrawer({
      //     ..._drawer,
      //   });
      // }
    };
  
    const bodyContent = () => {
      let _prodlist = Array.isArray(props?.products) && props?.products.map(item=>{
        return({
          ...item,
          key:item?.gtin,
          subtitle1:item?.description,
          icon: {
            imageURI: item?.image?.uri ? item?.image?.uri : `https://ui-avatars.com/api/?name=${item.title}`,
            imgWidth: 40,
            imgHeight: 40
          }
        })
      })
        return (
          <CredentialTemplateV
            templateDetails={_prodlist}
            onCardClick={eventKey => props.onCardClick(eventKey)}
            containerStyle={StyleSheet.flatten([styles.containerStyle])}
          />
        );      
    }

    // const hederLeftData = () => {
    //   return (
    //     <div style={StyleSheet.flatten([styles.leftcontainerStyle])}>
    //       <Whisper
    //         placement="top"
    //         trigger="hover"
    //         speaker={<Tooltip>Search </Tooltip>}
    //         onClick={props.onSearchClick}
    //       >
    //         <IconButtonV icon={{ name: 'search' }} circle />
    //       </Whisper>
    //     </div>
    //   );
    // }

    return (
      <>
        <BodyTemplateV
          primaryColor={BP?.primaryColor || theme?.palette.primary}
          primaryHighlightColor={
            BP?.primaryHighlightColor || theme?.palette.secondary
          }
          secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
          ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
          renderContent={bodyContent}
          dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
          contentScrollableInline={true}
          headerTitleStyle={{ marginLeft: 20 }}
          navbarStyle={{}}
          layout={'sidebar-header-content-footer'}
          navBarRightItems={BP?.navRightItems}
        //   navBarLeftItems={BP?.navLeftItems}
          onNavItemSelect={onNavItemClick}
          headerBackgroundColor={'#fff'}
          renderFooter={() => <div />}
        />
      </>
    );
}