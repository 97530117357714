import React from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import { ButtonV, ButtonType } from 'react-native-button-vfw';
import { useHistory } from 'react-router-dom';
import { IconV } from 'react-native-icon-vfw';
import Rating from '@material-ui/lab/Rating';
import { TextV } from 'react-native-text-vfw';
import { Alert } from 'rsuite';
import moment from 'moment';
import { ThemeContext } from '../../../../providers/theme';
import { FORM_VALUES, defaultValuesDS, SENDER_ID, FormV } from 'react-form-rs';
import { useProductView } from '../../../ProductView/provider';
import { FeedbackProductCard } from '../index';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.EnterContest;

interface EnterContest {
    onContestSubmit?(formData: any): void;
    privacyClick?(): void;
    termsClick?(): void;
};
let formContext = {}

export const EnterContestSM = (props: EnterContest) => {
    const parentRef = React.useRef(() => { });
    const [validateForm, setValidateForm] = React.useState<boolean>(false);
    const history = useHistory();
    const theme = React.useContext(ThemeContext);
    const { feedbackData } = useProductView();
    const [rate, setRate] = React.useState(4);
    const ratingText = [
        "Poor", "Fair", "Good", "Very Good", "Excellent!"
    ];
    const title = feedbackData?.prodTitle;
    const subTitle = feedbackData?.prodSubTitle
    const subTitle1 = feedbackData?.prodPrice
    const imageUri = feedbackData?.prodImage

    const styles = {
        formContainer: {
            width: '100%',
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            // overflow: 'scroll',
        },
        buttonTitleStyle: {
            color: '#ffffff',
        },
        buttonContainerStyle: {
            width: 120,
            alignSelf: 'center',
            marginTop: 20,
            marginBottom: 60,
        },
        submitButtonStyle: {
            backgroundColor: theme?.palette.secondary,
            borderRadius: 10,
        },
        termCondistionStyles: {
            padding: 20,
        }
    }

    const _handleOnValidateResult = (
        formContext?: any,
        status?: 'success' | 'error',
        validateResult: any,
    ) => {
        let formData = {};
        formData.name = defaultValuesDS.get('fullName')?.defaultValue;
        formData.email = defaultValuesDS.get('emailAddress')?.defaultValue ?? null;
        formData.telephone =
            defaultValuesDS.get('contactNumber')?.defaultValue ?? null;
        formData.rating = rate;
        formData.comments = defaultValuesDS.get('feedback')?.defaultValue ?? null;
        const dob = defaultValuesDS.get('selectDOB')?.defaultValue ?? null;
        formData.birthDate = new Date(dob).toISOString();
        const currentTime = new Date(new Date()).toISOString();
        const years = moment(currentTime, 'YYYY-MM-DD[T]HH:mm:ss. SSS[Z]').diff(moment(dob, 'YYYY-MM-DD[T]HH:mm:ss. SSS[Z]'), 'years');
        const anniversaryDate = defaultValuesDS.get('selectAnniversaryDate')?.defaultValue ?? null;
        formData.anniversaryDate = new Date(anniversaryDate).toISOString();
        
        if (years >= 18) {
        if (status === 'success') {
            props.onContestSubmit && props.onContestSubmit(formData)
            defaultValuesDS.clear();
            setValidateForm(false)
        }
        if (status === 'error') {
            setValidateForm(false)
        }
        } else {
            Alert.error(`Age should be 18 years or above`); 
            setValidateForm(false)
        }
    }

    const onSubmitPress = () => {
        setValidateForm(true);
        // history.push('/', formData);
    }

    const handleBackPress = () => {
        window.history.go(-1);
        return false;
    }

    return (
        <ScrollView>
            <FeedbackProductCard
                cardBgColor={theme?.palette?.ternary}
                imageUri={imageUri}
                title={title}
                subTitle={subTitle}
                subTitle1={subTitle1}
            />
            <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                <Rating
                    name="simple-controlled"
                    value={rate}
                    onChange={(event: object, value: number) => {
                        setRate(value)
                    }}
                />
                {<TextV type={'body'}>{ratingText[rate - 1]}</TextV>}
            </View>
            <FormV
                components={BP?.formFields}
                formContext={{}}
                containerStyle={styles.formContainer}
                validateForm={validateForm}
                onValidateResult={_handleOnValidateResult}
                defaultValues={defaultValuesDS.get()}
                ref={parentRef}
            />
            <View
                style={StyleSheet.flatten([styles.termCondistionStyles])}
            >
                <TextV
                    type={'footnote'}
                >
                    By Clicking Submit, You agree to our <a onClick={props.privacyClick} target="_blank">Privacy Policy</a> & <a onClick={props.termsClick} target="_blank">Terms of Service</a>
                </TextV>
            </View>
            <View style={{ width: '100%' }}>
                <ButtonV
                    // {...props}
                    title={'Submit'}
                    titleStyle={StyleSheet.flatten([styles.buttonTitleStyle])}
                    type={ButtonType.Solid}
                    buttonStyle={StyleSheet.flatten([styles.submitButtonStyle])}
                    containerStyle={StyleSheet.flatten([styles.buttonContainerStyle])}
                    onPress={onSubmitPress}
                />
            </View>
        </ScrollView>
    );
}