//@ts-nocheck
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useViewport, ViewportProvider} from 'react-viewport-provider';
import {DataSet} from 'vis-data';
import {ReactFormGenerator} from './core';
import './core/dist/app.css';

const ID = require('./uuid');

const formgeneratorDS = new DataSet({
  fieldId: 'field_name',
});

export interface IFormGeneratorVProps {
  containerStyle?: any;
  form_action: string; //URL path to submit the form
  form_method: string; //Verb used in the form submission.
  data: any[]; //Question data retrieved from the database
  action_name?: string //Defines form submit button text. Defaults to "Submit"
  onSubmit?(data: any): void; //Invoke when submit data, if exists will override form post
  back_action?: string; //URL path to go back if needed
  back_name?: string; //Button text for back action. Defaults to "Cancel"
  task_id?: number;//User to submit a hidden variable with id to the form on the backend database
  answer_data?: any[]; //Answer data, only used if loading a pre-existing form with values
  authenticity_token?: string; //If using Rails and need an auth token to submit form
  hide_actions?: boolean; //If you would like to hide the submit / cancel buttons set to true
  skip_validations?: boolean; //Suppress form validations on submit, if set to true
  display_short?: boolean; //Display an optional "shorter page/form" which is common for legal documents or situations where the user will just have to sign or fill out a shorter form with only the critical elements
  read_only?: boolean //Shows a read only version which has fields disabled and removes "required" labels
  variables?: any //Key/value object that can be used for Signature variable replacement
  submitButtonContainerStyle?: any;
  backButtonContainerStyle?: any;
  submitButtonTextStyle?: any;
  backButtonTextStyle?: any;
  validateResult?: boolean;
}

const FormGeneratorVInternal = (props: IFormGeneratorVProps) => {
  const {width, height} = useViewport();
  const answer_data: any = props.answer_data ?? [];
  // if (!props.answer_data && props.data && Array.isArray(props.data)) {
  //   props.data.forEach(x => {
  //     if (x.value && x.field_name) {
  //       answer_data.push({
  //         name: x.field_name,
  //         value: x.value
  //       })
  //     }
  //   })
  // }
  React.useEffect(() => {
    formgeneratorDS.update(props.data);
  }, [props.data])

  const styles = StyleSheet.create({
    containerStyle: {
      width: 0.6 * width,
      flexDirection: 'column',
      paddingTop: 0.1 * height,
    }
  })

  const handleSubmit = (data: any) => {
    if (data && Array.isArray(data) && data.length > 0) {
      data.forEach(filledData => {
        try {
          formgeneratorDS.updateOnly({
            field_name: filledData.name,
            value: filledData.value
          });
        }
        catch (err) {

        }
      })
    }
    if (props.onSubmit) {
      props.onSubmit(data);
    }
  }

  return (
    <View style={StyleSheet.flatten([styles.containerStyle, props.containerStyle])}>
      <ReactFormGenerator
        {...props}
        answer_data={answer_data}
        onSubmit={handleSubmit}
      />
    </View>
  )
}
export const FormGeneratorV = (props: IFormGeneratorVProps) => {
  return (
    <ViewportProvider>
      <FormGeneratorVInternal {...props} />
    </ViewportProvider>
  )
}
