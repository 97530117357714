import React from 'react';
import { View, StyleSheet, Image, ImageSourcePropType, TouchableOpacity } from 'react-native';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { TextV } from 'react-native-text-vfw';
import { titleCase } from 'title-case';
import { IconV } from 'react-native-icon-vfw';
import { useViewport } from 'react-viewport-provider';
import { ImageBackgroundV } from 'react-image-background';
import { AvatarSize, AvatarV } from 'react-native-avatar-vfw';
import {useHistory, useLocation} from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { ThemeContext } from '../../../../providers/theme';
import { SvgIconV } from '../../../../widgets/SvgIconV';
import { Carousel } from '../Carousel';
import { GoogleMapV } from '../../../../widgets/GoogleMapV';
import config from '../../../../config/default.config.json';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProductInfoScreen;

const RIGHT_AVAVTAR_SIZE = 50;

interface ProductInfoScreenProps {
  isListing?: boolean;
  isFetchingFeatures?: object;
  listFeatures?: any[];
  wallpaperURL?: ImageSourcePropType;
  googleMapAPIKey?:string;
};

export const ProductInfoScreenSM = ({
  wallpaperURL, 
  googleMapAPIKey
}: ProductInfoScreenProps) => {
  const { height, width } = useViewport();
  const theme = React.useContext(ThemeContext);
  const history = useHistory();
  const location = useLocation();
  const post = location.state;
  const styles = {
    // imageStyle: {
    //   width: 0.9 * width,
    //   height: 0.5 * height,
    //   borderRadius: 10,
    //   alignSelf: 'center',
    //   elevation: 10,
    //   backgroundColor: '#fff',
    //   marginVertical: 5,
    // },
    // titleStyle: {
    //   fontWeight: 'bold',
    //   color: theme?.palette?.primary,
    //   letterSpacing: 1,
    // },
    // twoColStyle: {
    //   flexDirection: 'row',
    // },
    // subtextStyle: {
    //   fontSize: 14,
    //   color: 'gray',
    // },
    // textContainer: {
    //   margin: 20,
    // },
    rowTitle: {
      flexDirection: 'row',
      width,
      paddingHorizontal: 15,
      marginTop: 0,
      minHeight: 50,
      paddingBottom: 30
    },
    rightAvatarStyle: {
      position: 'absolute',
      right: 10,
      top: 0,
      marginRight: 15,
    },
    postTitle: {
      color: '#373a3b',
      fontWeight: 500,
      fontSize: 20,
      textAlign: 'left',
      backgroundColor: 'transparent',
      zIndex: 9999,
      width: '70%',
    },
    subTitle: {
      color: theme?.palette?.secondary,
      marginRight: 16,
      marginBottom: 14,
      marginLeft: 15,
      fontSize: 15,
      marginTop: 10,
      textAlign: 'left',
      backgroundColor: 'transparent',
      zIndex: 9999,
    },
    subTitle2: {
      color: theme?.palette?.secondary,
      marginRight: 16,
      marginBottom: 14,
      marginLeft: 15,
      fontSize: 15,
      textAlign: 'left',
      backgroundColor: 'transparent',
      zIndex: 9999,
    },
    content: {
      textAlign: 'justify',
      fontSize: 14,
    },
    boxContent: {
      backgroundColor: '#F5F5F5',
      padding: 6,
    },
    boxInfo: {
      paddingTop: 15,
      marginBottom: 10,
    },
    tableInfo: {
      paddingTop: 10,
      backgroundColor: '#F5F5F5',
    },
    row: {
      paddingLeft: 5,
      alignItems: 'center',
      justifyContent: 'flex-start',
      zIndex: 9999,
      marginHorizontal: 5,
      flexDirection: 'row',
    },
    label: {
      color: '#000',
      width: '30%',
      paddingLeft: 10,
      fontSize: 14,
      lineHeight: 30,
      alignSelf: 'center',
    },
    tableLabel: {
      color: '#000',
      width: 200,
      paddingLeft: 12,
      paddingTop: 4,
      fontSize: 12,
      lineHeight: 30,
    },
    text: {
      color: theme?.palette?.secondary,
      fontSize: 14,
      width: '60%',
      lineHeight: 30,
      alignSelf: 'center',
    },
    rightText: {
      color: '#000',
      fontSize: 14,
      paddingTop: 5,
      // width: width / 2,
      lineHeight: 30,
      alignSelf: 'flex-start',
      marginRight: 25,
    },
    rightContainer: {
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexDirection: 'row',
    },
    headerTop: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
  };

  const _openBox = (element: any) => {
    if (element) {
      switch (element.index) {
        case 0 /* location */:
          const { latitude, longitude } = element.location;
          const url = `http://maps.apple.com/?ll=${latitude},${longitude}`;
          const urlGG = `https://google.com/maps/place/${latitude},${longitude}`;
          // WebBrowser.openBrowserAsync(Platform.OS == 'ios' ? url : urlGG);
          window.open(urlGG);
          break;
        case 1 /* Phone */:
          // const phone = element.value;
          // const phoneOpen =
          //   Platform.OS == 'ios' ? `telprompt:${phone}` : `tel:${phone}`;
          // Linking.canOpenURL(phoneOpen)
          //   .then(supported => {
          //     if (supported) {
          //       return Linking.openURL(phoneOpen).catch(err => {}
          //       );
          //     }
          //   })
          //   .catch(err => {});
          return null;
          break;
        case 2 /* Twitter */:
          const twitter = element.value;
          // Linking.openURL(`https://twitter.com/${twitter}`);
          window.open(`https://twitter.com/${twitter}`);
          break;
        // case 3 /* Facebook */:
        //   break;
        default:
          const link = element.url || element.value;
          if (link) {
            let website;
            if (link.includes('http://') || link.includes('https://')) {
              website = link;
            } else {
              return;
            }
            // WebBrowser.openBrowserAsync(website).catch(err => {});
            window.open(website)
          }
          return;
      }
    }
  }


  const _renderListingContent = () => {
    const postTitle = post && post?.title 
    const subTitle = post && post?.subTitle
    const subTitle2 = post && post?.subTitle2
    const boxInfo = post && post?.boxInfo
    // const tableInfo = post ? post.tableInfo ? post.tableInfo : '' : '';
    const content = post && post?.content
    const source = post && post?.organizationLogo
    return (
      <View>
        {/* <ImageBackgroundV image={wallpaperURL ?? ''} > */}
        <View style={StyleSheet.flatten([styles.rowTitle])}>
          {postTitle && (
            <TextV
              type={'title3'}
              numberOfLines={1}
              ellipsizeMode={'tail'}
              style={StyleSheet.flatten([styles.postTitle])}
            >
              {postTitle}
            </TextV>
          )}
          {source && (
            <AvatarV
              rounded
              title={postTitle && postTitle[0]}
              source={source}
              size={RIGHT_AVAVTAR_SIZE}
              containerStyle={StyleSheet.flatten([styles.rightAvatarStyle])}
            />
          )}
        </View>

        {subTitle && (
          <TextV
            type={'headline'}
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={StyleSheet.flatten([styles.subTitle])}
          >
            {subTitle}
          </TextV>
        )}
        {subTitle2 && (
          <TextV
            type={'headline'}
            numberOfLines={1}
            ellipsizeMode={'tail'}
            style={StyleSheet.flatten([styles.subTitle2])}
          >
            {subTitle2}
          </TextV>
        )}
        <View style={StyleSheet.flatten([styles.boxContent])}>
          <TextV
            type={'footnote'}
            style={StyleSheet.flatten([styles.content])}
          >
            {content}
          </TextV>
        </View>
        {/* <View
              style={StyleSheet.flatten([
                classes.tableInfo
              ])}
            >
              {tableInfo &&
                tableInfo.map((element: any, index: number) => {
                  return (
                    <React.Fragment>
                      <TouchableOpacity
                        key={`box-info-${index}`}
                        style={classes.row}
                      >
                        {element.path && (
                          <SvgIconV
                            color={'#05a9ef'}
                            path={element.path}
                            width={element.width}
                            height={element.height}
                          />
                        )}
                        {element.label && (
                          <TextV
                            type={'headline'}
                            ellipsizeMode={'tail'}
                            numberOfLines={1}
                            style={{...classes.tableLabel}}
                          > */}
        {/* {Languages[element.label] || element.label} */}
        {/* {element.label}
                          </TextV>
                        )}
                        {element.value && (
                          <View style={{...classes.rightContainer}}>
                            <TextV
                              type={'headline'}
                              numberOfLines={1}
                              ellipsizeMode={'tail'}
                              style={{...classes.rightText}}
                            >
                              {element.value}
                            </TextV>
                          </View>
                        )}
                      </TouchableOpacity> */}
        {/* <View
                                        style={{ borderBottomColor: styles && styles.themeSolidColor && styles.themeSolidColor.color,
                                                 borderBottomWidth: 0.3,
                                                 marginLeft: 12,
                                                 marginRight: 15,
                                            }}
                                    /> */}
        {/* </React.Fragment>
                  );
                })}
            </View> */}
        <View style={StyleSheet.flatten([styles.boxInfo])}>
          {boxInfo &&
            boxInfo.map((element, index) => {
              return (
                <TouchableOpacity
                  key={`box-info-${index}`}
                  onPress={() => {
                    _openBox(element);
                  }}
                >
                  <View style={StyleSheet.flatten([styles.row])}>
                    {element.path && (
                      <SvgIconV
                        color={theme?.palette?.secondary}
                        path={element?.path}
                        width={element?.width}
                        height={element?.height}
                      />
                    )}
                    {element?.label && (
                      <TextV
                        type={'headline'}
                        numberOfLines={1}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.flatten([styles.label])}
                      >
                        {/* {Languages[element.label] || element.label} */}
                        {element.label}
                      </TextV>
                    )}
                    {element?.value && (
                      <TextV
                        type={'headline'}
                        numberOfLines={2}
                        ellipsizeMode={'tail'}
                        style={StyleSheet.flatten([styles.text])}
                      >
                        {element.value}
                      </TextV>
                    )}
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
        {/* </ImageBackgroundV> */}
      </View>
    );
  }

  const _renderMap = () => {
    const _markers = post ? (post.latitude && post.longitude) ? [ {lat: post.latitude, lng: post.longitude} ] : [] : [ {lat: BP.post.latitude, lng: BP.post.longitude} ]
    const zoomCenter = {
      center: _markers,
      zoom: 6
    }
    return (
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: googleMapAPIKey,
          }}
          defaultCenter={{ lat: post.latitude, lng: post.longitude }}
          defaultZoom={4}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={
            ({ map, maps }) => {
              if (
                Array.isArray(_markers) &&
                _markers.length > 0
              ) {
                _markers.forEach((location, index) => {
                  let marker = new maps.Marker({
                    position: location,
                    map,
                    titile: post.title,
                  });
                });
              }
            }
            // renderMarker(map, maps, element.markers)
          }
        ></GoogleMapReact>
      </div>
    );
  }

  const handleBackPress = () => {
    window.history.go(-1);
  }

  return (
    <>
      <TouchableOpacity hitSlop={{ top: 10, bottom: 10, right: 0, left: 10 }}>
      <IconV
        name={'closecircle'}
        type={'antdesign'}
        onPress={handleBackPress}
        size={26}
        color={'#000'}
        containerStyle={{
          alignSelf: 'flex-end',
          marginTop: 20,
          marginRight: 20,
        }}
      />
      </TouchableOpacity>
      <Carousel images={post && post?.galleryImages} />
      {_renderListingContent()}
      {_renderMap()}
    </>
  );

  //  return (
  //    <View style={{height: height, margin: 20}}>
  //      <IconV
  //        name={'closecircle'}
  //        type={'antdesign'}
  //        onPress={handleBackPress}
  //        size={26}
  //        color={'#000'}
  //        containerStyle={{
  //          alignSelf: 'flex-end',
  //          paddingHorizontal: 5,
  //          marginBottom: 10
  //        }}
  //      />
  //    <FBGridV>
  //      <FBItemV colspan={24}>
  //        <View style={{alignItems: 'center'}}>
  //          <Image
  //            source={{uri: productData.imageUrl}}
  //            resizeMode="cover"
  //            style={StyleSheet.flatten([styles.imageStyle])}
  //          />
  //        </View>
  //      </FBItemV>
  //      <FBItemV colspan={24}>
  //        <View style={StyleSheet.flatten([styles.textContainer])}>
  //          <TextV
  //            type={'title3'}
  //            children={
  //              typeof productData.title === 'string' &&
  //              titleCase(productData.title)
  //            }
  //            style={StyleSheet.flatten([styles.titleStyle])}
  //            ellipsizeMode={'tail'}
  //            numberOfLines={1}
  //          />
  //          <View style={StyleSheet.flatten([styles.twoColStyle])}>
  //            <IconV
  //              name={'location-on'}
  //              type={'material'}
  //              size={30}
  //              color={'gray'}
  //              containerStyle={{alignSelf: 'center', padding: 5}}
  //            />
  //            <TextV
  //              type={'title3'}
  //              children={
  //                typeof productData.location === 'string' &&
  //                titleCase(productData.location)
  //              }
  //              style={StyleSheet.flatten([
  //                styles.subtextStyle,
  //                {paddingTop: 10},
  //              ])}
  //              ellipsizeMode={'tail'}
  //              numberOfLines={1}
  //            />
  //          </View>
  //          <View style={StyleSheet.flatten([styles.twoColStyle])}>
  //            <IconV
  //              name={'calendar'}
  //              type={'octicon'}
  //              size={30}
  //              color={'gray'}
  //              containerStyle={{
  //                alignSelf: 'center',
  //                paddingHorizontal: 5,
  //              }}
  //            />
  //            <TextV
  //              type={'title3'}
  //              children={
  //                typeof productData.timelineDate === ('string' || Date) &&
  //                titleCase(productData.timelineDate)
  //              }
  //              style={StyleSheet.flatten([styles.subtextStyle])}
  //              ellipsizeMode={'tail'}
  //              numberOfLines={1}
  //            />
  //          </View>
  //        </View>
  //      </FBItemV>
  //    </FBGridV>
  //    </View>
  //  );
};