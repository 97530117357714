import React, { useContext } from 'react';
import {Image, View, StyleSheet, TouchableOpacity, ScrollView} from 'react-native';
import { TextV } from 'react-native-text-vfw';
import {TooltipV} from 'react-tooltip-mui';
import { ImageV } from 'react-native-image-vfw';
import {GoogleMapV} from '../../../../widgets/GoogleMapV';
import {useViewport} from 'react-viewport-provider';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import {ThemeContext} from '../../../../providers/theme';
import config from '../../../../config/default.config.json';
import { useProductView } from '../../provider';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProvenanceMap;

interface ProvenanceMapProps {
  defaultCenter?: object;
  markers?: any[];
  handleMarkerClick?(item: any): void;
  mapDetails?: object;
  googleMapAPIKey?:string;
};


export const ProvenanceMapLG = ({ handleMarkerClick, defaultCenter, markers, mapDetails, googleMapAPIKey }: ProvenanceMapProps) => {
  const _getMarkers = () => {
    let modifiedMarker = [];
    markers && markers.map((item, index) => {
      modifiedMarker.push(
        {
          id: index,
          image: {
            uri: item?.imageURL?.uri,
            size: 40,
            backgroundColor: "blue"
          },
          title: item?.title,
          description: "",
          lat: item?.latitude,
          lng: item?.longitude
        }
      );
    });
    return modifiedMarker;
  };
  const _getData = () => {
    let data = [];
    mapDetails && mapDetails?.listMarkers && mapDetails?.listMarkers.map((item, index) => {
      data.push(
        {
          image: {
            uri: item?.imageURL?.uri
          },
          title: item?.title,
          lat: item?.latitude,
          lng: item?.longitude
        }
      );
    });
    return data;
  }
    const _markers = markers && _getMarkers();;
    const _defaultCenter = defaultCenter;
    const _defaultZoom = BP.defaultZoom;
    const _data = mapDetails && _getData();
    const [size, setSize] = React.useState(Array(_markers?.length).fill(1));
    const [zoomCenter, setZoomCenter] = React.useState({
        center: _defaultCenter,
        zoom: _defaultZoom,
    });
    const theme = useContext(ThemeContext);
    const {width, height} = useViewport();
    const styles = {
      contentContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        borderRadius: 20,
        // height: 1.1 * height,
        backgroundColor: theme?.palette.ternary,
      },
      tooltipIcon: {
        backgroundColor: 'white',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        shadowOpacity: 0.75,
        shadowRadius: 10,
        shadowColor: 'rgba(0,0,0,0.2)',
        shadowOffset: {height: 0, width: 0},
      },
      thumbnailStyle: {
        // width: 0.2*width,
        height: 0.8*height,
        overflow: 'auto'
      }
    };
    const onChildClick = (key: number, childProps: any) => {};
    const onChildMouseEnter = (key: number, childProps: any) => {
      let _size = size;
      _size[key] = 1.8;
      setSize(_size);
    };
    const onChildMouseLeave = (key: number, childProps: any) => {
      let _size = size;
      _size[key] = 1;
      setSize(_size);
    };
    const onBoundsChange = (
      center: number[],
      zoom: number,
      bounds: number[],
      marginBounds: number[],
    ) => {};

  const handleTouchEnd = () => {
    // setShow(true);
    const data = {
      markers: _markers,
      defaultCenter: _defaultCenter,
      defaultZoom: _defaultZoom
    }
    handleMarkerClick&& handleMarkerClick(data);
  };

    const renderItem = (tooltip, index) => {
      //Currently supports only react icons/images, not react-native icons/images
      return (
        <View
          style={
              StyleSheet.flatten([styles.tooltipIcon, {
                    width: size[tooltip?.id] * (tooltip?.image?.size + 8),
                    height: size[tooltip?.id] * (tooltip?.image?.size + 8),
                    borderRadius: tooltip?.image?.size
                    ? (size[tooltip?.id] * (tooltip?.image?.size + 8)) / 2
                    : 0,
            }])}
          onClick={handleTouchEnd}
        >
          <Image
            key={index}
            source={{uri: tooltip?.image?.uri}}
            style={{
              resizeMode: 'contain',
              backgroundColor: 'transparent',
              width: size[tooltip?.id] * tooltip?.image?.size,
              height: size[tooltip?.id] * tooltip?.image?.size,
              borderRadius: tooltip?.image?.size
                ? (size[tooltip?.id] * tooltip?.image?.size) / 2
                : 0,
            }}
          />
        </View>
      );
    };

    const renderMarker = (marker: object | any, index: number) => {
      return (
        <TooltipV
          key={index}
          title={marker?.title}
          tooltipStyle={{
            maxWidth: 200,
            fontSize: 20,
            backgroundColor: theme?.palette.primary,
          }}
          arrowStyle={{color: theme?.palette.primary}}
          children={[marker]}
          renderItem={renderItem}
          placement={'top'}
          arrow
        />
      );
    };

    const onThumbnailPress = (data: any, index: number) => {
        setZoomCenter({
            center: {
                lat: data?.lat,
                lng: data?.lng,
            },
            zoom: 11,
        });
    }

    return (
      <View style={styles.contentContainer}>
        <FBGridV justify={'center'}>
          <FBItemV colspan={10}>
        <View style={{ overflow: 'hidden', width: '90%' }}>
          <ScrollView contentContainerStyle={styles.thumbnailStyle}>
            {_data && _data.map((data, index) => (
              <TouchableOpacity onPress={() => onThumbnailPress(data, index)}>
                <ImageV
                  source={data?.image?.uri}
                  imageStyle={{
                    width: 420,
                    height: 220,
                    resizeMode: 'cover',
                    borderRadius: 10,
                    marginHorizontal: 10,
                    shadowOpacity: 0.75,
                    shadowRadius: 10,
                    shadowColor: 'rgba(0,0,0,0.9)',
                    shadowOffset: { height: 0, width: 0 },
                    overflow: 'hidden'
                  }}
                />
                <TextV type={'title1'} style={{ fontSize: 20, marginLeft: 10 }}>
                  {data?.title}
                </TextV>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
          </FBItemV>
          <FBItemV colspan={14}>
           <View style={{width: '100%'}}>
          {googleMapAPIKey ? 
          <GoogleMapV
          width={0.51 * width}
          height={0.8 * height}
          bootstrapURLKeys={{ key: googleMapAPIKey}}
          defaultCenter={defaultCenter && defaultCenter}
          defaultZoom={BP.defaultZoom}
          center={zoomCenter?.center}
          zoom={zoomCenter?.zoom}
          markers={_markers && _markers}
          renderMarker={renderMarker}
          onChildClick={onChildClick}
          onChildMouseEnter={onChildMouseEnter}
          onChildMouseLeave={onChildMouseLeave}
          onBoundsChange={onBoundsChange}
        />:
        <TextV type={'title1'} style={{ fontSize: 16, marginLeft: 10 }}>
                  {"map not found!"}
      </TextV>
        }
        
            </View>
          </FBItemV>
        </FBGridV>
      </View>
    );
}