import gql from 'graphql-tag';

export const UPDATE_BATCH = gql`
mutation updateBatch($where:FindUniqueBranch, $data:UpdateBatchInput){
    updateBatch(
        where:$where
        data:$data
        ){
        _id
    }}
`;

export const GET_QR_CODE = gql`
mutation getQRCode($appMode:AppMode!, $gtin: String!, $generatePdf: Boolean){
    encodeBarcode(
        appMode: $appMode,
        gtin: $gtin,
        generatePdf: $generatePdf
    ){
        pdfBase64
    }
}
`;