import React, { useContext } from 'react';
import { ThemeContext } from '../../../../providers/theme';
import config from '../../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';

const BP = config.dashboard;

interface props {
}

export const DashboardSM = ({ }: props) => {
  const theme = useContext(ThemeContext);
  const { width, height } = useViewport();

  return (
    <div />
  );
};
