import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { EnterContestLG } from './EnterContest.lg';
import { EnterContestSM } from './EnterContest.sm';

export interface EnterContestProps {
    onContestSubmit?(formData: any): void;
    privacyClick?(): void;
    termsClick?(): void;
}

export const EnterContest = (props: EnterContestProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <EnterContestSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if(bp === 'lg')
    return <EnterContestLG {...props} />;
};
