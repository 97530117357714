import React from 'react';

import {LandingPageV } from '../../../../widgets/LandingPageV';

import config from './config/default-blueprint.json';
import {Journey} from './illustrators/Journey';
import {Food} from './illustrators/Food';
import {Auth} from './illustrators/Auth';
import {CustomerEngagement} from './illustrators/CustomerEngagement';
import {Factory} from './illustrators/Factory';
import {MobileMarket} from './illustrators/MobileMarket';
import {Shopping} from './illustrators/Shopping';
import {TargetMessage} from './illustrators/TargetMessage';

import { useHistory } from 'react-router-dom';
import { Feedback } from './illustrators/Feedback';
import { id } from 'date-fns/esm/locale';

const benefits = {
    benefitsTitle: 'Empower brands',
    benefitsDesciption:
      'Detecting counterfeits and verifying the journey of your product is now at your fingertips',
    benefitsData: [
      {
        icon: 'https://i.ibb.co/k26kSKD/protection.png',
        iconStyle: {
          tintColor: '#5987AF',
        },
        title: 'Brand Protection & Brand Value Elevation',
        desciption:
          null,
      },
      {
        icon: 'https://i.ibb.co/R4PPVmF/user-experience.png',
        iconStyle: {
          tintColor: '#5987AF',
        },
        title: 'Elevated Customer Experience (Millennials and Gen-Z)',
        desciption:
          null,
      },
      {
        icon: 'https://i.ibb.co/Lpctd9n/revenue.png',
        iconStyle: {
          tintColor: '#5987AF',
        },
        title: 'Increased Revenue (by means of offering clean, traceable product)',
        desciption:
          null,
      }
    ],
  };
  
  const alternateSections = [
    {
      key: '01',
      parallax: true,
      title: null,
      paragraph:"Blockchain-based Authenticity & Provenance Platform empowering brands to counter fraud, trace the Journey from source to consumer and engage consumers real-time",
      dotColor: '#5987AF',
      list: [ ],
    },
    {
      key: '02',
      parallax: true,
      title: null,
      paragraph:"Mobile wallet for consumers, application for supply chain actors to document proofs & API enablement layer to integrate with actors in supply chain",
      dotColor: '#5987AF',
      list: [ ],
    },
    {
      key: '03',
      parallax: true,
      title: null,
      paragraph:"Aligns with global standards including gs1 and is used by clients in US and India",
      dotColor: '#5987AF',
      list: [ ],
    },
    {
      key: '05',
      parallax: true,
      title: null,
      paragraph:"Proves Authenticity of the Product",
      dotColor: '#5987AF',
      list: [ ],
    },
    {
      key: '06',
      parallax: true,
      title: null,
      paragraph:"Provides Transparent view of Product Journey (Origin to Customer)",
      dotColor: '#5987AF',
      list: [ ],
    },
    {
      key: '07',
      parallax: true,
      title: null,
      paragraph:"Enhances after-sale experience by automating warranty process & batch recall",
      dotColor: '#5987AF',
      list: [ ],
    }
  ];
  
  const pricingTable = [
    {
      buttonText: 'Get Started',
      title: 'Bronze',
      priceText: '$100 /Month',
    },
    {
      buttonText: 'Get Started',
      title: 'Silver',
      priceText: '$400 /Month',
    },
    {
      buttonText: 'Contact Us',
      title: 'Gold',
      priceText: 'Contact Us',
    }
];
  
const pricingDetail = {
    tableHeader: [
      {
        key: 'featureCategory',
        label: 'Feature Category',
      },
      {
        key: 'feature',
        label: 'Feature',
      },
      {
        key: 'bronze',
        label: 'Bronze',
      },
      {
        key: 'silver',
        label: 'Silver',
      },
      {
        key: 'gold',
        label: 'Gold',
      }
    ],
    dataList: [
      {
        title: 'Product Stories',
        subTitle: 'Tell the stories behind  your projects',
        icon1: 'check',
        icon2: 'check',
        icon3: 'check',
      },
      {
        title: 'Proof Points',
        subTitle: 'Prove statement(eg recyclable)',
        icon1: 'check',
        icon2: 'check',
        icon3: 'check',
      },
      {
        title: 'Sharing Tools',
        subTitle: 'Publish across all digital and physical channel',
        icon1: 'check',
        icon2: 'check',
        icon3: 'check',
      },
      {
        title: 'Network Map',
        subTitle: 'Open up your supplirs market',
        icon1: 'close',
        icon2: 'close',
        icon3: 'check',
      },
      {
        title: 'Product Journey',
        subTitle: 'Show the full journey',
        icon1: 'close',
        icon2: 'close',
        icon3: 'check',
      },
    ],
  };
  
  const footer = {
    contactDetails: {
      title: 'CONTACT US',
      details: [
        {
          key: 'phone',
          label: 'Phone',
          value: '+91 80 4155 7384',
        },
        {
          key: 'email',
          label: 'Email',
          value: 'info@vlinder.io',
        },
      ],
    },
    socialLinks: {
      title: 'CONNECT WITH US',
      details: [
        {
          key: 'twitter',
          icon: 'https://i.ibb.co/k693xvN/twitter.png',
          link: 'https://twitter.com/GoVlinder/',
        },
        {
          key: 'linkedIn',
          icon: 'https://i.ibb.co/8jdMD1q/linkedIn.png',
          link: 'https://www.linkedin.com/company/vlinder-io/',
        },
      ],
    },
    appDownload:{
      title: "Download App",
      links:[
        {
          "key": "android",
          "icon": "https://i.ibb.co/560xTmk/android-icon.png",
          "url":"https://play.google.com/store/apps/details?id=io.vlinder.trag"
        }, 
        {
          "key": "ios",
          "icon": "https://i.ibb.co/VNtXMYT/apple-icon.png",
          "url":"https://apps.apple.com/app/id1536485775"
        }
      ]
    },
    copyright: {
      logo: 'https://i.ibb.co/VNHWjpM/vlinder-circle.png',
      footerNav: [
        {
          eventKey: 'aboutus',
          label: 'About Us',
        },
        {
          eventKey: 'terms',
          label: 'Terms And Condition',
        },
        {
          eventKey: 'privacy',
          label: 'Privacy',
        },
      ],
      copyRightText: {
        company: 'vlinder Inc.',
        poweredBy: 'vlinder',
        poweredByURL: 'https://vlinder.io/',
      },
    },
  };
  
  const HeaderMenu = [
    {
      eventKey: 'product',
      label: 'Product',
    },
    {
      eventKey: 'benefits',
      label: 'Benefits',
    },
    {
      eventKey: 'features',
      label: 'Features',
    },
    {
      eventKey: 'contact',
      label: 'Contact'
    }
  ];

export const Landing = () => {
    const history = useHistory();
    const onFooterNavSelect = (eventKey:string) => {
      console.log('foote nav item', eventKey);
      if(eventKey === 'aboutus'){
        history.push('/hello');
      }
      if(eventKey === 'terms'){
        history.push('/termsandcondition');
      }
      if(eventKey === 'privacy'){
        history.push('/privacy');
      }
    };
    
    const handleIllustrator = ({
        key,
        width,
        height,
        stroke,
        main,
        highlight,
        secondary,
        tertiary,
        index,
      }) => {
        switch (key) {
          // case 'university':
          //   return <University width={width} height={height} stroke={stroke} main={main} highlight={highlight} secondary={secondary} tertiary={tertiary} />
          // case 'verifier':
          //   return <Verifier width={width} height={height} stroke={stroke} main={main} highlight={highlight} secondary={secondary} tertiary={tertiary} />
          // case 'holder':
          //   return <Reader width={width} height={height} stroke={stroke} main={main} highlight={highlight} secondary={secondary} tertiary={tertiary} />
          case 'journey':
            return (
              <Journey
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'factory':
            return (
              <Factory
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'food':
            return (
              <Food
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'auth':
            return (
              <Auth
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'customer-engagement':
            return (
              <CustomerEngagement
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'shopping':
            return (
              <Shopping
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'feedback':
            return (
              <Feedback
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          case 'mobile-market':
            return (
              <MobileMarket
                width={width}
                height={height}
                stroke={stroke}
                main={main}
                highlight={highlight}
                secondary={secondary}
                tertiary={tertiary}
              />
            );
          // case 'target-message':
          //   return (
          //     <TargetMessage
          //       width={width}
          //       height={height}
          //       stroke={stroke}
          //       main={main}
          //       highlight={highlight}
          //       secondary={secondary}
          //       tertiary={tertiary}
          //     />
          //   );
          default:
            return null;
        }
      };
    return (
        <LandingPageV
        themeColor={"#5987AF"}
        bgImg={'https://i.ibb.co/KbqMfjt/slider-bg.png'}
        bgImgHeight={'80vh'}
        loginButtonTitle={'Login'}
        headerMenu={HeaderMenu}
        title={'Trag'}
        onLoginButtonPress={()=>{history.push('/login')}}
        leftAvatar={'https://i.ibb.co/TPKn99J/trag-logo.png'}
        videoLink={'https://www.youtube.com/watch?v=NBFWvdno3tQ'}
        videoButtonColor={'red'}
        videoTitle={'Trag: Authenticate & Trace'}
        videoDescription={
          'Trag is a Blockchain-enabled Authenticity & Provenance Platform empowering brands to counter fraud and track the Journey of their products from source to end consumers.'
        }
        benefits={benefits}
        // pricingData={pricingTable}
        pricingTitle={'Choose the right plan'}
        pricingSubTitle={
          'Start your transparency journey today. Open up your supply chain behind a single product or the impact across your entire business.'
        }
        renderIllustrator={handleIllustrator}
        illustratorsGroup={config.Illustrator.illustratorGroup}
        mobileillustratorsGroup={config.Illustrator.mobileIllustratorGroup}
        bodyContent={alternateSections}
        contactDetails={footer?.contactDetails}
        socialLinks={footer?.socialLinks}
        copyright={footer?.copyright}
        contactForm={config?.contactForm}
        // parallaxBg={`${require('./assets/digicert.gif')}`} 
        //mobileParallaxBg={`${require('./assets/mobile-bg.png')}`}
        pricingDetailData={pricingDetail}
        onFooterNavSelect={onFooterNavSelect}
      />
    )
}