import React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

export interface RectangleVProps {
    triangleStyle?: ViewStyle;
    color?: string;
    size?: number;
};

const styles = StyleSheet.create({
    triangle: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderLeftWidth: 50,
        borderRightWidth: 50,
        borderBottomWidth: 100,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'red',
    }
})

export const TriangleV = (props: RectangleVProps) => {
    return (
        <View
            style={
                StyleSheet.flatten([styles.triangle,
                props.triangleStyle,
                props.color && { borderBottomColor: props.color },
                props.size && { borderBottomWidth: props.size, borderLeftWidth: props.size / 2, borderRightWidth: props.size / 2 }
                ])
            }
        />
    )
} 