import React from 'react';
import { StyleSheet, View } from 'react-native';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { PanelV } from 'react-panel-rs';
import { TextV } from 'react-native-text-vfw';
import {useHistory} from 'react-router-dom';
import { IconV } from 'react-icon-rs';
import { useViewport } from 'react-viewport-provider';
import { titleCase } from 'title-case';
import Rating from '@material-ui/lab/Rating';
import { ThemeContext } from '../../../../providers/theme';

interface HeaderInfoProps {
    title?: string;
    volume?: string;
    info?: string;
    price?: string;
};

export const HeaderInfoSM = ({
    title,
    volume,
    info,
    price
}: HeaderInfoProps) => {
    const theme = React.useContext(ThemeContext);
    const [rate, setRate] = React.useState(4);
    const { height } = useViewport();
    const history = useHistory();
    const styles = {
        panelStyle: {
            borderRadius: 10,
            backgroundColor: '#ffffff',
            // position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            top: 400,
            elevation: 10,
            borderColor: theme?.palette?.primary,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
        },
        titleStyle: {
            textAlign: 'left',
            fontWeight: 'bold',
            letterSpacing: 0.95,
            paddingTop: 5,
            paddingBottom: 5,
            display: 'block'
        },
        volumeStyle: {
            color: 'gray',
            fontSize: 13,
            fontWeight: 'bold',
            paddingVertical: 5,
        },
        infoStyle: {
            textAlign: 'justify',
            color: 'gray',
            fontSize: 13,
            fontWeight: '600',
        },
        priceContainer: {
            backgroundColor: theme?.palette?.primary,
            width: price
                ? price.length * 15
                : 90,
            borderRadius: 10,
            elevation: 10,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginRight: 5
        },
        priceStyle: {
            color: '#fff',
            fontWeight: 'bold',
            letterSpacing: 0.9,
            fontSize: 18,
        },
    };

    const _getIcon = () => {
        if (true) {
            return (
                <IconV
                    icons={[
                        {
                            name: "lock",
                            style: {
                                color: 'green'
                            },
                            size: '2x'
                        }
                    ]}
                />
            );
        } else {
            return (
                <IconV
                    icons={[
                        {
                            name: 'unlock-alt',
                            style: {
                                color: 'tomato',
                            },
                            size: '2x',
                        },
                    ]}
                />
            );
        }
    }

    const handleHeaderInfoClick = () => {
        history.push('/productDetail');
    }
    if(title) {
    return (
        <PanelV style={StyleSheet.flatten([styles.panelStyle])} onClick={handleHeaderInfoClick}>
            <FBGridV>
                {/* <FBItemV colspan={22}></FBItemV>
                <FBItemV colspan={2}>{_getIcon()}</FBItemV> */}
                <FBItemV colspan={24} style={{ paddingBottom: '15px' }}>
                    {title && (
                        <TextV
                            type={'title3'}
                            children={typeof title === 'string' && titleCase(title)}
                            style={StyleSheet.flatten([styles.titleStyle])}
                            ellipsizeMode={'tail'}
                            numberOfLines={2}
                        />
                    )}
                </FBItemV>
                {/* <FBItemV colspan={24} style={{ marginBottom: 10 }}>
                    <Rating
                        name="simple-controlled"
                        value={rate}
                        onChange={(event: object, value: number) => {
                            onRating(value)
                            setRate(value)
                        }}
                    />
                </FBItemV> */}
                <FBItemV colspan={24} style={{ paddingBottom: '5px' }}>
                    {volume && (
                        <TextV
                            type={'callout'}
                            children={typeof volume === 'string' && titleCase(volume)}
                            ellipsizeMode={'tail'}
                            numberOfLines={1}
                            style={StyleSheet.flatten([styles.volumeStyle])}
                        />
                    )}
                </FBItemV>
                <FBItemV colspan={24}>
                    {info && (
                        <TextV
                            type={'headline'}
                            children={typeof info === 'string' && titleCase(info)}
                            ellipsizeMode={'tail'}
                            numberOfLines={3}
                            style={StyleSheet.flatten([styles.infoStyle])}
                        />
                    )}
                </FBItemV>
                <FBItemV colspan={16}></FBItemV>
                <FBItemV colspan={8} style={{justifyContent:''}}>
                    {price && (
                        <View style={StyleSheet.flatten([styles.priceContainer])}>
                            <TextV
                                type={'title3'}
                                children={typeof price === 'string' && titleCase(price)}
                                ellipsizeMode={'tail'}
                                numberOfLines={1}
                                style={StyleSheet.flatten([styles.priceStyle])}
                            />
                        </View>
                    )}
                </FBItemV>
            </FBGridV>
        </PanelV>
    );
    } else {
        return null
    }
}