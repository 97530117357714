//@ts-nocheck
import {MaskServiceV} from 'mask-service-vfs';
import React, {useEffect, useState} from 'react';
import {AutoCompleteV} from 'react-autocomplete-input-rs';
import {CheckboxV} from 'react-checkbox-rs';
import {CheckPickerV} from 'react-checkpicker-rs';
import {DatePickerV} from './DataPickerV';
import {useForm} from 'react-hook-form';
import {InputNumberV} from 'react-input-number-rs';
import {InputV} from 'react-input-rs';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {Wrap} from 'react-native-flexbox-vfw';
import {RadioV} from 'react-radio-rs';
import {SelectInputV} from 'react-select-input-rs';
import {ToggleV} from 'react-toggle-rs';
import {Schema} from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import FormGroup from 'rsuite/lib/FormGroup';
import Badge from 'rsuite/lib/Badge';
import InputGroup from 'rsuite/lib/InputGroup';
import { TextV } from 'react-native-text-vfw';

const ids = require('short-id');
const {
  StringType,
  NumberType,
  ArrayType,
  DateType,
  ObjectType,
  BooleanType,
} = Schema.Types;

export const SENDER_ID = 'widgets:FormV';
export const errorsDS = new DataSet({
  fieldId: 'key',
});
export const defaultValuesDS = new DataSet({
  fieldId: 'key',
});
export const formValidationDS = new DataSet();

export const FORM_VALUES = 'form_values';
const schemaTypeMapper = (schema: string) => {
  let mapper;
  if (schema) {
    try {
      mapper = eval(schema);
    } catch (err) {
    }
  }
  return mapper;
};

function deleteKeys(obj: any, keys: string[]) {
  if (obj && keys && Array.isArray(keys)) {
    keys.forEach((key: string) => {
      delete obj[key];
    });
  }
}

function renameKeys(obj: any, newKeys: any, delete_keys?: string[]) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return {[newKey]: obj[key]};
  });
  const ret = Object.assign({}, ...keyValues);
  delete_keys && deleteKeys(ret, delete_keys);
  return ret;
}

interface FormType {
  component:
    | 'input'
    | 'toggle'
    | 'checkbox'
    | 'radio'
    | 'input-number'
    | 'text-area'
    | 'select'
    | 'autocomplete-select'
    | 'checkpicker'
    | 'datepicker'
    | 'anchor';
  componentProps?: any;
  key: string;
  label: string;
  defaultValue?: any;
  schemaType?: any;
}

export type WidgetProps = {
  containerStyle?: ViewStyle;
  components: FormType[];
  validateForm?: boolean;
  formContext?: any;
  defaultValues?: any[];
  onValidateResult?(
    formContext: any,
    status: 'success' | 'error',
    error?: any,
  ): void;
};

const mapper = {
  inputNumberStyle: 'style',
  prefixComponent: 'prefix',
  suffixComponent: 'postfix',
};
const delete_keys = [
  'containerStyle',
  'inline',
  'onChange',
  'title',
  'titleStyle',
  'titleContainerStyle',
  'inputGroupStyle',
  'prefixComponent',
  'suffixComponent',
  'helpblock',
  'errorMessage',
];

const styles = StyleSheet.create({
  errorMessageStyle: {
    color: 'red',
    marginTop: 5,
    marginLeft: 5,
    fontSize: 11,
  },
});

export const FormV = React.forwardRef((props: WidgetProps, ref: any) => {
  const {register, errors} = useForm<any>({
    mode: 'onBlur',
  });
  const [date, setDate] = useState<Date>(new Date());
  const validate = () => {
    if (formModel && defaultValuesDS.get()) {
      const pipe = createNewDataPipeFrom(defaultValuesDS)
        // All items can be arbitrarily transformed.
        .map(item => ({
          [item.key]: item.defaultValue,
        }))
        // This builds and returns the pipe from appDS to visDS.
        .to(formValidationDS);

      pipe.all().start();

      const _mergeResult = formValidationDS
        .get()
        .reduce(function (result, current) {
          return Object.assign(result, current);
        }, {});
      const _result = formModel.check(_mergeResult);
      let _status = 'success';
      for (const key in _result) {
        if (_result[key]?.hasError) {
          _status = 'error';
          break;
        }
      }
      for (const key in _result) {
        errorsDS.update({
          key: key,
          errorMessage: _result[key].errorMessage,
        });
      }
      props.onValidateResult &&
        props.onValidateResult(formContext, _status, _result);
      if (_status === 'error') {
        setDate(new Date());
      }
    }
  };

  useEffect(() => {
    if (ref) {
      ref.current = validate;
    }
  }, []);

  useEffect(() => {
    defaultValuesDS.update(props.defaultValues);
  }, [props.defaultValues]);

  useEffect(() => {
    props.validateForm && validate();
  }, [props.validateForm]);

  const _props = {...props};
  const {components, formContext} = props;
  const schema = {};
  let formModel;

  const _widgetProps = renameKeys(_props, mapper);
  deleteKeys(_widgetProps, delete_keys);

  const handleNonInputChange = (name, value, schema) => {
    const inputModel = Schema.Model({
      [name]: schema[name],
    });
    const res = inputModel.check({
      [name]: value,
    });
    errorsDS.update({
      key: name,
      errorMessage: res[name].errorMessage,
    });
    defaultValuesDS.update({
      key: name,
      defaultValue: value,
    });
    if (res[name].hasError) {
      setDate(new Date());
    }
  };

  const handleToggleOnChange = (name, schema, checked) => {
    handleNonInputChange(name, checked, schema);
  };

  const handleRadioOnChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleCheckboxOnChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleInputOnEnterPress = (name, schema, value) => {};

  const handleInputNumberOnChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleOnSelectChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleOnSelectEnterPress = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleAutoComSelectChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleAutoComSelectEnterPress = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleCheckPickerChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleCheckPickerEnterPress = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const handleDatePickerChange = (name, schema, value) => {
    handleNonInputChange(name, value, schema);
  };

  const _renderForm = () => {
    const _formFields = [];
    if (props.components && Array.isArray(props.components)) {
      const formFields = [...props.components];
      for (let i = 0; i < formFields.length; ++i) {
        //-------------------------------------------------
        /**
         * InputNumber
         */
        if (formFields[i].component === 'input-number') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <InputNumberV
              {...formFields[i].componentProps}
              onChange={handleInputNumberOnChange.bind(null, name, schema)}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
            />,
          );
        }

        //-------------------------------------------------
        /**
         * Checkbox
         */
        if (formFields[i].component === 'checkbox') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
            <CheckboxV
              {...formFields[i].componentProps}
              onChange={handleCheckboxOnChange.bind(null, name, schema)}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
            />
            {formFields[i].anchor &&
                <a href={formFields[i].href} style={StyleSheet.flatten([{ lineHeight: 1.14285714, paddingTop: '8px', paddingBottom: '10px', marginTop: '23px' }, formFields[i].containerStyle])}>
                <TextV type={'callout'} style={StyleSheet.flatten([{color: '#57577C', fontSize: 14}, formFields[i].titleStyle])}>{formFields[i].title}</TextV>
                </a>
            }
            </View>
          );
        }

        //--------------------------------------------------
        /**
         * Radio
         */
        if (formFields[i].component === 'radio') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <RadioV
              {...formFields[i].componentProps}
              onChange={handleRadioOnChange.bind(null, name, schema)}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
            />,
          );
        }

        //--------------------------------------------------
        /**
         * Toggle
         */
        if (formFields[i].component === 'toggle') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;

          _formFields.push(
            <ToggleV
              {...formFields[i].componentProps}
              onChange={handleToggleOnChange.bind(null, name, schema)}
              title={formFields[i].label}
              defaultChecked={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
            />,
          );
        }

        //--------------------------------------------------
        /**
         * input & text-area
         */
        if (
          formFields[i].component === 'input' ||
          formFields[i].component === 'text-area'
        ) {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;

          _formFields.push(
            <InputV
              key={ids.generate()}
              {...formFields[i].componentProps}
              title={formFields[i].label}
              defaultValue={
                defaultValuesDS.get(formFields[i].key)?.defaultValue
              }
              ref={register({
                validate: value => {
                  const inputModel = Schema.Model({
                    [name]: schema[name],
                  });
                  let _value: any = value;
                  let _rawValue: any = value;
                  let _cc = '';
                  if (formFields[i].componentProps?.maskParams) {
                    const maskParams = formFields[i].componentProps?.maskParams;
                    if (maskParams && value && value !== '') {
                      const _mask = new MaskServiceV(
                        maskParams.type,
                        value,
                        maskParams.options,
                      );
                      _value = _mask.toMask();
                      _rawValue = _mask.toRawValue();
                      _cc = _mask.getCC();
                    }
                  }

                  const res = inputModel.check({
                    [name]: _rawValue,
                  });
                  errorsDS.update({
                    key: formFields[i].key,
                    errorMessage: res[name].errorMessage,
                  });
                  defaultValuesDS.update({
                    key: formFields[i].key,
                    defaultValue: _rawValue,
                  });
                  return !res[name].hasError;
                },
              })}
              name={formFields[i].key}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
              textArea={
                formFields[i].component === 'text-area' ? true : undefined
              }
              autoComplete={'new-password'}
              inputStyle={StyleSheet.flatten([
                formFields[i].componentProps?.inputStyle,
                formFields[i].component === 'text-area' && {resize: 'auto'},
              ])}
            />,
          );
        }

        //--------------------------------------------------
        /**
         * input & selectInput
         */

        if (formFields[i].component === 'select') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <SelectInputV
              {...formFields[i].componentProps}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
              onChange={handleOnSelectChange.bind(null, name, schema)}
              onEnterPress={handleOnSelectEnterPress.bind(null, name, schema)}
            />,
          );
        }

        //--------------------------------------------------
        /**
         * input & autoComplete-select
         */

        if (formFields[i].component === 'autocomplete-select') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <AutoCompleteV
              {...formFields[i].componentProps}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
              onChange={handleAutoComSelectChange.bind(null, name, schema)}
              onEnterPress={handleAutoComSelectEnterPress.bind(
                null,
                name,
                schema,
              )}
            />,
          );
        }

        //--------------------------------------------------
        /**
         * input & autoComplete-select
         */

        if (formFields[i].component === 'checkpicker') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <CheckPickerV
              {...formFields[i].componentProps}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
              onChange={handleCheckPickerChange.bind(null, name, schema)}
              onEnterPress={handleCheckPickerEnterPress.bind(
                null,
                name,
                schema,
              )}
            />,
          );
        }

        //--------------------------------------------------
        /**
         * datePicker
         */

        if (formFields[i].component === 'datepicker') {
          const name = formFields[i].key;
          const res = schemaTypeMapper(formFields[i].schemaType);
          if (res) schema[name] = res;
          _formFields.push(
            <DatePickerV
              {...formFields[i].componentProps}
              title={formFields[i].label}
              defaultValue={defaultValuesDS.get(name)?.defaultValue}
              errorMessage={errorsDS.get(formFields[i].key)?.errorMessage}
              onChange={handleDatePickerChange.bind(null, name, schema)}
            />,
          );
        }
      }
      formModel = Schema.Model(schema);
    }
    return <FormGroup>{_formFields}</FormGroup>;
  };

  return (
    <View
      style={StyleSheet.flatten([
        props.containerStyle,
        {flexDirection: 'row'},
        {alignItems: 'flex-start'},
      ])}
    >
      <Wrap
        containerStyle={{alignItems: 'flex-start', justifyContent: 'center'}}
      >
        {_renderForm()}
      </Wrap>
    </View>
  );
});
