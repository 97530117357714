//@ts-nocheck
import React, {ReactNode, useState} from 'react';
import {IconV} from 'react-icon-rs';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import 'rsuite/dist/styles/rsuite-default.css';
import HelpBlock from 'rsuite/lib/HelpBlock';
import InputNumber from 'rsuite/lib/InputNumber';

function deleteKeys(obj: any, keys: string[]) {
  if (obj && keys && Array.isArray(keys)) {
    keys.forEach((key: string) => {
      delete obj[key];
    });
  }
}

function renameKeys(obj: any, newKeys: any, delete_keys?: string[]) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return {[newKey]: obj[key]};
  });
  const ret = Object.assign({}, ...keyValues);
  delete_keys && deleteKeys(ret, delete_keys);
  return ret;
}

export type WidgetProps = {
  classPrefix?: string;
  containerStyle?: ViewStyle;
  title?: string;
  titleStyle?: TextStyle;
  titleContainerStyle?: ViewStyle;
  inputNumberStyle?: any;
  inline?: boolean;
  defaultValue?: number;
  disabled?: boolean;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  min?: number;
  max?: number;
  step?: number;
  onChange?(value: any, meta?: string): void;
  prefixComponent?: ReactNode;
  suffixComponent?: ReactNode;
  helpblock?: {
    tooltip?: boolean;
    message: string;
    style?: any;
  };
  errorMessage?: string;
  errorMessageStyle?: any;
};

const mapper = {
  inputNumberStyle: 'style',
  prefixComponent: 'prefix',
  suffixComponent: 'postfix',
};
const delete_keys = [
  'containerStyle',
  'inline',
  'onChange',
  'title',
  'titleStyle',
  'titleContainerStyle',
  'inputGroupStyle',
  'prefixComponent',
  'suffixComponent',
  'helpblock',
  'errorMessage',
  'errorMessageStyle',
];

const styles = StyleSheet.create({
  linerStyle: {
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  suffixStyle: {},
  textInlineStyle: {marginRight: 10, marginTop: 10},
  textNonInlineStyle: {marginBottom: 10},
  errorMessageStyle: {
    color: 'red',
    marginTop: 5,
    marginLeft: 5,
    fontSize: 11,
  },
});

export const InputNumberV = (props: WidgetProps) => {
  const [value, setValue] = useState<number>(0);
  const _props = {...props};

  const _widgetProps = renameKeys(_props, mapper);
  deleteKeys(_widgetProps, delete_keys);
  const handleOnChange = (value: any, event: any) => {
    props.onChange && props.onChange(value);
    setValue(value);
  }

  const renderComponent = (comp) => {
    if (comp) {
      if (Array.isArray(comp)) {
        return (
          <IconV
            icons={comp}
          />
        )
      }
    }
    return comp;
  }

  const _renderBody = () => {
    return (
      <InputNumber
        {..._widgetProps}
        prefix={renderComponent(props.prefixComponent)}
        postfix={renderComponent(props.suffixComponent)}
        value={value}
        onChange={handleOnChange}
      />
    );
  }

  const _renderTitle = () => {
    return (
      <View
        style={StyleSheet.flatten([
          props.titleContainerStyle,
          {flexWrap: 'wrap'},
        ])}
      >
        <Text
          style={StyleSheet.flatten([
            props.titleStyle,
            props.inline ? styles.textInlineStyle : styles.textNonInlineStyle,
          ])}
        >
          {props.title}
        </Text>
      </View>
    );
  }
  return (
    <View
      style={StyleSheet.flatten([
        props.containerStyle,
        props.inline && {flexDirection: 'row'},
      ])}
    >
      {props.title && _renderTitle()}
      <View
        style={StyleSheet.flatten([
          {
            flexDirection:
              props.helpblock?.tooltip && !props.errorMessage
                ? 'row'
                : 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          },
        ])}
      >
        {_renderBody()}
        {props.helpblock && !props.errorMessage && (
          <HelpBlock
            tooltip={props.helpblock.tooltip}
            style={StyleSheet.flatten([props.helpblock.style])}
          >
            {props.helpblock.message}
          </HelpBlock>
        )}
        {props.errorMessage && (
          <HelpBlock
            tooltip={false}
            style={StyleSheet.flatten([styles.errorMessageStyle, props.errorMessageStyle])}
          >
            {props.errorMessage}
          </HelpBlock>
        )}
      </View>
    </View>
  );
};
