import React, { useEffect } from 'react';
import { Text, StyleSheet } from 'react-native';
import { MODAL_OPEN, ModalV } from 'react-modal-rs';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import { EventBus } from 'event-bus-vfs';
import { ViewportProvider, useViewport } from 'react-viewport-provider';
import { DrawerV } from 'react-drawer-rs';
import { useHistory, useLocation } from 'react-router-dom';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import ReactPlayer from 'react-player'
import { Modal } from 'rsuite';
import { ProductPage, FloatingProductInfo, ProvenanceMapModal, ProductInfoScreen, RatingInfo, ProductDetails, Header } from './components';
import BP from './config/default-blueprint.json';
import { useProductView } from './provider';
import config from '../../config/default.config.json';
import { HttpServer } from '../../servers/HttpServer';
// import {useAuth} from '../../services/auth-service/auth.provider';
import { UPDATE_FEEDBACK } from './mutation';
import { TextV } from 'react-native-text-vfw';
const is = require('is_js');
const http = new HttpServer(45000);
let count = 0;

const ProductViewInternal = () => {
  const { height, width, bp } = useViewport();
  const history = useHistory();
  const { batchPromoDetails, timeLineData } = useProductView();
  const {getSecretURL, app_id} = config;
  const [show, setShow] = React.useState(false);
  // const {setHash} = useAuth();
  const location = useLocation();
  const [feedbackModal, setFeedbackModal] = React.useState<boolean>(false);
  const [secretKey, setSecretKey] = React.useState<any[]>([]);
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'xs',
    backdrop: false,
    show: false,
  });

  const onModalClose = () => {
    if (show) {
      setShow(false);
    }
  }


  const {
    getFullProvenanceBarcodeWeb,
    data,
    getProvHomeLoading,
    getProvHomeError,
    prodFeedbackLoading,
    prodFeedbackError,
    prodFeedbackData,
    updateFeedback,
    isValid
  } = useProductView();

  useEffect(() => {
    http.post(getSecretURL, {
      "app_id":app_id
  }
  ).then(res=>{
    let _accessKeys= res?.data?.app?.metadata?.accessKeys;
    console.log(`secret keys`, _accessKeys);
    if(_accessKeys){
      setSecretKey([..._accessKeys]);
    }
  })
    const url = window.location.href;
    const res: any = _getVerifyData(url);
    if (res) {
      const { network, hash } = res;
      if (network && hash) {
        // setHash(hash);
        config.hash = hash;
        getFullProvenanceBarcodeWeb({ variables: { network: network, hash: hash } })
      }
    }

  }, []);

  useEffect(() => {
    if (data && !getProvHomeLoading && count === 0 && data?.BatchPromoDetails?.isPromoEnabled === true) {
      count = count + 1;
      setShow(true);
    }

  }, [data]);

  const _getModalBody = () => {
    return (
      <ReactPlayer url={batchPromoDetails?.promoVideoUrl} width={'100%'} controls />
    );
  }

  const _getVerifyData = (url: string) => {
    let param = url.split("/");
    if (param && is.array(param) && param.length > 4) {
      param = param.slice(-2);
      if (!param || is.empty(param)) {
        return history.push("/hello");
      }
      if (param && param[0] && param[1]) {
        return {
          network: param[0],
          hash: param[1]
        }
      }
    }
    return history.push("/hello");
  }

  const styles: object = {
    lgStyle: {
      maxWidth: width * 0.95,
      height: height * 0.95,
      alignSelf: 'center',
      alignItems: 'center',
      margin: '0 auto',
    },
    smStyle: {
      maxWidth: '100%',
      alignSelf: 'center',
      alignItems: 'center',
      margin: '0 auto',
    },
    modalStyle: {
      width: (bp === 'xs') ? '100%' : '60%',
      alignSelf: 'center',
      alignItems: 'center',
      margin: '4% auto 0 auto',
      background: 'transparent'
    },
    modalBodyStyle: {
      height: bp === 'xs' ? 480 : 480
    }
  };

  const handleBackPress = () => {
    // infoScreen && setInfoScreen(false);
  }

  const onFabClick = () => {
    history.push('/detailedInfo', { backButton: true });
  }

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  const onLGInfoClick = () => {
    let _drawer = { ...drawer };
    _drawer.show = true;
    setDrawer({
      ..._drawer,
    });
  }

  const renderDrawerBody = () => {
    return (
      <FloatingProductInfo
        headerTitle={BP.FloatingProductInfo.headerTitle}
        productInfo={BP.FloatingProductInfo.productInfoList}
        onProductInfoItemPress={() => { }}
        handleBackPress={handleBackPress}
        backButton={false}
      />
    );
  }

  // const handleLGMarkerClick = (data: any) => {
  //   EventBus.emit(MODAL_OPEN, {
  //     show: true,
  //     modalTitle: "",
  //     bodyChildren: [
  //       <ProvenanceMapModal mapData={data} />
  //     ],
  //     autoFocus: true,
  //     size: 'md',
  //     full: true,
  //     containerStyleLG: styles.lgStyle,
  //   })
  // }

  const handleFeedBackData = (formData: any) => {
  }

  const handleSMFeedbackClick = () => {
    history.push('/feedback');
  }

  const handlePromoButtonClick = () => {
    window.open(data?.BatchPromoDetails?.promoWebsiteUrl, '_blank');
  }

  const handleRating = (rate: number) => {
    EventBus.emit(MODAL_OPEN, {
      show: true,
      modalTitle: "",
      bodyChildren: [
        <RatingInfo formData={handleFeedBackData} />
      ],
      autoFocus: true,
      size: 'md',
      full: false,
      containerStyleLG: styles.lgStyle
    })
  }

  const handleLGHeaderInfoClick = () => {
    EventBus.emit(MODAL_OPEN, {
      show: true,
      bodyChildren: [
        <ProductDetails />
      ],
      autoFocus: true,
      size: 'lg',
      full: true,
      containerStyleLG: styles.lgStyle,
      containerStyleSM: styles.smStyle
    })
  }

  if (getProvHomeLoading || prodFeedbackLoading) {
    return <LogoSpinnerV uri={'https://i.ibb.co/fkhtppW/400x400-white.png'} />
  } else if (getProvHomeError || isValid === false) {
    history.push('/hello')
  } else if (data && !getProvHomeLoading) {
    const organizationLogo = data?.ModalProductDetails?.organizationLogo.uri;
    const _getProductPage = () => {
      let _mapKey = Array.isArray(secretKey) && secretKey.find(item=>item?.name === "googleMapAPIKey");
      console.log('map api key', _mapKey?.key);
      return(
        <ProductPage
          carouselImages={data && data?.ProductImageCard?.productImages}
          onFabClick={onFabClick}
          onInfoClick={onLGInfoClick}
          headerTitle={'Provenance'}
          handleLGMarkerClick={() => { }}
          onRating={handleRating}
          FeedbackClick={handleSMFeedbackClick}
          PromoEnrollmentClick={handlePromoButtonClick}
          handleLGHeaderInfoClick={handleLGHeaderInfoClick}
          googleMapAPIKey={_mapKey?.key}
        />
      )
    }
    
    return (
      <FBGridV>
        {bp !== 'xs' && bp !== 'sm' &&
          <FBItemV colspan={24}>
            <Header headerLogo={BP.tragLogo} />
          </FBItemV>
        }
        <FBItemV colspan={24}>
        {_getProductPage()}
        </FBItemV>
        <DrawerV
          placement={drawer.placement}
          show={drawer.show}
          backdrop={drawer.backdrop}
          size={drawer.size}
          renderHeader={renderDrawerHeader}
          onHide={handleOnDrawerHide}
          drawerTitle={drawer.title}
          renderBody={renderDrawerBody}
          renderFooter={renderDrawerFooter}
        />
        <ModalV />
        <Modal show={show} onHide={onModalClose} style={StyleSheet.flatten([styles.modalStyle])}>
          <Modal.Header >
            <div />
          </Modal.Header>
          <Modal.Body style={StyleSheet.flatten([styles.modalBodyStyle])}>

            {_getModalBody()}
          </Modal.Body>
        </Modal>
      </FBGridV>
    );
  }
}

export const ProductView = () => {
  return (
    <ViewportProvider>
      <ProductViewInternal />
    </ViewportProvider>
  );
}