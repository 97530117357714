import {useLazyQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import bp from './config/default-blueprint.json';
import {GET_PROV_HOME_PAGE_DATA} from './queries';
import {UPDATE_FEEDBACK, CREATE_CONTEST} from './mutation';

export const productViewDS = new DataSet({
  fieldId: 'id',
});

export const productViewTransformedDS = new DataSet({
  fieldId: 'id',
});
productViewTransformedDS.update(bp);

export const useProductView = () => {
  useEffect(() => {
      return function cleanup() {
        // productViewDS.clear();
        // productViewTransformedDS.clear();
      };
  }, []);

 const pipeTransformation = () => {
  const pipe = createNewDataPipeFrom(productViewDS)
    .map((item: any) => ({
      ...item,
      ProductPage: item?.ProductInfoCard,
      Chips: item?.chipList,
      ProductDetails: item?.ModalProductDetails,
      Carousel: item?.ProductImageCard?.productImages,
    }))
    .to(productViewTransformedDS);
  pipe.all().start();
};

  const [
    getFullProvenanceBarcodeWeb,
    {
      loading: getProvHomeLoading,
      data: getProvHomeData,
      error: getProvHomeError,
    },
  ] = useLazyQuery(GET_PROV_HOME_PAGE_DATA, {fetchPolicy:'network-only'});
  if(getProvHomeData){
  //  console.log('prov full data', getProvHomeData);
    productViewDS.update(getProvHomeData.getFullProvenanceBarcodeWeb);
    pipeTransformation();
  }

  const [
    createContestEntryWeb,
    {
      loading: prodContestLoading,
      data: prodContestData,
      error: prodContestError,
    },
  ] = useMutation(CREATE_CONTEST);

  const _getAllData = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let productData;

    if(_data){
      productData = _data
    }

    return productData;
  }

  const _getProvenanceData = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let timelineData;

    if(_data){
      timelineData = _data?.provenanceTimeline?.timeLineData
    }

    return timelineData;
  }

  const _getHeaderInfo = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let productInfo;

    if (_data) {
      productInfo = _data?.ProductInfoCard;
    }

    return productInfo;
  }

  const _getChipList = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let chipListData;

    if (_data) {
      chipListData = _data?.chipList;
    }

    return chipListData;
  }

  const _getFabProductinfo = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let productDetailedInfo;

    if (_data) {
      productDetailedInfo = _data?.ModalProductInformation;
    }

    return productDetailedInfo;
  }

  const _getFeedbackData = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let feedbackData;

    if (_data) {
      feedbackData = _data?.ModalFeedbackForm;
    }

    return feedbackData;
  }

  const _getProductDetails = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let productDetails;

    if (_data) {
      productDetails = _data?.ProductDetails;
    }

    return productDetails;
  }

  const _getMapDetails = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let mapDetails;

    if (_data) {
      mapDetails = _data?.ProvenanceMapView;
    }

    return mapDetails;
  }

  const _getIsValid = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let isValid;

    if (_data) {
      isValid = _data?.isValid;
    }
    return isValid;
  }

  const _getBatchPromoDetails = () => {
    const _data = productViewTransformedDS.get(bp.id);
    let _BatchPromoDetails;
    if(_data){
      _BatchPromoDetails = _data?.BatchPromoDetails;
    }
    return _BatchPromoDetails;
  }


  const data = _getAllData();
  const timeLineData = _getProvenanceData();
  const headerInfo = _getHeaderInfo();
  const chipList = _getChipList();
  const fabProductInfo = _getFabProductinfo();
  const feedbackData = _getFeedbackData();
  const productDetails = _getProductDetails();
  const mapDetails = _getMapDetails();
  const isValid = _getIsValid();
  const batchPromoDetails = _getBatchPromoDetails();

return {
  getProvHomeData,
  isValid,
  batchPromoDetails,
  getProvHomeLoading,
  data,
  timeLineData,
  headerInfo,
  chipList,
  fabProductInfo,
  feedbackData,
  productDetails,
  mapDetails,
  getProvHomeError,
  getFullProvenanceBarcodeWeb,

  prodContestLoading,
  prodContestError,
  prodContestData,
  createContestEntryWeb,
};
}