import {useLazyQuery, useQuery, useMutation} from '@apollo/react-hooks';
import {useEffect} from 'react';
import {createNewDataPipeFrom, DataSet} from 'vis-data';
import { RESET_PASSWORD} from './mutation';
import BP from './config/default-bluprint.json';

export const provResetPassword = () => {
    const [PasswordReset, { 
        data:resetPasswordData, loading:resetPasswordLoading, error:resetPasswordError
    }] = useMutation(RESET_PASSWORD);

    return {
        PasswordReset,
        resetPasswordData,
        resetPasswordLoading,
        resetPasswordError
    }
}