import React from 'react';
import {useViewport} from 'react-viewport-provider';
import {BenefitsLG} from './Benefits.lg';
import {BenefitsSM} from './Benefits.sm';

export interface IProps {
  benefits?: any;
}

export const Benefits = (props: IProps) => {
  const {bp} = useViewport();

  if (bp === 'xs' || bp === 'sm') {
    return <BenefitsSM {...props} />;
  }

  // if (bp === 'md') {
  //   return <LandingTemplateMD />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <BenefitsLG {...props} />;
};
