import React from 'react';
import { useViewport } from 'react-viewport-provider';
// import { EnrollFormLG } from './EnrollForm.lg';
import {EnrollFormSM} from './EnrollForm.sm';

export interface EnrollFormProps {
    onEnrollSubmit?(formData: any): void;
}

export const EnrollForm = (props: EnrollFormProps) => {
    const { bp } = useViewport();

    // if (bp === 'xs' || bp === 'sm') {
        return <EnrollFormSM {...props} />;
    // }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if(bp === 'lg')
    // return <EnrollFormLG {...props} />;
};
