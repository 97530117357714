import React from 'react';
import {View, StyleSheet} from 'react-native';
import {useViewport} from 'react-viewport-provider';
import {ButtonV, ButtonType} from 'react-native-button-vfw';
import {ThemeContext} from '../../../providers/theme';
import blueprint from '../config/default-blueprint.json';
import config from '../../../config/default.config.json';

const BP = blueprint.EnterContestButton;

interface FeedBackProps {
    onPress?(): void;
    contestButtonText: string;
};

export const FeedbackButton = (props: FeedBackProps) => {
    const theme = React.useContext(ThemeContext);
    const {bp} = useViewport();
    const styles = StyleSheet.create({
        buttonContainer: {
            justifyContent: (bp !== 'xs' && bp !== 'sm') ? 'flex-end' : 'center',
            alignItems: (bp !== 'xs' && bp !== 'sm') ? 'flex-end' : 'center',
            alignSelf: (bp !== 'xs' && bp !== 'sm') ? 'flex-end' : 'center',
        },
        buttonStyle: {
            borderRadius: 10,
            width: 180,
            marginBottom: 20
        },
    });

    return (
        <ButtonV
            type={ButtonType.Solid}
            title={props.contestButtonText}
            onPress={props.onPress}
            buttonStyle={StyleSheet.flatten([
                styles.buttonStyle,
                { backgroundColor: theme?.palette?.secondary },
            ])}
            containerStyle={StyleSheet.flatten([styles.buttonContainer])}
        />
    );
}