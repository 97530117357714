const AUTH_TOKEN = 'AUTH_TOKEN';
const ACTOR = 'ACTOR';
const USER_PROFILE = 'USER_PROFILE';

let token: string | undefined | null = null;
let actor: string | undefined | null;
let profile: any;

const init = async () => {
  token = await localStorage.getItem(AUTH_TOKEN);
  actor = await localStorage.getItem(ACTOR);
  profile = await localStorage.getItem(USER_PROFILE); 
}

const getToken = async () => {
  if (token) {
    return Promise.resolve(String(token));
  }

  // token = await localStorage.getItem(AUTH_TOKEN);
  //console.log("get token", String(token));
  return token;
};

const signIn = ({
  newToken, rememberMe = false, actorName, userProfile}: {
    newToken: string,
    rememberMe?: boolean,
    actorName?: string,
    userProfile?: any
  }) => {
  token = newToken;
  if (rememberMe) {
    localStorage.setItem(AUTH_TOKEN, String(newToken));
  }
  if (actorName) {
    actor = actorName;
    localStorage.setItem(ACTOR, String(actorName));
  }
  if (userProfile) {
    if (typeof userProfile === 'string') {
      userProfile = JSON.stringify(userProfile);
    } 
    profile = userProfile;
    localStorage.setItem(USER_PROFILE, String(userProfile));
  }
  return token;
};

const isAuthenticated = () => {
  return token ? true : false;
}

const signOut = () => {
  token = undefined;
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(ACTOR);
  localStorage.removeItem(USER_PROFILE);
};

const setActor = (actorName: string) => {
  actor = actorName;
  return localStorage.setItem(ACTOR, String(actorName));
};

const removeActor = () => {
  actor = undefined;
  return localStorage.removeItem(ACTOR);
};

const getActor = async () => {
  if (actor) {
    return Promise.resolve(String(actor));
  }

  actor = await localStorage.getItem(ACTOR);
  return actor;
};

const getUserProfile = async () => {
  if (profile) {
    return Promise.resolve(JSON.parse(profile));
  }

  profile = await localStorage.getItem(USER_PROFILE);
  if (profile) {
    profile = JSON.parse(profile);
  }
  return profile;
};

export default {
  getToken,
  signIn,
  signOut,
  setActor,
  removeActor,
  getActor,
  isAuthenticated,
  init,
  getUserProfile,
};
