//@ts-nocheck
import React from "react";
import { withTranslation } from "react-i18next";

import * as S from "./styles";

export const Input = ({ id, name, placeholder, onChange}) => {
    return (
        <S.Container>
            <label htmlFor={name}>{id}</label>
            <S.Input
                spellcheck="false"
                placeholder={placeholder}
                name={name}
                id={name}
                onChange={onChange}
            />
        </S.Container>
    );
};
