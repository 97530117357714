import React from 'react';
import {View, StyleSheet} from 'react-native';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {PanelV} from 'react-panel-rs';
import { Card} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {useViewport} from 'react-viewport-provider';
import {
  Carousel, HeaderInfo, ProvenanceMap, ProvenanceTimeline, FeedbackButton, PromoButton, ProductInfoScreen, FooterDetail} from '../index';
import { useProductView } from '../../provider';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProductPage;
interface ProductPageProps {
  onInfoClick?(): void;
  handleLGMarkerClick?(item: any): void;
  onRating?(value: number): void;
  carouselImages?: [];
  handleLGHeaderInfoClick?(): void;
  FeedbackClick?(): void;
  PromoEnrollmentClick?(): void;
  googleMapAPIKey?:string;
};

export const ProductPageMD = ({
  carouselImages,
  onInfoClick,
  handleLGMarkerClick,
  handleLGHeaderInfoClick,
  FeedbackClick,
  PromoEnrollmentClick,
  onRating,
  googleMapAPIKey
}: ProductPageProps) => {
  const history = useHistory();
  const { data, headerInfo, mapDetails, timeLineData, batchPromoDetails } = useProductView();
  const [provenanceOnClickData, setProvenanceOnClickData] = React.useState(timeLineData && timeLineData[0]);
  const {width} = useViewport();
  const galleryImages = carouselImages && carouselImages;
    const styles = {
      containerItemStyle: {
        alignItems: 'center',
        // backgroundImage: `URL(https://i.ibb.co/R7k8G53/slider-bg.png)`,
        // backgroundSize: '50%',        width: 
        padding: '5% 0px'
      },
        containerStyle: {
            alignItems: 'center',
            marginTop: 20,
            width: 0.89 * width,
        },
        mapStyles: {
            marginTop: 20
        },
      provenanceInfoCardStyle: {
        width: '90%',
        borderRadius: 10,
        marginTop: 20,
        paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: -20
      },
      timelineContainer: {
        width: 0.89 * width ,
        marginTop: 20
      }
    };

    const handleTimelinfProvClick = (item: any) => {
        // productInfo = item;
        // setTimelineInfo(true);
    }

  const handleTimelineClick = (item: any, index: number) => {
    if(item && provenanceOnClickData !== item){
      item.index = index;
      setProvenanceOnClickData(item);
    }
  }

  const onFooterNavSelect = (eventKey: string) => {
    if (eventKey === 'terms') {
      history.push('/termsandcondition');
      window.scrollTo(0, 0);
    }
    if (eventKey === 'privacy') {
      history.push('/privacy');
      window.scrollTo(0, 0)
    }
    if (eventKey === 'home') {
      history.push('/');
    }
  }

    return (
      <>
        {/* <HeaderV {...props} /> */}
        <FBGridV justify={'center'}>
          <FBItemV colspan={24}>
            <View style={StyleSheet.flatten([styles.containerItemStyle])}>
            
            <Card style={StyleSheet.flatten([styles.containerStyle])}>
              {/* <View style={StyleSheet.flatten([styles.panelStyle])}> */}
                <FBGridV justify={'center'}>
                  <FBItemV colspan={10}>
                    <Carousel images={galleryImages} />
                  </FBItemV>
                  <FBItemV colspan={14}>
                    <View style={{alignItems: 'center'}}>
                    <HeaderInfo
                      title={headerInfo?.title}
                      volume={headerInfo?.subTitle}
                      info={headerInfo?.info}
                      price={headerInfo?.price}
                      handleLGHeaderInfoClick={handleLGHeaderInfoClick}
                      onInfoClick={onInfoClick}
                      onRating={onRating}
                    />
                  </View>
                  </FBItemV>
                </FBGridV>
              {/* </View> */}
            </Card>
            </View>
          </FBItemV>
            <FBItemV colspan={24}>
            <View style={StyleSheet.flatten([styles.mapStyles])}>
              <ProvenanceMap
                defaultCenter={
                  {
                    lat: mapDetails && mapDetails?.post?.latitude,
                    lng: mapDetails && mapDetails?.post?.longitude
                  }
                }
                markers={mapDetails && mapDetails?.listMarkers}
                mapDetails={mapDetails}
              handleMarkerClick={handleLGMarkerClick} 
              googleMapAPIKey={googleMapAPIKey}
              />
            </View>
          </FBItemV>
          <FBItemV colspan={24} style={{ width: 'auto' }}>
            <View style={StyleSheet.flatten([styles.timelineContainer])}>
            <FBGridV justify={'center'}>
              <FBItemV colspan={10}>
                  <ProvenanceTimeline
                    timeLineData={timeLineData}
                    onTimelineElementClick={handleTimelineClick}
                    
                  />
              </FBItemV>
              {timeLineData &&
                <FBItemV colspan={14}>
                  <View style={{alignItems: 'center'}}>
                      <ProductInfoScreen
                        onClickData={provenanceOnClickData && provenanceOnClickData}
                        googleMapAPIKey={googleMapAPIKey}
                      />
                </View>
                </FBItemV>
              }
            </FBGridV>
            </View>
          </FBItemV>
          <FBItemV colspan={24}>
            <FBGridV>
              <FBItemV colspan={12}>
                {batchPromoDetails?.contestButtonText &&
                  <View style={{ marginTop: 20, marginRight: 10 }}>
                    <FeedbackButton contestButtonText={batchPromoDetails?.contestButtonText} onPress={FeedbackClick} />
                  </View>
                }
              </FBItemV>
              <FBItemV colspan={12}>
                {batchPromoDetails?.promoButtonText &&
                  <View style={{ marginTop: 20, marginLeft: 10 }}>
                    <PromoButton promoButtonText={batchPromoDetails?.promoButtonText} onPress={PromoEnrollmentClick} />
                  </View>
                }
              </FBItemV>
            </FBGridV>
          </FBItemV>
          <FBItemV colspan={24}>
            <FooterDetail onFooterNavSelect={onFooterNavSelect} />
          </FBItemV>
        </FBGridV>
      </>
    );
}