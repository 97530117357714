import React, {useContext} from 'react';
import { View, StyleSheet, Text} from 'react-native';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {
  Carousel,
  HeaderInfo,
  Chips,
  ProvenanceTimeline,
  ProvenanceMap,
  ProvenanceMapModal,
  ProductInfoScreen,
  ProductDetails,
  FAB, 
  FeedbackButton,
  PromoButton,
  FooterDetail
} from '../index';
import {TextV} from 'react-native-text-vfw';
import {useHistory} from 'react-router-dom';
import {useViewport} from 'react-viewport-provider';
import { ScrollView } from 'react-native-gesture-handler';
import {Avatar} from 'react-native-elements';
import Icon from 'rsuite/lib/Icon';
import config from '../../../../config/default.config.json';
import {ThemeContext} from '../../../../providers/theme';
import {useProductView} from '../../provider';
import blueprint from '../../config/default-blueprint.json';



interface ProductPageProps {
  onFabClick?(): void;
  onRating?(value: number): void;
  FeedbackClick?(): void;
  PromoEnrollmentClick?(): void;
  footerContents?: any;
  googleMapAPIKey?:string;
  carouselImages?: [];
};

const BP = blueprint.ProductPage;
7
export const ProductPageSM = ({
  onFabClick,
  onRating,
  FeedbackClick,
  PromoEnrollmentClick,
  footerContents,
  carouselImages,
  googleMapAPIKey
}: ProductPageProps) => {
  const galleryImages = carouselImages && carouselImages;
  const theme = useContext(ThemeContext);
  const history = useHistory();

  const { data, headerInfo, mapDetails, batchPromoDetails } = useProductView();
  const {height} = useViewport();

    const styles = {
        productInfoStyle: {
            flexDirection: 'column'
        },
        containerStyle: {
          height: height,
          backgroundColor: theme?.palette?.ternary
        },
      topSectionStyle: {
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 20, 
        // height: 440,
        backgroundColor: theme?.palette?.ternary,
        // position: 'relative'
      },
      footerContainerStyle: {
        width: '100%',
        bottom: 0,
        backgroundColor: '#25242A',
        padding: '2% 5%',
        marginTop: '8%'
      },
      footerHeaderStyle: {
        padding: '4% 5%',
        color: '#fff',
        fontSize: 16,
        fontWeight: 600,
        display: 'block'
      },
      footerTextStyle: {
        paddingLeft: '5%',
        textAling: 'center',
        fontSize: 14,
        color: '#fff',

      },
      footerColumnStyle: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textAlign: 'left',
        margin: '0 auto'
      },
      policyTextStyle: {
        paddingLeft: '5%',
        textAling: 'center',
        fontSize: 14,
        color: '#fff',
      }
    }

  const onFooterNavSelect = (eventKey: string) => {
    if (eventKey === 'terms') {
      history.push('/termsandcondition');
      window.scrollTo(0, 0);
    }
    if (eventKey === 'privacy') {
      history.push('/privacy');
      window.scrollTo(0, 0)
    }
    if (eventKey === 'aboutus') {
      history.push('/hello');
    }
  }

  const _getFooterContent = () => {
    let footerContent = footerContents || BP?.footerContent;
    return (
      <>
        <FBItemV colspan={6} style={{ textAlign: 'center' }}>
          {/* <Text style={StyleSheet.flatten([styles.footerHeaderStyle])}>{footerContent?.socialTitle}</Text> */}
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', }}>
            {
              footerContent && Array.isArray(footerContent?.socialIcons) ?
                footerContent?.socialIcons.map((item, key) => {
                  return (
                    <a href={item.link} target="_blank" style={{ padding: 5 }}>
                      <Avatar source={{ uri: item.icon }} size={20} />
                    </a>
                  )
                }) : null
            }</View>
        </FBItemV>
        <FBItemV colspan={8} style={{ textAlign: 'center', paddingTop: 5 }}>
          <a href={footerContent?.copyRightLink} target="_blank"> <img src={footerContent?.copyRightLogo} width={80} height={20} style={{ display: 'block', margin: '0 auto' }} /> </a>

        </FBItemV>
        <FBItemV colspan={10} style={{ textAlign: 'center', paddingTop: 5 }}>
          <Text style={StyleSheet.flatten(styles.footerTextStyle)}><Icon icon='copyright' size={8} />{' '}{footerContent?.copyRightText}</Text>
        </FBItemV>
        {/* {footerContent?.privacyPolicyURL &&
          <FBItemV colspan={10} style={{ textAlign: 'center', paddingTop: 5 }}>
          <a href={footerContent?.privacyPolicyURL} target="_blank"><Text style={StyleSheet.flatten(styles.policyTextStyle)}>{footerContent?.policyText}</Text></a>
          </FBItemV>
        } */}
       
      </>
    )
  }
      return (
        <View style={StyleSheet.flatten([styles.containerStyle])}>
          <ScrollView>
            <FBGridV>
              <FBItemV colspan={24}>
                <View style={StyleSheet.flatten([styles.topSectionStyle])}>
                  <Carousel images = {galleryImages} />
                  <HeaderInfo
                    title={headerInfo?.title}
                    volume={headerInfo?.subTitle}
                    info={headerInfo?.info}
                    price={headerInfo?.price}
                    onRating={onRating}
                  />
                </View>
              </FBItemV>
              <FBItemV colspan={24} >
                <Chips />
              </FBItemV>
              <FBItemV colspan={24}>
                <ProvenanceMap 
                  defaultCenter={
                    {
                    lat: mapDetails && mapDetails?.post?.latitude,
                    lng: mapDetails && mapDetails?.post?.longitude
                    }
                  }
                  markers={mapDetails && mapDetails?.listMarkers}
                  googleMapAPIKey={googleMapAPIKey}
                />
              </FBItemV>
              <FBItemV colspan={24}>
                <ProvenanceTimeline />
              </FBItemV>
              <FBItemV colspan={24}>
                {batchPromoDetails?.contestButtonText &&
                  <FeedbackButton contestButtonText={batchPromoDetails?.contestButtonText} onPress={FeedbackClick} />
                }
              </FBItemV>
              <FBItemV colspan={24}>
                {batchPromoDetails?.promoButtonText &&
                  <PromoButton promoButtonText={batchPromoDetails?.promoButtonText} onPress={PromoEnrollmentClick} />
                }
              </FBItemV>
              <FBItemV colspan={24}>
                <FooterDetail onFooterNavSelect={onFooterNavSelect} />
              </FBItemV>
            </FBGridV>
            {/* <FBGridV justify={'start'} align={'middle'} style={StyleSheet.flatten([styles.footerContainerStyle])}>
              {_getFooterContent()}
            </FBGridV> */}
          </ScrollView>
          <FAB onFabClick={onFabClick} />
        </View>
      );
}