import * as React from "react"
interface SVGRProps {
  title?: string;
  titleId?: string;
  main?: string;
  highlight?: string;
  secondary?: string;
  tertiary?: string;
}

export function MobileMarket({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={804}
      height={697.683}
      viewBox="0 0 804 697.683"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        data-name="Path 22"
        d="M669.817 165.283h-3.821V60.593A60.594 60.594 0 00605.402 0H383.596a60.594 60.594 0 00-60.593 60.594v574.343a60.594 60.594 0 0060.593 60.594h221.806a60.594 60.594 0 0060.593-60.594V239.803h3.821z"
        fill="#e6e6e6"
      />
      <path
        data-name="Path 23"
        d="M607.847 15.758h-28.95a21.498 21.498 0 01-19.908 29.618H431.92a21.498 21.498 0 01-19.905-29.617h-27.041a45.25 45.25 0 00-45.251 45.25v573.51a45.25 45.25 0 0045.25 45.25h222.875a45.25 45.25 0 0045.25-45.25V61.009a45.25 45.25 0 00-45.25-45.25z"
        fill="#fff"
      />
      <path
        d="M428.994 149.53a8.606 8.606 0 01-6.427-2.798 10.721 10.721 0 01-2.544-8.208 12.408 12.408 0 013.328-7.483 9.991 9.991 0 017.236-3.206 9.015 9.015 0 016.721 2.962 10.522 10.522 0 012.589 8.044 11.951 11.951 0 01-3.592 7.841 10.627 10.627 0 01-7.31 2.848zm1.593-18.08c-3.539 0-6.597 3.247-6.962 7.391a7.115 7.115 0 001.609 5.45 4.964 4.964 0 003.76 1.623c3.31 0 6.85-2.285 7.301-7.39a6.904 6.904 0 00-1.653-5.285 5.439 5.439 0 00-4.055-1.788zm7.51 7.233z"
        fill="#3f3d56"
      />
      <path
        d="M429.112 160.378a19.73 19.73 0 01-19.937-21.853 23.96 23.96 0 0123.442-21.538c6.51 0 11.865 2.091 15.488 6.048 3.436 3.752 5.045 9.033 4.53 14.872-.487 5.53-2.833 8.411-4.716 9.854a7.923 7.923 0 01-5.998 1.719c-2.976-.528-6.268-3.231-5.75-9.114l.958-10.876a1.808 1.808 0 013.602.317l-.958 10.876c-.258 2.925.781 4.882 2.779 5.237a4.363 4.363 0 003.168-1.03c1.306-1 2.942-3.08 3.313-7.3.43-4.886-.812-9.075-3.594-12.113-2.92-3.189-7.354-4.874-12.822-4.874a20.332 20.332 0 00-19.84 18.237 16.116 16.116 0 0016.335 17.922 18.145 18.145 0 008.258-1.497 1.808 1.808 0 011.4 3.334 21.691 21.691 0 01-9.658 1.78zM410.022 418.805a1.808 1.808 0 01-1.278-3.086l11.752-11.752a1.808 1.808 0 012.556 2.557l-11.751 11.752a1.802 1.802 0 01-1.279.53zm29.832-4.52a1.808 1.808 0 01-1.279-3.086l9.944-9.944a1.808 1.808 0 012.557 2.557l-9.944 9.944a1.802 1.802 0 01-1.278.53zm-5.424 0a1.802 1.802 0 01-1.279-.53l-7.232-7.231a1.808 1.808 0 112.557-2.557l7.232 7.232a1.808 1.808 0 01-1.278 3.086z"
        fill="#3f3d56"
      />
      <path
        d="M452.51 404.341a4.52 4.52 0 114.52-4.52 4.525 4.525 0 01-4.52 4.52zm0-5.423a.904.904 0 10.904.904.905.905 0 00-.904-.904zM437.142 419.71a4.52 4.52 0 114.52-4.52 4.525 4.525 0 01-4.52 4.52zm0-5.425a.904.904 0 10.904.904.905.905 0 00-.904-.904zM424.486 407.053a4.52 4.52 0 114.52-4.52 4.525 4.525 0 01-4.52 4.52zm0-5.423a.904.904 0 10.904.904.905.905 0 00-.904-.904zM407.31 424.23a4.52 4.52 0 114.52-4.52 4.525 4.525 0 01-4.52 4.52zm0-5.425a.904.904 0 10.904.904.905.905 0 00-.904-.904zM578.894 563.653h-37.968a6.335 6.335 0 01-6.328-6.328v-27.12a6.335 6.335 0 016.328-6.327h37.968a6.335 6.335 0 016.327 6.328v27.12a6.335 6.335 0 01-6.327 6.327zm-37.968-36.16a2.715 2.715 0 00-2.712 2.713v27.12a2.715 2.715 0 002.712 2.711h37.968a2.715 2.715 0 002.712-2.712v-27.12a2.715 2.715 0 00-2.712-2.711z"
        fill="#3f3d56"
      />
      <path
        d="M559.91 547.381a1.805 1.805 0 01-1.11-.38l-16.272-12.656a1.808 1.808 0 112.22-2.855l15.162 11.793 15.162-11.793a1.808 1.808 0 112.22 2.855L561.02 547a1.805 1.805 0 01-1.11.381z"
        fill="#3f3d56"
      />
      <path
        d="M763.835 588.683l-209.142.082a1 1 0 010-2l209.142-.082a1 1 0 010 2z"
        fill="#ccc"
      />
      <circle cx={761.051} cy={587.683} r={14} fill={props?.tertiary || "#5987af" } />
      <path
        d="M758.835 320.683l-211.142.082a1 1 0 010-2l211.142-.082a1 1 0 010 2z"
        fill="#ccc"
      />
      <circle cx={761.051} cy={320.683} r={14} fill={props?.tertiary || "#5987af" } />
      <path
        d="M218.268 183.683l211.141.082a1 1 0 000-2l-211.141-.082a1 1 0 100 2z"
        fill="#ccc"
      />
      <circle cx={216.051} cy={183.683} r={14} fill={props?.tertiary || "#5987af" } />
      <path
        d="M218.268 454.683l211.141.082a1 1 0 000-2l-211.141-.082a1 1 0 100 2z"
        fill="#ccc"
      />
      <circle cx={216.051} cy={454.683} r={14} fill={props?.tertiary || "#5987af" } />
      <path
        d="M559.91 588.765a45 45 0 1145-45 45.051 45.051 0 01-45 45zm0-88a43 43 0 1043 43 43.049 43.049 0 00-43-43zM429.91 454.765a45 45 0 1145-45 45.051 45.051 0 01-45 45zm0-88a43 43 0 1043 43 43.049 43.049 0 00-43-43zM550.91 320.765a45 45 0 1145-45 45.051 45.051 0 01-45 45zm0-88a43 43 0 1043 43 43.049 43.049 0 00-43-43zM430.91 183.683a45 45 0 1145-45 45.051 45.051 0 01-45 45zm0-88a43 43 0 1043 43 43.049 43.049 0 00-43-43z"
        fill="#ccc"
      />
      <path
        d="M148.502 488.58a11.02 11.02 0 01-4.302-7.881 10.27 10.27 0 01-.025-1.08l-46.568-31.363a8.552 8.552 0 01-3.337-9.67l22.257-55.296a9.699 9.699 0 0118.023 7.171l-17.97 45.69 36.262 32.936a10.462 10.462 0 011.453-.224 11 11 0 012.983 21.762 11.407 11.407 0 01-2.146.206 10.865 10.865 0 01-6.63-2.252z"
        fill="#a0616a"
      />
      <path
        d="M97.626 415.85a4.812 4.812 0 01.101-4.01l10.625-21.716a13.377 13.377 0 0125.521 8.029l-3.879 23.941a4.817 4.817 0 01-6.213 3.82l-23.185-7.368a4.811 4.811 0 01-2.97-2.696z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        fill="#a0616a"
        d="M170.908 683.95h12.26L189 636.662l-18.094.001.002 47.287z"
      />
      <path
        d="M167.78 679.948l24.144-.001h.001a15.386 15.386 0 0115.387 15.386v.5l-39.53.001z"
        fill="#2f2e41"
      />
      <path
        fill="#a0616a"
        d="M51.025 675.264l11.874 3.051 17.42-44.348-17.525-4.503-11.769 45.8z"
      />
      <path
        d="M48.993 670.608l23.384 6.01A15.386 15.386 0 0183.45 695.35l-.124.484-38.287-9.839z"
        fill="#2f2e41"
      />
      <circle cx={137.015} cy={334.98} r={24.561} fill="#a0616a" />
      <path
        d="M124.592 437.141a88.73 88.73 0 01-14.736-20.234 32.716 32.716 0 01-1.54-26.477 33.442 33.442 0 0118.99-19.345 32.88 32.88 0 0141.613 15.447c6.146 12.027 8.554 23.786 7.159 34.952-2.668 21.345-4.23 32.379-4.643 32.79l-.137.138-45.69 2.03z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        d="M127.306 450.535l40.887-2.035a4 4 0 014.183 3.64l18.809 210.832a4 4 0 01-4.07 4.354l-15.973-.34a4 4 0 01-3.798-3.035l-30.516-122.963a4 4 0 00-7.515-.71l-57.83 125.6a4 4 0 01-4.643 2.198l-16.076-4.194a3.995 3.995 0 01-2.905-4.693c5.29-24.968 36.972-167.956 76.862-207.533a3.94 3.94 0 012.585-1.12z"
        fill="#2f2e41"
      />
      <path
        d="M204.733 474.207a11.018 11.018 0 01-6.758-5.913 10.353 10.353 0 01-.396-1.004l-54.531-13.363a8.552 8.552 0 01-6.47-7.925l1.805-59.578a9.699 9.699 0 0119.391.509l-1.096 49.084 45.4 18.397a10.744 10.744 0 011.286-.712 11.01 11.01 0 111.37 20.505z"
        fill="#a0616a"
      />
      <path
        d="M132.567 416.111a4.812 4.812 0 01-1.253-3.81l2.703-24.024a13.377 13.377 0 0126.735-1.023l4.399 23.852a4.817 4.817 0 01-4.567 5.687l-24.313.859a4.811 4.811 0 01-3.704-1.541z"
        fill={props?.tertiary || "#5987af" }
      />
      <path
        d="M101.82 367.407l2.567-8.294 1.755 9.308 14.413-.382-.066-9.177 5.479 9.057 13.06-2.887c-2.79-3.718-5.613-7.494-7.398-11.876s-2.434-9.522-.766-13.959c4.122-10.968 19.751-7.899 28.3-7.992 2.493-.027 5.309-.206 6.942-2.245 1.23-1.536 1.431-3.732 1.369-5.762-.321-10.455-6.598-20.43-15.471-24.585a23.981 23.981 0 00-13.906-1.884 25.14 25.14 0 00-7.091 2.234c-1.997.975-4.095 3.299-6.245 3.236-1.807-.053-4.2-1.935-6.166-2.257a19.06 19.06 0 00-7.124.249 22.375 22.375 0 00-12.076 7.226c-6.68 7.762-8.665 19.2-7.5 29.731s5.103 20.434 8.998 30.166"
        fill="#2f2e41"
      />
      <path d="M803 697.683H1a1 1 0 010-2h802a1 1 0 010 2z" fill="#e6e6e6" />
      <path
        d="M789.47 685.29a19.309 19.309 0 006.376-9.597c.813-3.819-.814-8.302-4.45-9.78-4.084-1.577-8.45 1.262-11.755 4.118s-7.106 6.127-11.439 5.512a17.383 17.383 0 005.33-16.287 6.641 6.641 0 00-1.495-3.32c-2.258-2.425-6.375-1.379-9.098.53-8.633 6.077-11.04 17.799-11.074 28.358-.88-3.818-.15-7.786-.166-11.622s-1.096-8.301-4.383-10.327a13.282 13.282 0 00-6.641-1.577c-3.885-.133-8.202.25-10.859 3.088-3.32 3.52-2.44 9.447.432 13.283s7.222 6.309 11.224 8.998a24.772 24.772 0 018.035 7.654 7.322 7.322 0 01.598 1.362h24.323a67.688 67.688 0 0015.042-10.394z"
        fill="#f2f2f2"
      />
      <path
        d="M536.405 302.202a2.719 2.719 0 01-2.47-3.825l3.129-6.981a23.407 23.407 0 118.849 4.928l-7.985 5.406a2.71 2.71 0 01-1.523.472zm16.146-48.267a20.046 20.046 0 00-12.488 35.736l.986.786-3.367 7.512 7.726-5.23.763.256a20.052 20.052 0 106.38-39.06z"
        fill="#3f3d56"
      />
      <circle cx={552.551} cy={272.908} r={3.253} fill="#3f3d56" />
      <circle cx={543.336} cy={272.908} r={3.253} fill="#3f3d56" />
      <circle cx={561.767} cy={272.908} r={3.253} fill="#3f3d56" />
    </svg>
  )
}
