import React from 'react';
import {View, Text, StyleSheet } from 'react-native';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {IProps} from './index';
import Panel from 'rsuite/lib/Panel';
import config from '../../../../config/default-blueprint.json';
import {AvatarV} from 'react-native-avatar-vfw';
import Avatar from 'rsuite/lib/Avatar';
import { NavV } from 'react-nav-rs';
import { Icon } from 'rsuite';
import moment from 'moment';

const BP = config?.footer

export const FooterLG = (props: IProps) => {
    const styles = {
        panalStyle: {
            backgroundColor: '#ececec'
        },
        copyrightpanalStyle:{
            backgroundColor: '#fcfcfc',
            margin:'0 auto',
            justifyContent:'center',
            alignSelf:'center',
            alignItems:'center',
            textAlign: 'center'
        },
        titleStyle: {
            display:'block',
            fontSize: 16,
            fontWeight:700,
            textAlign:'center',
            paddingTop:20
        },
        contactItemStyle:{
            padding:5
        },
        iconStyle:{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'center' 
        }
    }

    const onFooterNavSelect = (eventKey: string) =>{
        // console.log('eventkey', eventKey);
        props?.onFooterNavSelect && props?.onFooterNavSelect(eventKey);
    }
    const _getcontactDetails = () => {
        return(
            <>
            {
                Array.isArray(BP?.contactDetails?.details) && BP?.contactDetails?.details.map(item=>{
                    if(item.key === 'phone'){
                        return (
                            <View style={StyleSheet.flatten([styles.contactItemStyle])}>                    
                            <Text style={{ textAlign: 'center', fontSize: 12, color: '#fff' }}><a href={`tel:${item?.value}`}>{item.value}</a></Text>
                            </View>
                        )
                    }
                    if(item.key === 'email'){
                            return (
                                <View style={StyleSheet.flatten([styles.contactItemStyle])}>                    
                                <Text style={{ textAlign: 'center', fontSize: 12, color: '#fff' }}><a href={`mailto:${item?.value}`}>{item.value}</a></Text>
                                </View>
                            )
                    }
                })
            }
            </>
        )        
    }

    const _getSocialLinks = () => {
        return(
            <View style={StyleSheet.flatten([styles.iconStyle])}>
            {
                Array.isArray(BP?.socialLinks?.details) ?
                BP?.socialLinks?.details.map((item, key) => {
                        return (
                            <a href={item.link} target="_blank" style={{ padding: 5 }}>
                                <AvatarV source={{ uri: item.icon }} rounded/>
                            </a>
                        )
                    }) : null
            }</View>
        )
    }


    return(
        <FBGridV justify="center">
            <FBItemV colspan={24}>
                <Panel style={StyleSheet.flatten([styles.panalStyle])}>
                    <Text style={StyleSheet.flatten([styles.titleStyle])}>
                        {BP?.contactDetails?.title}
                    </Text>
                    {_getcontactDetails()}
                    <Text style={StyleSheet.flatten([styles.titleStyle])}>
                        {BP?.socialLinks?.title}
                    </Text>
                    {
                        _getSocialLinks()
                    }
                </Panel>
                <Panel style={StyleSheet.flatten([styles.copyrightpanalStyle])}>
                    <div style={{width:'100%', margin: '0 auto', display:'block', justifyContent:'center', alignSelf:'center', alignItems:'center'}}>
                    <Avatar
                    src={BP?.copyright?.logo}                  
                    circle
                    />
                    </div>                    
                    <NavV
                        items={BP?.copyright?.footerNav}
                        appearance={'subtle'}
                        onSelect={onFooterNavSelect}
                        style={{width:'100%', display:'block', margin:'0 auto'}}
                    />
                    <Text>
                        <Icon
                        icon={"copyright"}
                        /> {moment().year()}{" "}
                        {BP?.copyright?.copyRightText?.company} {"All rights reserved."}
                    </Text>
                </Panel>
            </FBItemV>
                    
            
        </FBGridV>
    )
}
