import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { FooterLG } from './Footer.lg';
import { FooterSM } from './Footer.sm';

export interface IProps {
    onFooterNavSelect?(eventKey?:string):void
}

export const FooterDetail = (props: IProps) => {
    const { bp } = useViewport();
    if (bp === 'xs' || bp === 'sm') {
        return <FooterSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if(bp === 'lg')
    return <FooterLG {...props} />;
};
