import React from 'react';
import { View, StyleSheet, Text} from 'react-native';
import {FBGridV, FBItemV} from 'react-flexbox-grid-rs';
import {PanelV} from 'react-panel-rs';
import { Card} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {useViewport} from 'react-viewport-provider';
import { Avatar } from 'react-native-elements';
import Icon from 'rsuite/lib/Icon';
import {
  Carousel, HeaderInfo, ProvenanceMap, ProvenanceTimeline, FeedbackButton, PromoButton, ProductInfoScreen, FooterDetail, Header} from '../index';
import { useProductView } from '../../provider';
import {ThemeContext} from '../../../../providers/theme';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.ProductPage;
interface ProductPageProps {
  onInfoClick?(): void;
  handleLGMarkerClick?(item: any): void;
  onRating?(value: number): void;
  carouselImages?: [];
  handleLGHeaderInfoClick?(): void;
  FeedbackClick?(): void;
  PromoEnrollmentClick?(): void;
  footerContents?: any;
  googleMapAPIKey?:string;
};

export const ProductPageLG = ({
  carouselImages,
  onInfoClick,
  handleLGMarkerClick,
  handleLGHeaderInfoClick,
  FeedbackClick,
  PromoEnrollmentClick,
  onRating,
  footerContents,
  googleMapAPIKey
}: ProductPageProps) => {
  const history = useHistory();
  const { data, headerInfo, mapDetails, timeLineData, batchPromoDetails } = useProductView();
  const theme = React.useContext(ThemeContext);
  const [provenanceOnClickData, setProvenanceOnClickData] = React.useState(timeLineData && timeLineData[0]);
  const {width} = useViewport();
  const galleryImages = carouselImages && carouselImages;
    const styles = {
      containerItemStyle: {
        alignItems: 'center',
        // backgroundImage: `URL(https://i.ibb.co/R7k8G53/slider-bg.png)`,
        // backgroundSize: '50%',        width: 
        padding: '5% 0px',
      },
        containerStyle: {
            alignItems: 'center',
            marginTop: 20,
            width: 0.89 * width,
        },
        mapStyles: {
            marginTop: 20
        },
      provenanceInfoCardStyle: {
        width: '90%',
        borderRadius: 10,
        marginTop: 20,
        paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#ffffff',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: -20
      },
      timelineContainer: {
        width: 0.89 * width ,
        marginTop: 20
      },
      footerContainerStyle: {
        backgroundColor: '#25242A',
        padding: '1% 3%'
      },
      footerHeaderStyle: {
        paddingLeft: '5%',
        paddingBottom: 15,
        color: '#fff',
        fontSize: 20,
        fontWeight: 600,
        display: 'block'
      },
      contactItemStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
      },
      footerTextStyle: {
        paddingLeft: '5%',
        textAling: 'center',
        fontSize: 18,
        color: '#fff',

      },
      footerColumnStyle: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textAlign: 'left',
        margin: '0 auto'
      },
      policyTextStyle: {
        paddingLeft: '5%',
        textAling: 'center',
        fontSize: 14,
        color: '#fff',
      }
    };

    const handleTimelinfProvClick = (item: any) => {
        // productInfo = item;
        // setTimelineInfo(true);
    }

  const handleTimelineClick = (item: any, index: number) => {
    if(item && provenanceOnClickData !== item){
      item.index = index;
      setProvenanceOnClickData(item);
    }
  }

  const _getContactDetails = () => {
    let contact: any[] = [];
    const contactData = contactData || BP?.footerContent?.contactDetail;
    if (contactData && Array.isArray(contactData)) {
      contactData.forEach((element, i) => {
        contact.push(
          <View style={StyleSheet.flatten([styles.contactItemStyle])}>
            <Icon style={{ backgroundColor: '#fff', padding: 5, borderRadius: '50%', margin: 5, color: theme?.palette?.primary }} icon={element.icon} size={40} />
            <Text style={{ textAlign: 'left', fontSize: 14, color: '#fff' }}>{element.title}</Text>
          </View>
        )
      })
    }
    return contact;
  }

  const _getFooterContent = () => {
    let footerContent = footerContents|| BP?.footerContent;
    return (
      <>
        <FBItemV colspan={8} style={StyleSheet.flatten([styles.footerColumnStyle])}>
          <Text style={StyleSheet.flatten([styles.footerHeaderStyle])}>{footerContent?.contactTitle}</Text>
          {_getContactDetails()}
        </FBItemV>
        <FBItemV colspan={8} style={{ textAlign: 'center' }}>
          <a href={footerContent?.copyRightLink} target="_blank"> <img src={footerContent?.copyRightLogo} width={200} height={50} style={{ margin: '0 auto', display: 'block' }} /> </a>
          <Text style={StyleSheet.flatten(styles.footerTextStyle)}><Icon icon='copyright' size={14} />{' '}{footerContent?.copyRightText}</Text>
        </FBItemV>
        <FBItemV colspan={8} style={{ textAlign: 'center' }}>
          <Text style={StyleSheet.flatten([styles.footerHeaderStyle])}>{footerContent?.socialTitle}</Text>
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            {
              footerContent && Array.isArray(footerContent?.socialIcons) ?
                footerContent?.socialIcons.map((item, key) => {
                  return (
                    <a href={item.link} target="_blank" style={{ padding: 5 }}>
                      <Avatar source={{ uri: item.icon }} />
                    </a>
                  )
                }) : null
            }</View>
        </FBItemV>
      </>
    )
  }

  const onFooterNavSelect = (eventKey: string) => {
    if (eventKey === 'terms') {
      history.push('/termsandcondition');
      window.scrollTo(0, 0);
    }
    if (eventKey === 'privacy') {
      history.push('/privacy');
      window.scrollTo(0, 0)
    }
    if (eventKey === 'aboutus') {
      history.push('/hello');
    }
  }

    return (
      <>
        {/* <HeaderV {...props} /> */}
        <FBGridV justify={'center'}>
          <FBItemV colspan={24}>
            <View style={StyleSheet.flatten([styles.containerItemStyle])}>
            
            <Card style={StyleSheet.flatten([styles.containerStyle])}>
              {/* <View style={StyleSheet.flatten([styles.panelStyle])}> */}
                <FBGridV justify={'center'}>
                  <FBItemV colspan={10}>
                    <Carousel images={galleryImages} />
                  </FBItemV>
                  <FBItemV colspan={14}>
                    <View style={{alignItems: 'center'}}>
                    <HeaderInfo
                      title={headerInfo?.title}
                      volume={headerInfo?.subTitle}
                      info={headerInfo?.info}
                      price={headerInfo?.price}
                      handleLGHeaderInfoClick={handleLGHeaderInfoClick}
                      onInfoClick={onInfoClick}
                      onRating={onRating}
                    />
                  </View>
                  </FBItemV>
                </FBGridV>
              {/* </View> */}
            </Card>
            </View>
          </FBItemV>
            <FBItemV colspan={24}>
            <View style={StyleSheet.flatten([styles.mapStyles])}>
              <ProvenanceMap
                defaultCenter={
                  {
                    lat: mapDetails && mapDetails?.post?.latitude,
                    lng: mapDetails && mapDetails?.post?.longitude
                  }
                }
                markers={mapDetails && mapDetails?.listMarkers}
                mapDetails={mapDetails}
              handleMarkerClick={handleLGMarkerClick} 
              googleMapAPIKey={googleMapAPIKey}
              />
            </View>
          </FBItemV>
          <FBItemV colspan={24} style={{ width: 'auto' }}>
            <View style={StyleSheet.flatten([styles.timelineContainer])}>
            <FBGridV justify={'center'}>
              <FBItemV colspan={10}>
                  <ProvenanceTimeline
                    timeLineData={timeLineData}
                    onTimelineElementClick={handleTimelineClick}
                    googleMapAPIKey={googleMapAPIKey}
                  />
              </FBItemV>
              {timeLineData &&
                <FBItemV colspan={14}>
                  <View style={{alignItems: 'center'}}>
                      <ProductInfoScreen
                        onClickData={provenanceOnClickData && provenanceOnClickData}
                      />
                </View>
                </FBItemV>
              }
            </FBGridV>
            </View>
          </FBItemV>
          <FBItemV colspan={24}>
            <FBGridV>
              <FBItemV colspan={12}>
                {batchPromoDetails?.contestButtonText &&
                  <View style={{ marginTop: 20, marginRight: 10 }}>
                    <FeedbackButton contestButtonText={batchPromoDetails?.contestButtonText} onPress={FeedbackClick} />
                  </View>
                }
              </FBItemV>
              <FBItemV colspan={12}>
                {batchPromoDetails?.promoButtonText &&
                  <View style={{ marginTop: 20, marginLeft: 10 }}>
                    <PromoButton promoButtonText={batchPromoDetails?.promoButtonText} onPress={PromoEnrollmentClick} />
                  </View>
                }
              </FBItemV>
            </FBGridV>
          </FBItemV>
          <FBItemV colspan={24}>
            <FooterDetail onFooterNavSelect={onFooterNavSelect} />
          </FBItemV>
        </FBGridV>
      </>
    );
}