import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import config from './config/default-blueprint.json';
import { EventBus } from 'event-bus-vfs';
import { useViewport, ViewportProvider } from 'react-viewport-provider';
import { ThemeContext } from '../../../../../../providers/theme';
import { CheckPickerV } from 'react-checkpicker-rs';
import { BodyTemplateV } from 'react-body-template-1';
import { CredentialForm } from './CredentialForm';
import { SkoolMentorV } from 'skool-mentor-template';
import { FormV, FORM_VALUES } from 'react-form-rs';
import { ButtonV } from 'react-button-rs';
import { ScanTemplateV } from 'react-scan-to-download-template-1';
import { AdminDashboard } from './AdminDashboard';
import { VerifierDashboard } from './VerifierDashboard';
import { IssuerDashboard } from './IssuerDashboard';
type DashboardGraphProps = {
  drawerWidth: number;
};
const BP = config.adminDashboard?.dashboardGraph;
let formContext = {};

const DashboardGraphInternal = (props: DashboardGraphProps) => {
  const { drawerWidth } = props;
  const theme = useContext(ThemeContext);
  const [sideBarEventKey, setSideBarEventKey] = React.useState<string>();

  const { width, height } = useViewport();

  useEffect(() => {
    const app = EventBus.on(FORM_VALUES, (value) => {
      formContext = { ...value };
    });
    return function () {
      app.off();
    };
  }, []);

  const styles = {
    bodyContainerStyle: {
      // width: width - drawerWidth,
    },
    navBarStyle: {
      backgroundColor: '#f6f6f6',
      width: 100,
    },
    sidebarStyle: {
      maxHeight: height - (height * 0.08)
    }
  };
  const _getData = () => {
    const key = sideBarEventKey;
    switch (key) {
      case 'adminDashboard':
        return <AdminDashboard drawerWidth={drawerWidth} />;
      // case 'issuer':
      //   return <IssuerDashboard drawerWidth={drawerWidth} />;
      // case 'verifier':
      //   return <VerifierDashboard drawerWidth={drawerWidth} />;
      default:
        return <AdminDashboard drawerWidth={drawerWidth} />;
    }
  };
  const setNavItem = (eventKey: string) => {
    setSideBarEventKey(eventKey);
  };
  return (
    <View style={StyleSheet.flatten([styles.bodyContainerStyle])}>
      <BodyTemplateV
        primaryColor={BP?.primaryColor || theme?.palette.primary}
        primaryHighlightColor={BP?.primaryHighlightColor || theme?.palette.secondary}
        secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
        ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
        renderContent={_getData}
        sidebarStyle={StyleSheet.flatten([styles.sidebarStyle])}
        contentScrollableInline={true}
        headerTitleStyle={{ marginLeft: 20 }}
        navbarStyle={{}}
        layout={'sidebar-header-content-footer'}
        onNavItemSelect={(eventKey) => setNavItem(eventKey)}
        // sideBarItems={BP?.nav_items}
        onSideNavItemSelect={(eventKey) => setNavItem(eventKey)}
        // renderFooter={() => <div />}
        // sidebarFixedWidth={80}
      />
    </View>
  );
};

export const DashboardGraph = (props: DashboardGraphProps) => {
  return (
    <ViewportProvider>
      <DashboardGraphInternal {...props} />
    </ViewportProvider>
  );
};
