import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { ProductDetailsSM } from './ProductDetails.sm';
import { ProductDetailsLG } from './ProductDetails.lg';

export interface ProductDetailsProps {
}

export const ProductDetails = (props: ProductDetailsProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
        return <ProductDetailsSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if(bp === 'lg')
    return <ProductDetailsLG {...props} />;
};
