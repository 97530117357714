import React from 'react';
import { useViewport } from 'react-viewport-provider';
import { HeaderInfoLG } from './HeaderInfo.lg';
import { HeaderInfoSM } from './HeaderInfo.sm';

export interface HeaderInfoProps {
  title?: string;
  volume?: string;
  info?: string;
  price?: string;
  handleLGHeaderInfoClick?(): void;
  onInfoClick?(): void;
  onRating?(value: number): void;
}

export const HeaderInfo = (props: HeaderInfoProps) => {
    const { bp } = useViewport();

    if (bp === 'xs' || bp === 'sm') {
    return <HeaderInfoSM {...props} />;
    }

    // if (bp === 'md') {
    //   return <DashboardMD />;
    // }

    // if (bp === 'lg')
        return <HeaderInfoLG {...props} />;
};
