import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import { BodyTemplateV } from 'react-body-template-1';
import { useViewport } from 'react-viewport-provider';
import { DrawerV } from 'react-drawer-rs';
import Button from 'rsuite/lib/Button';
import {StepTemplateV} from 'react-step-rs-template';
import {InputV} from 'react-input-rs';
import {InputNumberV} from '../../../../../../widgets/InputNumberV';
import {FormGeneratorV, HttpServer} from '../../../../../../widgets/FormBuildGenV/src';
import {ThemeContext} from '../../../../../../providers/theme';
import blueprint from './config/default-blueprint.json';

const BP = blueprint.addProvenance;
const http = new HttpServer();

export const AddProvenance = () => {
    const {height, width} = useViewport();
    const theme = React.useContext(ThemeContext);
    const parentRef = React.useRef(0);
    const [enable, setEnable] = React.useState(true);
    const [step, setStep] = React.useState<number>(0);
    const [data, setData] = React.useState<any>();
    const [drawer, setDrawer] = React.useState<any>({
        placement: 'right',
        title: '',
        size: 'md',
        backdrop: false,
        show: false,
    });
    React.useEffect(() => {
        http.get('http://vlinderserver.online:3014/formsuite?cid=tragAddProvenance')
            .then(res => {
                if (res?.data) {
                    setData(res?.data?.form?.task_data);
                }
            })
    }, [])

    const styles = {
        mainContainerStyle: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20
        },
        containerStyle: {
            width: 500,
            borderWidth: 2,
            borderRadius: 10,
            borderColor: '#d2d3d3',
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 60,
            boxShadow: "1px 3px 1px #d2d3d3",
        },
        inputStepsStyle: {
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
        }
    }

    const handleSubmit = (data: any) => {

    }
    const bodyContent = () => {
        return null;
    }

    const onNavItemClick = (eventKey: string) => {
        // setUserActionKey(eventKey);
        if(eventKey === 'addProvenance'){
            let _drawer = { ...drawer };
            _drawer.placement = BP.notificationsDrawerPlacement;
            _drawer.show = true;
            setDrawer({
                ..._drawer,
            });
        }
        // if (eventKey === 'newIssuer') {
        //   let _drawer = { ...drawer };
        //   _drawer.title = humanizeString(eventKey);
        //   _drawer.placement = BP?.drawerPlacement;
        //   _drawer.show = true;
        //   setDrawer({
        //     ..._drawer,
        //   });
        // }
    };


    const _getModalFooter = () => {
        return null;
    }

    // <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
    //     {data &&
    //         <FormGeneratorV
    //             back_action="/"
    //             back_name="Back"
    //             action_name="Submit"
    //             form_action="/"
    //             form_method="POST"
    //             onSubmit={handleSubmit}
    //             data={data && data}
    //             containerStyle={StyleSheet.flatten([styles.containerStyle])}
    //         />
    //     }
    // </View>

    const renderContent = (key: string) => {
        if (key === 'addSteps') {
            return (
                <>
                <View style={StyleSheet.flatten([styles.inputStepsStyle])}>
                    <InputV 
                        // onChange={_handleInputChange}
                        // onEnterPress={_handleEnterPress}
                        title={BP?.inputNameField?.label}
                        {...BP.inputNameField.componentProps}
                    />
                </View>
                <View style={StyleSheet.flatten([styles.inputStepsStyle])}>
                    <InputNumberV
                        // onChange={_handleInputChange}
                        // onEnterPress={_handleEnterPress}
                        title={BP?.inputStepField?.label}
                        {...BP.inputStepField.componentProps}
                    />
                </View>
                </>
            );
        } else if (key === 'form') {
            return (
                <ScrollView>
                <View style={StyleSheet.flatten([styles.mainContainerStyle])}>
                    {data &&
                        <FormGeneratorV
                            back_action="/"
                            back_name="Back"
                            action_name="Submit"
                            form_action="/"
                            form_method="POST"
                            onSubmit={handleSubmit}
                            data={data && data}
                            containerStyle={StyleSheet.flatten([styles.containerStyle])}
                        />
                    }
                </View>
                </ScrollView>
            );
        }
    }

    const renderDrawerHeader = () => {
        return (
            <h4 style={{ textAlign: 'center' }}>{BP?.addProvenanceModalTitle}</h4>
        );
    };

    const renderDrawerFooter = () => {
        return <div />;
    };

    const renderDrawerBody = () => {
        return (
            <StepTemplateV
                height={'87vh'}
                step={step}
                bordered
                shaded
                items={BP.stepItems}
                renderStepperContent={renderContent}
                stepIconOnly={true}
                stepperColSpan={2}
                headerTitleToDisplay={true}
                stepperBackgroundColor={'#5987AF'}
                stepperIconActiveColor={'#f05776'}
                onNext={(currentStep, currentKey) => {
                    console.log('onNext current step', currentStep);
                    console.log('current key', currentKey);
                    console.log('parentRef key', parentRef);
                    setStep(currentStep + 1);
                }}
                onPrev={(currentStep, currentKey) => {
                    console.log('onPrev current step', currentStep);
                    console.log('current key', currentKey);
                    console.log('parentRef key', parentRef);
                    setStep(currentStep - 1);
                }}
                loading={false}
                stepperPrevTitle={'Previous'}
                stepperNextTitle={'Next'}
                enableNextonCurrentStep={enable}
                enablePrevonCurrentStep={enable}
            />
        );
    };

    const handleOnDrawerHide = () => {
        let _drawer = { ...drawer };
        _drawer.show = false;
        setDrawer({
            ..._drawer,
        });
    };

    return (
        <>
            <BodyTemplateV
                primaryColor={BP?.primaryColor || theme?.palette.primary}
                primaryHighlightColor={
                    BP?.primaryHighlightColor || theme?.palette.secondary
                }
                secondaryColor={BP?.secondaryColor || theme?.palette.secondary}
                ternaryColor={BP?.ternaryColor || theme?.palette.ternary}
                renderContent={bodyContent}
                dashboardContainerStyle={StyleSheet.flatten([styles.containerStyle])}
                contentScrollableInline={true}
                headerTitleStyle={{ marginLeft: 20 }}
                navbarStyle={{}}
                layout={'sidebar-header-content-footer'}
                navBarRightItems={BP?.navRightItems}
                //   navBarLeftItems={BP?.navLeftItems}
                onNavItemSelect={onNavItemClick}
                headerBackgroundColor={'#fff'}
                renderFooter={() => <div />}
            />
            <DrawerV
                placement={drawer.placement}
                show={drawer.show}
                backdrop={drawer.backdrop}
                size={drawer.size}
                renderHeader={renderDrawerHeader}
                onHide={handleOnDrawerHide}
                drawerTitle={drawer.title}
                renderBody={renderDrawerBody}
                renderFooter={renderDrawerFooter}
            />
        </>
    );
}