import React from 'react';
import {View, StyleSheet} from 'react-native';
import {ButtonV, ButtonType} from 'react-native-button-vfw';
import {ThemeContext} from '../../../../providers/theme';
import {FormV, FORM_VALUES, defaultValuesDS, SENDER_ID} from '../../../../widgets/FormV';
import blueprint from '../../config/default-blueprint.json';

const BP = blueprint.RatingInfo;

interface RatingInfo {
    formData?(formData: any): void;
};

export const RatingInfoLG = (props: RatingInfo) => {
    const parentRef = React.useRef(() => { });
    const [validateForm, setValidateForm] = React.useState<boolean>(false);
    const theme = React.useContext(ThemeContext);

    const styles = {
        formContainer: {
            width: '100%',
            backgroundColor: '#ffffff',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            // overflow: 'scroll',
        },
        buttonTitleStyle: {
            color: '#ffffff',
        },
        buttonContainerStyle: {
            width: 120,
            alignSelf: 'center',
            marginTop: 20,
            marginBottom: 60,
        },
        submitButtonStyle: {
            backgroundColor: theme?.palette.secondary,
            borderRadius: 10,
        },
    }

    const _handleOnValidateResult = (
        formContext?: any,
        status?: 'success' | 'error',
        validateResult: any,
    ) => {
        let formData = {};
        formData.name = defaultValuesDS.get('fullName')?.defaultValue;
        formData.email = defaultValuesDS.get('emailAddress')?.defaultValue ?? null;
        formData.contactNo =
            defaultValuesDS.get('contactNumber')?.defaultValue ?? null;
        formData.address = defaultValuesDS.get('feedback')?.defaultValue ?? null;

        if (status === 'success'){
            props.formData(formData);
        }
    }

    const onSubmitPress = () => {
        setValidateForm(true);
    }

    return (
        <>
        <FormV
            components={BP?.formFields}
            formContext={{}}
            containerStyle={styles.formContainer}
            validateForm={validateForm}
            onValidateResult={_handleOnValidateResult}
            defaultValues={defaultValuesDS.get()}
            ref={parentRef}
        />
            <View style={{ width: '100%' }}>
            <ButtonV
                // {...props}
                title={'Submit'}
                titleStyle={StyleSheet.flatten([styles.buttonTitleStyle])}
                type={ButtonType.Solid}
                buttonStyle={StyleSheet.flatten([styles.submitButtonStyle])}
                containerStyle={StyleSheet.flatten([styles.buttonContainerStyle])}
                onPress={onSubmitPress}
            />
            </View>
        </>
    );
}