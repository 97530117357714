// @ts-nocheck
import * as React from 'react';
import blueprint from './config/default-blueprint.json';
import { Palette, StyleGuide, styleGuide } from './StyleGuide';

const BP: any = blueprint.Theme;

export enum ThemeName {
  Vlinder = 'vlinder',
}

export type ThemeColors = {
  primary: string;
  secondary: string;
  ternary: string;
  tableOddRowColor?: string;
  tableEvenRowColor?: string;
  barStyle: 'dark-content' | 'light-content';
  appearancePrimary: Appearance | string;
  appearanceSecondary: Appearance | string;
};

export type Appearance = {
  light: string;
  dark: string;
  [key: string]: string;
};

export type Theme = {
  palette: {
    primary: string;
    secondary: string;
    ternary: string;
    primaryDark: string;
    tableOddRowColor?: string;
    tableEvenRowColor?: string;
    barStyle: 'dark-content' | 'light-content';
  } & Palette;
} & StyleGuide;

export const Colors: { [name in ThemeName]: ThemeColors } = BP.colors;

type ThemeProviderProps = {
  children: React.ReactNode;
};

type ThemeProviderState = {
  theme: Theme;
};

export const ThemeContext = React.createContext<Theme | null>(null);

export class ThemeProvider extends React.Component<
  ThemeProviderProps,
  ThemeProviderState
  > {
  static instance: ThemeProvider | null = null;

  displayName = 'ThemeProvider';

  state = {
    theme: {
      palette: {
        ...Colors[ThemeName.Blue],
        ...styleGuide.palette,
      },
      typography: { ...styleGuide.typography },
      spacing: { ...styleGuide.spacing },
      styles: { ...styleGuide.styles },
    },
  };

  static getInstance(): ThemeProvider {
    if (!ThemeProvider.instance) {
      throw new Error('ThemeProvider is not mounted yet.');
    }
    return ThemeProvider.instance;
  }

  switchTheme(themeName: ThemeName) {
    const { palette, typography, spacing, styles } = this.state.theme;
    this.setState({
      theme: {
        palette: { ...palette, ...Colors[themeName] },
        typography,
        spacing,
        styles,
      },
    });
  }

  componentDidMount() {
    if (ThemeProvider.instance !== null) {
      throw new Error('Only one ThemeProvider is allowed to be used');
    }
    ThemeProvider.instance = this;
  }

  render() {
    const { children } = this.props;
    const { theme } = this.state;
    return (
      <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
    );
  }
}

export const withTheme = (WrappedComponent: any) => {
  return class extends React.PureComponent {
    render() {
      const { props } = this;
      return (
        <ThemeContext.Consumer>
          {theme => <WrappedComponent {...{ theme }} {...props} />}
        </ThemeContext.Consumer>
      );
    }
  };
};
